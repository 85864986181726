export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigDecimal: any;
  Instant: any;
  Json: any;
  LocalDate: any;
};

export type AccessibleDoor = {
  __typename?: "AccessibleDoor";
  id?: Maybe<Scalars["String"]>;
  isGeneral?: Maybe<Scalars["Boolean"]>;
  pin?: Maybe<Scalars["String"]>;
  privacyMode?: Maybe<Scalars["Boolean"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]>;
  title?: Maybe<Scalars["String"]>;
};

export enum AccountingState {
  Canceled = "CANCELED",
  Closed = "CLOSED",
  Inactive = "INACTIVE",
  Open = "OPEN"
}

export enum Actor {
  Booker = "BOOKER",
  PrimaryGuest = "PRIMARY_GUEST",
  TravelBuddy = "TRAVEL_BUDDY"
}

export type AddArbitraryOrderItemInput = {
  folioMetadata?: InputMaybe<Scalars["Json"]>;
  note?: InputMaybe<Scalars["String"]>;
  pmsFolioId: Scalars["String"];
  price: GrossPriceInput;
  quantity: Scalars["Int"];
  transactionTypeCode: Scalars["String"];
  transactionTypePmsId: Scalars["String"];
};

export type AdditionalDetails = {
  origin?: InputMaybe<Scalars["String"]>;
  returnUrl?: InputMaybe<Scalars["String"]>;
};

export type AdditionalServiceAvailabilityDto = {
  __typename?: "AdditionalServiceAvailabilityDTO";
  available: Scalars["Boolean"];
  basePrice: Price;
  bookableDates?: Maybe<Array<Maybe<Scalars["LocalDate"]>>>;
  bookedDates?: Maybe<Array<Maybe<Scalars["LocalDate"]>>>;
  canBookMultiple?: Maybe<Scalars["Boolean"]>;
  granularity: Granularity;
  magicServiceCodeEnum?: Maybe<Scalars["String"]>;
  /**  TODO remove when backoffice gets service definitions */
  name?: Maybe<Scalars["String"]>;
  payAtCheckout?: Maybe<Scalars["Boolean"]>;
  pmsServiceId: Scalars["String"];
  shopTypes?: Maybe<Array<Maybe<ShopType>>>;
  visibilities?: Maybe<Array<Visibility>>;
};

export type Address = {
  __typename?: "Address";
  addressLine1?: Maybe<Scalars["String"]>;
  addressLine2?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  countryCode?: Maybe<Scalars["String"]>;
  postalCode?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
};

export type AdjustOrderItemInput = {
  grossPrice?: InputMaybe<GrossPriceInput>;
  percentage?: InputMaybe<Scalars["BigDecimal"]>;
  pmsOrderItemIds: Array<Scalars["String"]>;
  pmsReasonCodeId: Scalars["String"];
  pmsReservationId: Scalars["String"];
  priceAdjustmentType: PriceAdjustmentType;
  reasonText: Scalars["String"];
};

export type AdyenStoredPaymentMethod = {
  __typename?: "AdyenStoredPaymentMethod";
  brand?: Maybe<Scalars["String"]>;
  expiryMonth?: Maybe<Scalars["String"]>;
  expiryYear?: Maybe<Scalars["String"]>;
  holderName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastFour?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  ownerName?: Maybe<Scalars["String"]>;
  shopperEmail?: Maybe<Scalars["String"]>;
  supportedShopperInteractions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<Scalars["String"]>;
};

export type Amount = {
  amount?: InputMaybe<Scalars["Int"]>;
  currency?: InputMaybe<Scalars["String"]>;
};

export type AvailableDoorDto = {
  __typename?: "AvailableDoorDto";
  batteryLevel?: Maybe<BatteryLevel>;
  id?: Maybe<Scalars["String"]>;
  lockAccessibility?: Maybe<DoorLockAccessibility>;
  pin?: Maybe<Scalars["String"]>;
  privacyMode?: Maybe<Scalars["Boolean"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]>;
  sortPriority?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
  unitId?: Maybe<Scalars["String"]>;
};

export type AvailableTerminal = {
  __typename?: "AvailableTerminal";
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
};

export type BackofficeAccessibleDoor = {
  __typename?: "BackofficeAccessibleDoor";
  batteryLevel?: Maybe<BatteryLevel>;
  id: Scalars["String"];
  isGeneral?: Maybe<Scalars["Boolean"]>;
  lockAccessibility?: Maybe<DoorLockAccessibility>;
  /** @deprecated No longer supported */
  pin?: Maybe<Scalars["String"]>;
  privacyMode?: Maybe<Scalars["Boolean"]>;
  /** # todo not this pr - remove this */
  sortPriority?: Maybe<Scalars["Int"]>;
  title?: Maybe<Scalars["String"]>;
};

export type BackofficeConditionCountsFilter = {
  filters?: InputMaybe<Scalars["Json"]>;
  pmsPropertyId: Scalars["String"];
};

export type BackofficeKeyCard = {
  __typename?: "BackofficeKeyCard";
  createdAt?: Maybe<Scalars["Instant"]>;
  id: Scalars["String"];
  isCopy?: Maybe<Scalars["Boolean"]>;
  requestor?: Maybe<DoorAccessRequestor>;
};

export type BackofficeMagicFileDescriptor = {
  __typename?: "BackofficeMagicFileDescriptor";
  contentType: Scalars["String"];
  fileName: Scalars["String"];
  metaData?: Maybe<Scalars["Json"]>;
};

export enum BackofficeManualCheckInCheckpoint {
  Confirmation = "CONFIRMATION",
  Payment = "PAYMENT",
  RegistrationPersonalData = "REGISTRATION_PERSONAL_DATA",
  RegistrationVerifyAndSign = "REGISTRATION_VERIFY_AND_SIGN",
  Services = "SERVICES"
}

export type BackofficeNote = {
  __typename?: "BackofficeNote";
  beddingComment?: Maybe<Array<Scalars["String"]>>;
  bookerComment?: Maybe<Array<Scalars["String"]>>;
  bookingComment?: Maybe<Array<Scalars["String"]>>;
  comment?: Maybe<Array<Scalars["String"]>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  guestComment?: Maybe<Array<Scalars["String"]>>;
  previousVisits?: Maybe<Scalars["Int"]>;
  rateBreakdownComment?: Maybe<Array<Scalars["String"]>>;
};

export type BackofficeOverviewDetailEvent = {
  pmsReservationId: Scalars["String"];
  tenantName: Scalars["String"];
  type: BackofficeOverviewDetailEventType;
};

export enum BackofficeOverviewDetailEventType {
  SecondScreenBackofficeEvent = "SECOND_SCREEN_BACKOFFICE_EVENT"
}

export type BackofficePing = BackofficeOverviewDetailEvent & {
  __typename?: "BackofficePing";
  pmsReservationId: Scalars["String"];
  tenantName: Scalars["String"];
  type: BackofficeOverviewDetailEventType;
};

export type BackofficeRateBreakdown = {
  __typename?: "BackofficeRateBreakdown";
  rateSpliceTypeTuples?: Maybe<Array<RateSpliceTypeTuple>>;
};

export type BackofficeRateSplice = {
  __typename?: "BackofficeRateSplice";
  date?: Maybe<Scalars["LocalDate"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  grossPrice?: Maybe<GrossPrice>;
  pmsId?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export type BackofficeReservationOverviewDetail = BackofficeReservationOverviewTable & {
  __typename?: "BackofficeReservationOverviewDetail";
  accessibleDoors: Array<BackofficeAccessibleDoor>;
  adultsCount: Scalars["Int"];
  allowedActions: Array<ReservationAction>;
  arrival: Scalars["Instant"];
  balance?: Maybe<GrossPrice>;
  bookedPmsUnitGroupId?: Maybe<Scalars["String"]>;
  bookedServicesOverview: Array<BookedService>;
  booker?: Maybe<Person>;
  bookingChannel?: Maybe<BookingChannelFilterValue>;
  bookingMagicLink: Scalars["String"];
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]>;
  checkoutOnAr?: Maybe<Scalars["Boolean"]>;
  checkoutTime?: Maybe<Scalars["Instant"]>;
  childrenAges?: Maybe<Array<Scalars["Int"]>>;
  childrenCount: Scalars["Int"];
  completed?: Maybe<Scalars["Boolean"]>;
  contractualCheckInTime?: Maybe<Scalars["Instant"]>;
  createdAt: Scalars["Instant"];
  departure: Scalars["Instant"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]>;
  files: Array<BackofficeMagicFileDescriptor>;
  folios: Array<Folio>;
  foliosToBePaid: Array<Folio>;
  groupedRateBreakdown?: Maybe<GroupedRateBreakdown>;
  idCheckStatus: IdCheckStatus;
  keyCards?: Maybe<Array<BackofficeKeyCard>>;
  lastConfirmedCheckoutPage?: Maybe<GuestJourneyCheckoutFlowCheckpoint>;
  lastConfirmedPage?: Maybe<GuestFlowCheckPoint>;
  magicId: Scalars["String"];
  magicLink: Scalars["String"];
  magicToken: Scalars["String"];
  maxCompanions: Scalars["Int"];
  notes?: Maybe<BackofficeNote>;
  pmsOriginalPropertyId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  pmsReservationId: Scalars["String"];
  primaryGuest?: Maybe<Person>;
  primaryGuestSameAsBooker?: Maybe<Scalars["Boolean"]>;
  /** @deprecated Use the 'groupedRateBreakdown' field instead */
  rateBreakdown?: Maybe<BackofficeRateBreakdown>;
  reservationCondition?: Maybe<ReservationCondition>;
  reservationConditionReasons?: Maybe<Scalars["Json"]>;
  reservationIsPaid?: Maybe<Scalars["Boolean"]>;
  reservationStatus?: Maybe<ReservationStatus>;
  sentNotifications: Array<BackofficeSentNotification>;
  /** @deprecated Use the 'bookedServicesOverview' field instead */
  services: Array<BackofficeService>;
  travelBuddies: Array<Person>;
  unit?: Maybe<BackofficeUnit>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]>;
};

export type BackofficeReservationOverviewFilter = {
  filters?: InputMaybe<Scalars["Json"]>;
  pageable: Pageable;
  pmsPropertyId: Scalars["String"];
};

export type BackofficeReservationOverviewTable = {
  adultsCount: Scalars["Int"];
  allowedActions: Array<ReservationAction>;
  arrival: Scalars["Instant"];
  balance?: Maybe<GrossPrice>;
  bookedPmsUnitGroupId?: Maybe<Scalars["String"]>;
  booker?: Maybe<Person>;
  bookingChannel?: Maybe<BookingChannelFilterValue>;
  bookingMagicLink: Scalars["String"];
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]>;
  checkoutOnAr?: Maybe<Scalars["Boolean"]>;
  checkoutTime?: Maybe<Scalars["Instant"]>;
  childrenAges?: Maybe<Array<Scalars["Int"]>>;
  childrenCount: Scalars["Int"];
  completed?: Maybe<Scalars["Boolean"]>;
  contractualCheckInTime?: Maybe<Scalars["Instant"]>;
  createdAt: Scalars["Instant"];
  departure: Scalars["Instant"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]>;
  files: Array<BackofficeMagicFileDescriptor>;
  idCheckStatus: IdCheckStatus;
  lastConfirmedCheckoutPage?: Maybe<GuestJourneyCheckoutFlowCheckpoint>;
  lastConfirmedPage?: Maybe<GuestFlowCheckPoint>;
  magicId: Scalars["String"];
  magicLink: Scalars["String"];
  magicToken: Scalars["String"];
  notes?: Maybe<BackofficeNote>;
  pmsOriginalPropertyId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  pmsReservationId: Scalars["String"];
  primaryGuest?: Maybe<Person>;
  primaryGuestSameAsBooker?: Maybe<Scalars["Boolean"]>;
  reservationCondition?: Maybe<ReservationCondition>;
  reservationConditionReasons?: Maybe<Scalars["Json"]>;
  reservationIsPaid?: Maybe<Scalars["Boolean"]>;
  reservationStatus?: Maybe<ReservationStatus>;
  services: Array<BackofficeService>;
  travelBuddies: Array<Person>;
  unit?: Maybe<BackofficeUnit>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]>;
};

export type BackofficeReservationOverviewTablePage = {
  __typename?: "BackofficeReservationOverviewTablePage";
  content: Array<BackofficeReservationOverviewTable>;
  totalElements: Scalars["Int"];
};

export type BackofficeSentNotification = {
  __typename?: "BackofficeSentNotification";
  /**  leaving these without 'pms' in front because we can reuse the DTO */
  bookingId?: Maybe<Scalars["String"]>;
  channel?: Maybe<CommunicationChannel>;
  contentLink?: Maybe<Scalars["String"]>;
  conversationId?: Maybe<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Instant"]>;
  id?: Maybe<Scalars["String"]>;
  language?: Maybe<CommunicationLanguage>;
  lastUpdateTimestamp?: Maybe<Scalars["Instant"]>;
  magicId?: Maybe<Scalars["String"]>;
  recipient?: Maybe<Scalars["String"]>;
  reservationId?: Maybe<Scalars["String"]>;
  status?: Maybe<Scalars["String"]>;
  template?: Maybe<NotificationType>;
  travelBuddyId?: Maybe<Scalars["String"]>;
};

export type BackofficeService = {
  __typename?: "BackofficeService";
  code?: Maybe<ServiceCodeEnum>;
  name?: Maybe<Scalars["String"]>;
  price?: Maybe<Price>;
  quantity?: Maybe<Scalars["Int"]>;
};

export type BackofficeUnit = {
  __typename?: "BackofficeUnit";
  /** # todo check todo in v1 */
  condition?: Maybe<UnitState>;
  name?: Maybe<Scalars["String"]>;
  pmsUnitGroupId?: Maybe<Scalars["String"]>;
  pmsUnitId?: Maybe<Scalars["String"]>;
  privacyMode?: Maybe<Scalars["Boolean"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]>;
};

export enum BalanceFilterValue {
  Negative = "NEGATIVE",
  Positive = "POSITIVE",
  Zero = "ZERO"
}

export enum BatteryLevel {
  Critical = "CRITICAL",
  Fresh = "FRESH",
  Good = "GOOD",
  Low = "LOW",
  None = "NONE",
  Unknown = "UNKNOWN"
}

export type BillingConfirmed = SecondScreenEvent & {
  __typename?: "BillingConfirmed";
  deviceId: Scalars["String"];
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type BookedService = {
  __typename?: "BookedService";
  dates: Array<BookedServiceDate>;
  service?: Maybe<BookedServiceDetails>;
};

export type BookedServiceDate = {
  __typename?: "BookedServiceDate";
  /**
   *  We split one ServiceOrder with a single getPrice() field into n entries depending on serviceOrder.getQuantity().
   *  So we don't know the actual price of this entry, just the calculated average
   */
  amount?: Maybe<Price>;
  serviceDate?: Maybe<Scalars["LocalDate"]>;
  tags?: Maybe<Array<BookedServiceTag>>;
};

export type BookedServiceDetails = {
  __typename?: "BookedServiceDetails";
  included?: Maybe<Scalars["Boolean"]>;
  magicServiceCodeEnum?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pmsServiceId: Scalars["String"];
  tags?: Maybe<Array<BookedServiceTag>>;
  totalAmount?: Maybe<Price>;
};

export enum BookedServiceTag {
  /** KEY */
  Included = "INCLUDED",
  Purchased = "PURCHASED"
}

export type Booking = {
  __typename?: "Booking";
  actor: Actor;
  bookingComment?: Maybe<Scalars["String"]>;
  expectedNumberOfReservations: Scalars["Int"];
  flowState: FlowState;
  id: Scalars["Int"];
  magicId: Scalars["String"];
  magicLink: Scalars["String"];
  magicToken: Scalars["String"];
  pmsId: Scalars["String"];
  reservations?: Maybe<Array<Maybe<Reservation>>>;
  userAccountUuid?: Maybe<Scalars["String"]>;
};

export enum BookingChannelFilterValue {
  Agoda = "AGODA",
  Airbnb = "AIRBNB",
  Booking = "BOOKING",
  Direct = "DIRECT",
  Expedia = "EXPEDIA",
  Ibe = "IBE",
  Other = "OTHER"
}

export type BookingFilter = {
  id?: InputMaybe<Scalars["Int"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
};

export enum BookingOnBehalfOf {
  Myself = "MYSELF",
  Others = "OTHERS"
}

export type BookingOverview = {
  __typename?: "BookingOverview";
  actor: Actor;
  bookingId: Scalars["Int"];
  flowState: FlowState;
  items: Array<BookingOverviewItem>;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  pmsBookingId: Scalars["String"];
  userAccountUuid?: Maybe<Scalars["String"]>;
};

export type BookingOverviewItem = {
  __typename?: "BookingOverviewItem";
  active: Scalars["Boolean"];
  arrival: Scalars["Instant"];
  bookingId: Scalars["Int"];
  bookingManagementCompleted: Scalars["Boolean"];
  departure: Scalars["Instant"];
  id: Scalars["String"];
  pmsBookingId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  propertyId: Scalars["Int"];
  reservations: Array<Reservation>;
  travelBuddies: Array<TravelBuddy>;
};

export type BookingPrice = {
  __typename?: "BookingPrice";
  accommodation?: Maybe<Price>;
  prepaid?: Maybe<Scalars["Boolean"]>;
  prepayment?: Maybe<GuaranteeType>;
  total?: Maybe<Price>;
};

export type CancelTerminalPaymentRequest = {
  pmsPropertyId: Scalars["String"];
  serviceId: Scalars["String"];
  terminalId: Scalars["String"];
  transactionId: Scalars["String"];
};

export type Canceled = SecondScreenEvent & {
  __typename?: "Canceled";
  deviceId: Scalars["String"];
  payload: CanceledPayload;
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type CanceledPayload = {
  __typename?: "CanceledPayload";
  pmsReservationId: Scalars["String"];
};

export type CancellationFee = {
  __typename?: "CancellationFee";
  description?: Maybe<Scalars["String"]>;
  dueDateTime?: Maybe<Scalars["Instant"]>;
  name?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
  price?: Maybe<GrossPrice>;
};

export type CardOnFilePaymentRequest = {
  amount: Amount;
  applySurcharges?: InputMaybe<Scalars["Boolean"]>;
  magicId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  pointOfSales: Scalars["Boolean"];
  shopperReference: Scalars["String"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
  token: Scalars["String"];
};

export type CardPaymentMethod = {
  __typename?: "CardPaymentMethod";
  hashCode: Scalars["String"];
  paymentMethods?: Maybe<Array<Maybe<PossiblePaymentMethod>>>;
  storedPaymentMethods?: Maybe<Array<Maybe<AdyenStoredPaymentMethod>>>;
  type: PaymentMethodType;
};

export type CashPaymentMethod = {
  __typename?: "CashPaymentMethod";
  action?: Maybe<Scalars["String"]>;
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export enum Category {
  Accommodation = "ACCOMMODATION",
  Additional = "ADDITIONAL",
  CityTax = "CITY_TAX",
  Deposit = "DEPOSIT",
  ExchangeRateDifference = "EXCHANGE_RATE_DIFFERENCE",
  Fee = "FEE",
  Others = "OTHERS",
  Rebate = "REBATE",
  Surcharge = "SURCHARGE",
  TaxCorrection = "TAX_CORRECTION"
}

export enum ChargeMode {
  EndOfReservation = "END_OF_RESERVATION",
  Once = "ONCE",
  PerPerson = "PER_PERSON",
  PerPersonPerTimeUnit = "PER_PERSON_PER_TIME_UNIT",
  StartOfReservation = "START_OF_RESERVATION",
  TimeUnit = "TIME_UNIT"
}

export enum ChargeUnit {
  Booking = "BOOKING",
  Person = "PERSON",
  Reservation = "RESERVATION"
}

export type CleaningDto = {
  __typename?: "CleaningDTO";
  adults?: Maybe<Scalars["Int"]>;
  arrival?: Maybe<Scalars["Instant"]>;
  arrivalPmsReservationId?: Maybe<Scalars["String"]>;
  children?: Maybe<Scalars["Int"]>;
  condition: UnitState;
  contractualCheckInTime?: Maybe<Scalars["Instant"]>;
  departure?: Maybe<Scalars["Instant"]>;
  departurePmsReservationId?: Maybe<Scalars["String"]>;
  expectedDeparture?: Maybe<Scalars["Instant"]>;
  floorNumber?: Maybe<Scalars["Int"]>;
  name: Scalars["String"];
  occupied: Scalars["Boolean"];
  pmsUnitGroupIds?: Maybe<Array<Maybe<Scalars["String"]>>>;
  pmsUnitId: Scalars["String"];
  priority: Priority;
  servicePmsReservationId?: Maybe<Scalars["String"]>;
  services?: Maybe<Array<Maybe<BackofficeService>>>;
};

export type Comment = {
  __typename?: "Comment";
  comment: Scalars["String"];
  pmsId?: Maybe<Scalars["String"]>;
  pmsType?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
  type: CommentTypeEnum;
};

export enum CommentTypeEnum {
  Booker = "BOOKER",
  Booking = "BOOKING",
  Guest = "GUEST",
  Other = "OTHER",
  Reservation = "RESERVATION"
}

export enum CommunicationChannel {
  Email = "EMAIL",
  Sms = "SMS",
  Whatsapp = "WHATSAPP"
}

export enum CommunicationLanguage {
  De = "DE",
  En = "EN",
  Fr = "FR",
  It = "IT"
}

export enum DebitorType {
  Company = "COMPANY",
  Guest = "GUEST",
  Others = "OTHERS",
  Virtual = "VIRTUAL"
}

export type DoorAccessRequestor = {
  __typename?: "DoorAccessRequestor";
  principal?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
  travelBuddyGuestId?: Maybe<Scalars["String"]>;
};

export enum DoorAccessState {
  None = "NONE",
  Public = "PUBLIC",
  PublicAndPrivate = "PUBLIC_AND_PRIVATE",
  PublicInhouse = "PUBLIC_INHOUSE"
}

export enum DoorAccessStatus {
  NotOk = "NOT_OK",
  Ok = "OK"
}

export enum DoorLockAccessibility {
  BackofficeOnly = "BACKOFFICE_ONLY",
  Private = "PRIVATE",
  PrivateService = "PRIVATE_SERVICE",
  Public = "PUBLIC",
  PublicInhouse = "PUBLIC_INHOUSE",
  Restricted = "RESTRICTED",
  Service = "SERVICE"
}

export type DropinPayloadDetails = {
  redirectResult?: InputMaybe<Scalars["String"]>;
};

export type DropinPayloadInput = {
  details?: InputMaybe<DropinPayloadDetails>;
};

export type FlowState = IFlowState & {
  __typename?: "FlowState";
  completed: Scalars["Boolean"];
  context: Scalars["Json"];
  fastCheckinAvailable: Scalars["Boolean"];
  notificationPending: Scalars["Boolean"];
  notificationSent: Scalars["Boolean"];
  performedActions: Array<Scalars["String"]>;
  sentNotifications: Array<Scalars["String"]>;
  wasOrIsCompleted: Scalars["Boolean"];
};

export enum FlowStateFilterValue {
  Completed = "COMPLETED",
  NotStarted = "NOT_STARTED",
  Started = "STARTED"
}

export type FlowStateUpdateInput = {
  billConfirmed?: InputMaybe<Scalars["Boolean"]>;
  bookingOnBehalfOf?: InputMaybe<BookingOnBehalfOf>;
  cleanUnitDialogSeen?: InputMaybe<Scalars["Boolean"]>;
  context?: InputMaybe<Scalars["Json"]>;
  dirtyUnitDialogSeen?: InputMaybe<Scalars["Boolean"]>;
  notificationPending?: InputMaybe<Scalars["Boolean"]>;
};

export type Folio = {
  __typename?: "Folio";
  closed?: Maybe<Scalars["Instant"]>;
  created?: Maybe<Scalars["Instant"]>;
  debitorType: DebitorType;
  groupedCharges: Array<GroupedCharge>;
  id?: Maybe<Scalars["Int"]>;
  metadata: Scalars["Json"];
  number?: Maybe<Scalars["String"]>;
  orderItems: Array<OrderItem>;
  origin?: Maybe<Origin>;
  payments: Array<Payment>;
  pendingPayments: Array<PendingPayment>;
  pmsCompanyId?: Maybe<Scalars["String"]>;
  pmsCustomerId?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
  prepaymentType: PrepaymentType;
  propertyId?: Maybe<Scalars["Int"]>;
  totalAllowedPayment?: Maybe<GrossPrice>;
  totalCharges?: Maybe<Price>;
  totalPayments?: Maybe<GrossPrice>;
  type: FolioType;
  updated?: Maybe<Scalars["Instant"]>;
};

export enum FolioTargetType {
  ExistingFolio = "EXISTING_FOLIO",
  NewFolio = "NEW_FOLIO"
}

export enum FolioType {
  Invoice = "INVOICE",
  Receipt = "RECEIPT"
}

export type FrontendPayload = {
  additionalDetailsDTO?: InputMaybe<AdditionalDetails>;
  dropinPayload?: InputMaybe<Scalars["Json"]>;
};

export enum Gender {
  Female = "FEMALE",
  Male = "MALE",
  Other = "OTHER"
}

export type GenericFilter = {
  filters?: InputMaybe<Scalars["Json"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
};

export enum Granularity {
  AnyDay = "ANY_DAY",
  AnyDayExceptArrivalDay = "ANY_DAY_EXCEPT_ARRIVAL_DAY",
  Never = "NEVER",
  WholeStay = "WHOLE_STAY",
  WholeStayPayOnce = "WHOLE_STAY_PAY_ONCE"
}

export type GrossPrice = {
  __typename?: "GrossPrice";
  currency?: Maybe<Scalars["String"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]>;
};

export type GrossPriceInput = {
  currency: Scalars["String"];
  grossPriceInCents: Scalars["Int"];
};

export type GroupedCharge = {
  __typename?: "GroupedCharge";
  hide: Scalars["Boolean"];
  name: Scalars["String"];
  pmsServiceId?: Maybe<Scalars["String"]>;
  prepayable: Scalars["Boolean"];
  subName?: Maybe<Scalars["String"]>;
  totalPrice: Price;
  totalQuantity: Scalars["Int"];
  translatedNames?: Maybe<Scalars["Json"]>;
};

export type GroupedRateBreakdown = {
  __typename?: "GroupedRateBreakdown";
  rateSpliceTypeTuples?: Maybe<Array<RateSpliceTypeEnumTuple>>;
};

export enum GuaranteeType {
  Company = "COMPANY",
  CreditCard = "CREDIT_CARD",
  Pm6Hold = "PM6HOLD",
  Prepayment = "PREPAYMENT"
}

export enum GuaranteeTypeFilterValue {
  Company = "COMPANY",
  CreditCard = "CREDIT_CARD",
  Pm6Hold = "PM6HOLD",
  Prepayment = "PREPAYMENT"
}

export enum GuestFlowCheckPoint {
  Address = "ADDRESS",
  Confirmation = "CONFIRMATION",
  Legal = "LEGAL",
  Overview = "OVERVIEW",
  Payment = "PAYMENT",
  PersonalData = "PERSONAL_DATA",
  PreferredChannel = "PREFERRED_CHANNEL",
  Services = "SERVICES",
  TermsAndConditions = "TERMS_AND_CONDITIONS"
}

export enum GuestJourneyCheckoutFlowCheckpoint {
  Bill = "BILL",
  Confirmation = "CONFIRMATION",
  DepartureTime = "DEPARTURE_TIME",
  Minibar = "MINIBAR",
  Payment = "PAYMENT"
}

export type IFlowState = {
  completed: Scalars["Boolean"];
  context: Scalars["Json"];
  fastCheckinAvailable: Scalars["Boolean"];
  notificationPending: Scalars["Boolean"];
  notificationSent: Scalars["Boolean"];
  performedActions: Array<Scalars["String"]>;
  sentNotifications: Array<Scalars["String"]>;
  wasOrIsCompleted: Scalars["Boolean"];
};

export enum IdCheckStatus {
  Confirmed = "CONFIRMED",
  Declined = "DECLINED",
  Reuploaded = "REUPLOADED",
  Unconfirmed = "UNCONFIRMED"
}

export type InitiatePaymentResponse = PaymentAction | Void;

export type MagicFileDescriptor = {
  __typename?: "MagicFileDescriptor";
  contentLength: Scalars["Int"];
  contentType: Scalars["String"];
  fileName: Scalars["String"];
  id: Scalars["String"];
  magicFileType: Scalars["String"];
  ownerId: Scalars["String"];
};

export type MagicObject = Booking | Reservation | TravelBuddy;

export enum MatchingBehaviour {
  All = "ALL",
  Any = "ANY"
}

export enum MemberShipClassEnum {
  FrequentFlyer = "FREQUENT_FLYER",
  LoyaltyProgram = "LOYALTY_PROGRAM",
  Other = "OTHER"
}

export type MembershipAccount = {
  __typename?: "MembershipAccount";
  membershipClass?: Maybe<MemberShipClassEnum>;
  membershipId?: Maybe<Scalars["String"]>;
  membershipTypeCode?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
};

export type MembershipAccountInput = {
  membershipClass?: InputMaybe<MemberShipClassEnum>;
  membershipId?: InputMaybe<Scalars["String"]>;
  membershipTypeCode?: InputMaybe<Scalars["String"]>;
};

export type MewsPaymentMethod = {
  __typename?: "MewsPaymentMethod";
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type MoveOrderItemsInput = {
  folioTargetType: FolioTargetType;
  pmsOrderItemIds: Array<Scalars["String"]>;
  pmsPropertyId: Scalars["String"];
  sourcePmsFolioId: Scalars["String"];
  sourcePmsReservationId: Scalars["String"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
  targetPmsFolioId?: InputMaybe<Scalars["String"]>;
  targetPmsReservationId: Scalars["String"];
};

export type MovePaymentInput = {
  folioTargetType: FolioTargetType;
  pmsPaymentIds: Array<Scalars["String"]>;
  pmsPropertyId: Scalars["String"];
  sourcePmsFolioId: Scalars["String"];
  sourcePmsReservationId: Scalars["String"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
  targetPmsFolioId?: InputMaybe<Scalars["String"]>;
  targetPmsReservationId: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Adds membership to the primary guest. */
  AddMembershipToPrimaryGuest?: Maybe<Scalars["Boolean"]>;
  AddNoteToProfile: ProfileNote;
  AddPreferenceToProfile: UserProfile;
  AddServicesToReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  /** Open any door using admin rights. */
  AdminOpenDoor?: Maybe<Scalars["Boolean"]>;
  BackofficeAddArbitraryOrderItems?: Maybe<Scalars["Boolean"]>;
  BackofficeAddMembershipToProfile?: Maybe<Scalars["Boolean"]>;
  /** Adds new services to the reservation from backoffice context. */
  BackofficeAddServicesToReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  /** Post Adjustment charge for order item */
  BackofficeAdjustOrderItem?: Maybe<Array<Maybe<OrderItem>>>;
  /** Sends a Canceled request for a specific second screen with the pmsReservationId */
  BackofficeCancelProcessOnSecondScreen?: Maybe<Scalars["Boolean"]>;
  /**
   * Update the flow state context manual checkin checkpoint for a reservation from backoffice context.
   * Used during Checkin wizard to skip sign via tablet or payment steps. Skip to services step would mean to set the
   * checkpoint to SERVICES.
   */
  BackofficeManualCheckinSkipToStep?: Maybe<Scalars["Boolean"]>;
  /**
   * Sends SecondScreenEvents: VerifyBill, VerifyAndSign, Canceled to a specific second screen device
   * using the pmsReservationId
   */
  BackofficePerformSecondScreenAction?: Maybe<Scalars["Boolean"]>;
  /** Sends a sign and verify request for a specific reservation to a second screen device identified by the deviceId. */
  BackofficeSignViaTablet?: Maybe<Scalars["Boolean"]>;
  /** Updates the primary guest of a reservation identified by the pmsReservationId, from backoffice context. */
  BackofficeUpdatePrimaryGuest: Person;
  /** Updates the flow state for a reservation from backoffice context. */
  BackofficeUpdateReservationFlowState?: Maybe<ReservationFlowState>;
  /** Updates the secondary guests of a reservation identified by the pmsReservationId, from backoffice context. */
  BackofficeUpdateSecondaryGuestDetails: Array<Person>;
  BulkUpdateUnitState?: Maybe<Scalars["Boolean"]>;
  Cancel?: Maybe<Reservation>;
  /**
   * Cancel an ongoing terminal payment using the transaction id and service id retrieved upon initiation of the
   * terminal payment.
   */
  CancelTerminalPayment?: Maybe<Scalars["Boolean"]>;
  CardOnFilePayment?: Maybe<Scalars["Boolean"]>;
  CashPayment?: Maybe<Scalars["String"]>;
  Checkin?: Maybe<Reservation>;
  Checkout?: Maybe<Reservation>;
  CreateBooking: Booking;
  CreatePaymentLink?: Maybe<PaymentLinkResponse>;
  /**  CreatePaymentIntent(paymentIntentRequest: PaymentIntentRequest!): PaymentIntentResponse */
  CreatePaymentRequest: PaymentRequestResponse;
  CreateTemplatedTask?: Maybe<Scalars["Boolean"]>;
  DeleteNote: Scalars["Boolean"];
  /**
   *  Deletes the given user account and removes it from bookings, reservations, and user profile
   *  if userAccountUuid is not specified -> deletes the logged-in user account
   *  only admin and operators can delete userAccountUuid of other users
   */
  DeleteUserAccount: Scalars["Boolean"];
  EncodeKey?: Maybe<MagicObject>;
  ExpirePaymentLink?: Maybe<PaymentLinkResponse>;
  InitiatePayment?: Maybe<InitiatePaymentResponse>;
  LogDoorAccess?: Maybe<MagicObject>;
  /** Move order items from one folio to another. */
  MoveOrderItems?: Maybe<Scalars["Boolean"]>;
  /** Move payments from one folio to another. */
  MovePayments?: Maybe<Scalars["Boolean"]>;
  OpenDoor?: Maybe<MagicObject>;
  PayAndCreateBooking: PayAndCreateBookingResponse;
  PayDetailsAndCreateBooking: Booking;
  PaymentDetails?: Maybe<Void>;
  /**
   * Performs a specific reservation action on a reservation identified by the pmsReservationId, with the given payload.
   * The action must be in the allowed list for the reservation for it to work.
   */
  PerformAction?: Maybe<Scalars["Boolean"]>;
  /**  "Post a payment for a magicId. Only positive values are supported." */
  PostPayment?: Maybe<Array<Maybe<PerformedPaymentResponse>>>;
  RefundPayment?: Maybe<Void>;
  RemovePreferenceFromProfile: UserProfile;
  /**  Note: not supported by mews-connector */
  RemoveServicesFromReservation?: Maybe<Array<Maybe<ServiceOrder>>>;
  RequestAccess?: Maybe<Scalars["Boolean"]>;
  /**
   * This mutation is used to trigger an action from the second screen device.
   * eventType can be any event implementing SecondScreenEventType. The payload type can specific to the event type.
   */
  SecondScreenAction?: Maybe<Scalars["Boolean"]>;
  /**
   *  If logged-in user has no account, it creates one with the given password. Otherwise it updates the password
   *  then it protects all bookings and reservations where the user is primary guest or booker
   *  can be called with magicId of a booking (from the booker) or reservation (from the primary guest)
   */
  SetPassword: UserProfile;
  /**
   * Synchronizes the door access for specific reservation identified by the pmsReservationId, for the level of access
   * defined by the status field.
   */
  SyncDoorAccess?: Maybe<Scalars["Boolean"]>;
  SyncPaymentRequest: PaymentRequestResponse;
  /**
   *  Unlinks the given idp
   *  if userAccountUuid is not specified -> unlink from the logged-in user account
   *  only admin and operators can unlink idps of other users
   */
  UnlinkIdp: Scalars["Boolean"];
  /**
   * Updates the personal data of the booker;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateBookerDetails: Person;
  UpdateEstimatedDepartureTime?: Maybe<Scalars["Boolean"]>;
  UpdateFlowState: ReservationFlowState;
  /**
   * Updates the personal data of the main actor from a specific context (reservation, travel buddy, booking).
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateMainActor: Person;
  UpdateNote: ProfileNote;
  /**
   * Updates the personal data of the booker;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdatePreferredCommunicationChannel: Person;
  UpdateReservationTermsAndConditions: Reservation;
  /**
   * Updates the personal data of the secondary guests;
   * This is used from a magicId/magicToken authentication context e.g Guest Journey
   * by the guests to self service their reservation.
   */
  UpdateSecondaryGuestDetails: Array<Person>;
  UpdateUnitState: Unit;
};

export type MutationAddMembershipToPrimaryGuestArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  membershipAccount: MembershipAccountInput;
};

export type MutationAddNoteToProfileArgs = {
  note: NoteInput;
  userProfileId: Scalars["Int"];
};

export type MutationAddPreferenceToProfileArgs = {
  pmsPreferenceId: Scalars["String"];
  userProfileId: Scalars["Int"];
};

export type MutationAddServicesToReservationArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  services: Array<ServiceOrderRequest>;
};

export type MutationAdminOpenDoorArgs = {
  doorId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
};

export type MutationBackofficeAddArbitraryOrderItemsArgs = {
  addArbitraryOrderItemsInput: Array<AddArbitraryOrderItemInput>;
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeAddMembershipToProfileArgs = {
  membershipAccount: MembershipAccountInput;
  pmsProfileId: Scalars["String"];
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeAddServicesToReservationArgs = {
  pmsReservationId: Scalars["String"];
  services: Array<ServiceOrderRequest>;
};

export type MutationBackofficeAdjustOrderItemArgs = {
  adjustOrderItemInput?: InputMaybe<AdjustOrderItemInput>;
  pmsPropertyId: Scalars["String"];
};

export type MutationBackofficeCancelProcessOnSecondScreenArgs = {
  deviceId: Scalars["String"];
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeManualCheckinSkipToStepArgs = {
  checkpoint: BackofficeManualCheckInCheckpoint;
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficePerformSecondScreenActionArgs = {
  deviceId: Scalars["String"];
  eventType: SecondScreenEventType;
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeSignViaTabletArgs = {
  deviceId: Scalars["String"];
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeUpdatePrimaryGuestArgs = {
  person: UpdatePersonInput;
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeUpdateReservationFlowStateArgs = {
  flowState: FlowStateUpdateInput;
  pmsReservationId: Scalars["String"];
};

export type MutationBackofficeUpdateSecondaryGuestDetailsArgs = {
  persons: Array<SecondaryPersonInput>;
  pmsReservationId: Scalars["String"];
};

export type MutationBulkUpdateUnitStateArgs = {
  pmsUnitIds: Array<Scalars["String"]>;
  state: UnitState;
};

export type MutationCancelArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationCancelTerminalPaymentArgs = {
  cancelTerminalPaymentRequest: CancelTerminalPaymentRequest;
};

export type MutationCardOnFilePaymentArgs = {
  cardOnFilePaymentRequest: CardOnFilePaymentRequest;
};

export type MutationCashPaymentArgs = {
  cashPaymentRequest?: InputMaybe<PaymentRequest>;
  magicId: Scalars["String"];
};

export type MutationCheckinArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationCheckoutArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationCreateBookingArgs = {
  booking: PotentialBooking;
};

export type MutationCreatePaymentLinkArgs = {
  paymentLinkRequest?: InputMaybe<PaymentLinkRequest>;
};

export type MutationCreatePaymentRequestArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  paymentRequest: PaymentRequestInput;
};

export type MutationCreateTemplatedTaskArgs = {
  due?: InputMaybe<Scalars["Instant"]>;
  guestNote?: InputMaybe<Scalars["String"]>;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  taskTemplate: TaskTemplate;
};

export type MutationDeleteNoteArgs = {
  pmsProfileNoteId: Scalars["String"];
};

export type MutationDeleteUserAccountArgs = {
  userAccountUuid?: InputMaybe<Scalars["String"]>;
};

export type MutationEncodeKeyArgs = {
  encoderId: Scalars["String"];
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationExpirePaymentLinkArgs = {
  paymentLinkId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
};

export type MutationInitiatePaymentArgs = {
  magicId: Scalars["String"];
  payment: WidgetPaymentRequest;
};

export type MutationLogDoorAccessArgs = {
  doorAccessStatus: DoorAccessStatus;
  doorId: Scalars["String"];
  doorLog?: InputMaybe<Scalars["String"]>;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationMoveOrderItemsArgs = {
  moveOrderItemsInput: MoveOrderItemsInput;
};

export type MutationMovePaymentsArgs = {
  movePaymentsInput: MovePaymentInput;
};

export type MutationOpenDoorArgs = {
  doorId: Scalars["String"];
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationPayAndCreateBookingArgs = {
  booking: PotentialBooking;
  payment: WidgetPaymentRequest;
  pmsPropertyId: Scalars["String"];
};

export type MutationPayDetailsAndCreateBookingArgs = {
  booking: PotentialBooking;
  payment: WidgetPaymentDetailsRequest;
  pmsPropertyId: Scalars["String"];
};

export type MutationPaymentDetailsArgs = {
  magicId: Scalars["String"];
  paymentDetails: WidgetPaymentDetailsRequest;
};

export type MutationPerformActionArgs = {
  payload?: InputMaybe<Scalars["Json"]>;
  pmsReservationId: Scalars["String"];
  reservationAction: ReservationAction;
};

export type MutationPostPaymentArgs = {
  performedPayment: PerformedPaymentInput;
};

export type MutationRefundPaymentArgs = {
  refundPaymentRequest?: InputMaybe<RefundPaymentRequest>;
};

export type MutationRemovePreferenceFromProfileArgs = {
  pmsPreferenceId: Scalars["String"];
  userProfileId: Scalars["Int"];
};

export type MutationRemoveServicesFromReservationArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  serviceOrderIds: Array<Scalars["String"]>;
};

export type MutationRequestAccessArgs = {
  magicId: Scalars["String"];
};

export type MutationSecondScreenActionArgs = {
  deviceId: Scalars["String"];
  eventType: SecondScreenEventType;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  payload?: InputMaybe<Scalars["Json"]>;
};

export type MutationSetPasswordArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  password: Scalars["String"];
};

export type MutationSyncDoorAccessArgs = {
  pmsReservationId: Scalars["String"];
  status: DoorAccessState;
};

export type MutationSyncPaymentRequestArgs = {
  magicId: Scalars["String"];
};

export type MutationUnlinkIdpArgs = {
  idp: Scalars["String"];
  userAccountUuid?: InputMaybe<Scalars["String"]>;
};

export type MutationUpdateBookerDetailsArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  person: PersonInput;
};

export type MutationUpdateEstimatedDepartureTimeArgs = {
  estimatedDepartureTime: Scalars["Instant"];
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationUpdateFlowStateArgs = {
  flowState: FlowStateUpdateInput;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationUpdateMainActorArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  person: UpdatePersonInput;
};

export type MutationUpdateNoteArgs = {
  note: NoteInput;
  pmsProfileNoteId: Scalars["String"];
};

export type MutationUpdatePreferredCommunicationChannelArgs = {
  channel?: InputMaybe<CommunicationChannel>;
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
};

export type MutationUpdateReservationTermsAndConditionsArgs = {
  magicToken: Scalars["String"];
  pmsReservationId: Scalars["String"];
  tc: ReservationTermsAndConditionsInput;
};

export type MutationUpdateSecondaryGuestDetailsArgs = {
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  persons: Array<SecondaryPersonInput>;
};

export type MutationUpdateUnitStateArgs = {
  pmsUnitId: Scalars["String"];
  state: UnitState;
};

export type NoteInput = {
  pmsProfileNoteCategoryId?: InputMaybe<Scalars["String"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]>;
  text?: InputMaybe<Scalars["String"]>;
  title?: InputMaybe<Scalars["String"]>;
};

/**  ch.aaap.magic.common.notification.guest.journey.NotificationTemplate */
export enum NotificationType {
  AccountActions = "ACCOUNT_ACTIONS",
  AccountEmailTest = "ACCOUNT_EMAIL_TEST",
  AccountEmailVerification = "ACCOUNT_EMAIL_VERIFICATION",
  AccountResetPassword = "ACCOUNT_RESET_PASSWORD",
  BookerFlowOverview = "BOOKER_FLOW_OVERVIEW",
  BookerMagicOnboarding = "BOOKER_MAGIC_ONBOARDING",
  BookingCreated = "BOOKING_CREATED",
  BookingReminder = "BOOKING_REMINDER",
  BuddyFlowOverview = "BUDDY_FLOW_OVERVIEW",
  CheckedIn = "CHECKED_IN",
  Checkin = "CHECKIN",
  CheckinIntroduction = "CHECKIN_INTRODUCTION",
  CheckinReminder = "CHECKIN_REMINDER",
  CheckoutAlert = "CHECKOUT_ALERT",
  CheckoutFollowUp = "CHECKOUT_FOLLOW_UP",
  CheckoutIntroduction = "CHECKOUT_INTRODUCTION",
  CheckoutReminder = "CHECKOUT_REMINDER",
  CompanionAdded = "COMPANION_ADDED",
  GuestFlowOverview = "GUEST_FLOW_OVERVIEW",
  GuestMagicOnboarding = "GUEST_MAGIC_ONBOARDING",
  Invoice = "INVOICE",
  InvoiceBilling = "INVOICE_BILLING",
  MarketingConsent = "MARKETING_CONSENT",
  NewsLetter_1 = "NEWS_LETTER_1",
  NewsLetter_2 = "NEWS_LETTER_2",
  NewsLetter_3 = "NEWS_LETTER_3",
  PayByLink = "PAY_BY_LINK",
  PreCheckinReminder = "PRE_CHECKIN_REMINDER",
  ResendGuestflowLink = "RESEND_GUESTFLOW_LINK",
  ReservationCanceled = "RESERVATION_CANCELED",
  ReservationCreated = "RESERVATION_CREATED"
}

export type OptionalPersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
};

export type OrderItem = {
  __typename?: "OrderItem";
  accountingState: AccountingState;
  category: Category;
  created?: Maybe<Scalars["Instant"]>;
  end?: Maybe<Scalars["Instant"]>;
  hide?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Int"];
  included?: Maybe<Scalars["Boolean"]>;
  name?: Maybe<Scalars["String"]>;
  note?: Maybe<Scalars["String"]>;
  pmsAccountId?: Maybe<Scalars["String"]>;
  pmsFolioId?: Maybe<Scalars["String"]>;
  pmsId: Scalars["String"];
  pmsReservationId?: Maybe<Scalars["String"]>;
  pmsServiceId?: Maybe<Scalars["String"]>;
  prepaid?: Maybe<Scalars["Boolean"]>;
  price: Price;
  quantity?: Maybe<Scalars["Int"]>;
  start?: Maybe<Scalars["Instant"]>;
  subName?: Maybe<Scalars["String"]>;
  translatedNames?: Maybe<Scalars["Json"]>;
};

export type OrderItemAdjustmentReasonCode = {
  __typename?: "OrderItemAdjustmentReasonCode";
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pmsId: Scalars["String"];
};

export type OrderItemTransactionType = {
  __typename?: "OrderItemTransactionType";
  defaultPrice?: Maybe<GrossPrice>;
  name?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
  transactionTypeCode?: Maybe<Scalars["String"]>;
};

export type OrderItemsPage = {
  __typename?: "OrderItemsPage";
  content: Array<OrderItem>;
  totalElements: Scalars["Int"];
};

export type OrderItemsPageFilter = {
  filters?: InputMaybe<Scalars["Json"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
  userProfileId?: InputMaybe<Scalars["Int"]>;
};

export enum Origin {
  Apaleo = "APALEO",
  Likemagic = "LIKEMAGIC",
  Mews = "MEWS",
  Ohip = "OHIP"
}

export enum Page {
  Homepage = "HOMEPAGE"
}

export type Pageable = {
  pageNumber: Scalars["Int"];
  pageSize: Scalars["Int"];
  sort?: InputMaybe<Scalars["String"]>;
};

export type PayAndCreateBookingResponse = Booking | PaymentAction;

export type PayAtCheckinPaymentMethod = {
  __typename?: "PayAtCheckinPaymentMethod";
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type PayAtCheckoutPaymentMethod = {
  __typename?: "PayAtCheckoutPaymentMethod";
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type Payment = {
  __typename?: "Payment";
  created?: Maybe<Scalars["Instant"]>;
  folioId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  pmsAccountId?: Maybe<Scalars["String"]>;
  pmsFolioId?: Maybe<Scalars["String"]>;
  pmsId: Scalars["String"];
  price: Price;
  refundStatus?: Maybe<RefundStatus>;
  refundable: Scalars["Boolean"];
  state: PaymentState;
  type: PaymentType;
  updated?: Maybe<Scalars["Instant"]>;
};

export type PaymentAction = {
  __typename?: "PaymentAction";
  checkoutPaymentsAction?: Maybe<Scalars["Json"]>;
  correlationId?: Maybe<Scalars["String"]>;
  transitoryPaymentDTO?: Maybe<TransitoryPayment>;
};

export type PaymentDataInput = {
  paymentMethod?: InputMaybe<PaymentMethodInput>;
};

export type PaymentDetailsRequest = {
  dropinPayload?: InputMaybe<DropinPayloadInput>;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]>;
};

export type PaymentIntentRequest = {
  grossPrice: GrossPriceInput;
};

export type PaymentIntentResponse = {
  __typename?: "PaymentIntentResponse";
  clientSecret?: Maybe<Scalars["String"]>;
};

export type PaymentLinkPaymentMethod = {
  __typename?: "PaymentLinkPaymentMethod";
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type PaymentLinkRequest = {
  amount: Amount;
  email: Scalars["String"];
  expiresAt?: InputMaybe<Scalars["Instant"]>;
  magicId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  shopperReference?: InputMaybe<Scalars["String"]>;
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
};

export type PaymentLinkResponse = {
  __typename?: "PaymentLinkResponse";
  expiresAt?: Maybe<Scalars["Instant"]>;
  paymentLinkId: Scalars["String"];
  url: Scalars["String"];
};

export type PaymentMethod =
  | CardPaymentMethod
  | CashPaymentMethod
  | MewsPaymentMethod
  | PayAtCheckinPaymentMethod
  | PayAtCheckoutPaymentMethod
  | PaymentLinkPaymentMethod
  | PreAuthorizationPaymentMethod
  | StoredPaymentMethod
  | TerminalPaymentMethod;

export type PaymentMethodInput = {
  encryptedCard?: InputMaybe<Scalars["String"]>;
  encryptedExpiryMonth?: InputMaybe<Scalars["String"]>;
  encryptedExpiryYear?: InputMaybe<Scalars["String"]>;
  encryptedSecurityCode?: InputMaybe<Scalars["String"]>;
  holderName?: InputMaybe<Scalars["String"]>;
  riskData?: InputMaybe<Scalars["String"]>;
  tokenizedCard?: InputMaybe<Scalars["String"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export enum PaymentMethodType {
  AdyenWidget = "ADYEN_WIDGET",
  Cash = "CASH",
  MewsPayment = "MEWS_PAYMENT",
  PaymentLink = "PAYMENT_LINK",
  PayAtCheckin = "PAY_AT_CHECKIN",
  PayAtCheckout = "PAY_AT_CHECKOUT",
  PreAuthorization = "PRE_AUTHORIZATION",
  StoredPaymentMethod = "STORED_PAYMENT_METHOD",
  TerminalPayment = "TERMINAL_PAYMENT"
}

export type PaymentMethodsResponse = {
  __typename?: "PaymentMethodsResponse";
  groups?: Maybe<Array<Maybe<Scalars["String"]>>>;
  oneClickPaymentMethods?: Maybe<Array<Maybe<Scalars["String"]>>>;
  paymentMethods?: Maybe<Array<Maybe<PossiblePaymentMethod>>>;
  storedPaymentMethods?: Maybe<Array<Maybe<StoredPaymentMethod>>>;
  storingMethods?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type PaymentRequest = {
  amount?: InputMaybe<GrossPriceInput>;
  frontendPayload?: InputMaybe<FrontendPayload>;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]>;
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
};

export type PaymentRequestInput = {
  grossPrice: GrossPriceInput;
  services: Array<ServiceOrderRequest>;
};

export type PaymentRequestResponse = {
  __typename?: "PaymentRequestResponse";
  paymentLink: Scalars["String"];
  /**  todo remove this, probably not needed by FE */
  pmsId: Scalars["String"];
  state: Scalars["String"];
};

export enum PaymentState {
  Canceled = "CANCELED",
  Charged = "CHARGED",
  Failed = "FAILED",
  Pending = "PENDING",
  Verifing = "VERIFING"
}

export enum PaymentType {
  Cash = "CASH",
  CreditCard = "CREDIT_CARD",
  Invoice = "INVOICE",
  Others = "OTHERS",
  Virtual = "VIRTUAL"
}

export type PendingPayment = {
  __typename?: "PendingPayment";
  created?: Maybe<Scalars["Instant"]>;
  id: Scalars["String"];
  pmsFolioId?: Maybe<Scalars["String"]>;
  price: Price;
};

export type PerformedPaymentInput = {
  grossPrice: GrossPriceInput;
  magicId: Scalars["String"];
  paymentId: Scalars["String"];
};

export type PerformedPaymentResponse = {
  __typename?: "PerformedPaymentResponse";
  pmsPaymentId?: Maybe<Scalars["String"]>;
};

export type Person = {
  __typename?: "Person";
  address?: Maybe<Address>;
  age?: Maybe<Scalars["Int"]>;
  birthdate?: Maybe<Scalars["LocalDate"]>;
  email?: Maybe<Scalars["String"]>;
  emergencyEvacuationHelpNeeded: Scalars["Boolean"];
  enrolledInLoyaltyProgram: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  id: Scalars["Int"];
  identificationDocumentNumber?: Maybe<Scalars["String"]>;
  identificationDocumentType?: Maybe<Scalars["String"]>;
  isMinor?: Maybe<Scalars["Boolean"]>;
  lastName?: Maybe<Scalars["String"]>;
  membershipAccounts: Array<MembershipAccount>;
  nationality?: Maybe<Scalars["String"]>;
  phone?: Maybe<Scalars["String"]>;
  pmsId: Scalars["String"];
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]>;
  publicTransportationTickets?: Maybe<Array<Maybe<PublicTransportationTicket>>>;
  termsAndConditionsGeneral: Scalars["Boolean"];
  termsAndConditionsMarketingConsent: Scalars["Boolean"];
  termsAndConditionsMarketingConsentDoubleOptin: Scalars["Boolean"];
  termsAndConditionsOnline: Scalars["Boolean"];
  /** userProfile is only available to ADMIN, SERVICE_ACCOUNT, OPERATOR, HOST, HOUSE_KEEPER */
  userProfile?: Maybe<UserProfile>;
  userProfileId?: Maybe<Scalars["Int"]>;
  vipCode?: Maybe<Scalars["String"]>;
};

export type PersonAddressInput = {
  addressLine1?: InputMaybe<Scalars["String"]>;
  addressLine2?: InputMaybe<Scalars["String"]>;
  city?: InputMaybe<Scalars["String"]>;
  countryCode?: InputMaybe<Scalars["String"]>;
  postalCode?: InputMaybe<Scalars["String"]>;
  region?: InputMaybe<Scalars["String"]>;
};

export type PersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  identificationDocumentNumber?: InputMaybe<Scalars["String"]>;
  identificationDocumentType?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
};

export type PmsIdRateSplicesTuple = {
  __typename?: "PmsIdRateSplicesTuple";
  pmsId?: Maybe<Scalars["String"]>;
  rateSplices?: Maybe<Array<BackofficeRateSplice>>;
};

export type PmsIdToRateSplicesTuple = {
  __typename?: "PmsIdToRateSplicesTuple";
  pmsId?: Maybe<Scalars["String"]>;
  rateSplices?: Maybe<Array<RateSplice>>;
};

export type PossiblePaymentMethod = {
  __typename?: "PossiblePaymentMethod";
  brand?: Maybe<Scalars["String"]>;
  brands?: Maybe<Array<Maybe<Scalars["String"]>>>;
  configuration?: Maybe<Scalars["Json"]>;
  fundingSource?: Maybe<Scalars["String"]>;
  group?: Maybe<Scalars["Json"]>;
  inputDetails?: Maybe<Scalars["String"]>;
  issuers?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

export type PotentialBooking = {
  booker?: InputMaybe<OptionalPersonInput>;
  reservations: Array<InputMaybe<PotentialReservation>>;
};

export type PotentialReservation = {
  adultsAmount: Scalars["Int"];
  arrival: Scalars["Instant"];
  childrenAmount: Scalars["Int"];
  departure: Scalars["Instant"];
  pmsPropertyId: Scalars["String"];
  pmsRatePlanId: Scalars["String"];
  pmsUnitGroupId: Scalars["String"];
};

export type PreAuthorizationPaymentMethod = {
  __typename?: "PreAuthorizationPaymentMethod";
  availableTerminals?: Maybe<Array<Maybe<AvailableTerminal>>>;
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type Preference = {
  __typename?: "Preference";
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pmsId: Scalars["String"];
  pmsPreferenceCategoryId: Scalars["String"];
  /** A null pmsPropertyId means the preference is a global one */
  pmsPropertyId?: Maybe<Scalars["String"]>;
};

export type PreferenceCategory = {
  __typename?: "PreferenceCategory";
  description?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  pmsId: Scalars["String"];
  preferences: Array<Preference>;
};

export type PreferenceCategoryPreferencesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
};

export enum PrepaymentType {
  AgodaVcc = "AGODA_VCC",
  Airbnb = "AIRBNB",
  BookingDotCom = "BOOKING_DOT_COM",
  BookingDotComBankTransferPrepayment = "BOOKING_DOT_COM_BANK_TRANSFER_PREPAYMENT",
  BookingDotComVccCreditCard = "BOOKING_DOT_COM_VCC_CREDIT_CARD",
  BookingDotComVccPrepayment = "BOOKING_DOT_COM_VCC_PREPAYMENT",
  CheckoutOnAr = "CHECKOUT_ON_AR",
  CheckoutOnArCustomer = "CHECKOUT_ON_AR_CUSTOMER",
  Ctrip = "CTRIP",
  Expedia = "EXPEDIA",
  GenericOta = "GENERIC_OTA",
  Hotelbeds = "HOTELBEDS",
  None = "NONE",
  NotDeterminable = "NOT_DETERMINABLE",
  Prepaid = "PREPAID",
  RealCreditCard = "REAL_CREDIT_CARD"
}

export type Price = {
  __typename?: "Price";
  currency?: Maybe<Scalars["String"]>;
  grossPrice?: Maybe<Scalars["BigDecimal"]>;
  grossPriceInCents?: Maybe<Scalars["Int"]>;
  netPrice?: Maybe<Scalars["BigDecimal"]>;
  netPriceInCents?: Maybe<Scalars["Int"]>;
  taxes?: Maybe<Array<Maybe<Tax>>>;
};

export enum PriceAdjustmentType {
  FlatAmount = "FLAT_AMOUNT",
  Percentage = "PERCENTAGE"
}

export type PrimaryGuestFilter = {
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
};

export enum Priority {
  High = "HIGH",
  Low = "LOW",
  Medium = "MEDIUM"
}

export type ProfileNote = {
  __typename?: "ProfileNote";
  category?: Maybe<ProfileNoteCategory>;
  pmsId: Scalars["String"];
  pmsProfileNoteCategoryId?: Maybe<Scalars["String"]>;
  pmsPropertyId?: Maybe<Scalars["String"]>;
  text?: Maybe<Scalars["String"]>;
  title?: Maybe<Scalars["String"]>;
};

export type ProfileNoteCategory = {
  __typename?: "ProfileNoteCategory";
  name?: Maybe<Scalars["String"]>;
  pmsId: Scalars["String"];
};

export type PropertiesByCityAggregate = {
  __typename?: "PropertiesByCityAggregate";
  grouped?: Maybe<Array<Maybe<PropertyGroup>>>;
};

export type Property = {
  __typename?: "Property";
  address?: Maybe<Address>;
  availableDoors?: Maybe<Array<Maybe<AvailableDoorDto>>>;
  defaultCurrency?: Maybe<Scalars["String"]>;
  id: Scalars["Int"];
  name?: Maybe<Scalars["String"]>;
  origin?: Maybe<Origin>;
  pmsId: Scalars["String"];
  supportedSyncStates?: Maybe<Array<Maybe<DoorAccessState>>>;
  timezone?: Maybe<Scalars["String"]>;
  toBeCleanedToday?: Maybe<Array<Maybe<CleaningDto>>>;
};

export type PropertyFilter = {
  id?: InputMaybe<Scalars["Int"]>;
  name?: InputMaybe<Scalars["String"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
};

export type PropertyGroup = {
  __typename?: "PropertyGroup";
  key?: Maybe<Scalars["String"]>;
  value?: Maybe<Array<Maybe<Property>>>;
};

export type PublicTransportationTicket = {
  __typename?: "PublicTransportationTicket";
  code?: Maybe<Scalars["String"]>;
  link?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  ownerMagicId?: Maybe<Scalars["String"]>;
  valid?: Maybe<Scalars["Boolean"]>;
  validUntil?: Maybe<Scalars["Instant"]>;
};

export type Query = {
  __typename?: "Query";
  /**
   * Returns the available additional services for a specific reservation identified by the pmsReservationId.
   * Returns an empty list if no additional services are available.
   */
  BackofficeGetAdditionalServicesAvailability?: Maybe<
    Array<Maybe<AdditionalServiceAvailabilityDto>>
  >;
  /** Returns the counts of the reservation conditions (Minor, Critical, Ok) based on the given filter. */
  ConditionCounts?: Maybe<Scalars["Json"]>;
  /**
   *  not used & no plan to be used by FE => removing it for now
   *  TODO completely remove if confirmed not needed
   *  GetServices(pmsPropertyId: String!, pmsReservationId: String): [Service]
   */
  GetAdditionalServicesAvailability?: Maybe<Array<Maybe<AdditionalServiceAvailabilityDto>>>;
  GetAllBookings?: Maybe<Array<Maybe<Booking>>>;
  GetAllReservations?: Maybe<Array<Maybe<Reservation>>>;
  GetAvailableUnitGroups: Array<UnitGroupAvailability>;
  GetBooking?: Maybe<Booking>;
  GetBookingOverviews: Array<BookingOverview>;
  GetGuests?: Maybe<Array<Maybe<Person>>>;
  GetLatestMagicObject?: Maybe<MagicObject>;
  GetMagicBookingOverviews: Array<BookingOverview>;
  GetMagicObject?: Maybe<MagicObject>;
  GetOrderItemAdjustmentReasonCodes: Array<OrderItemAdjustmentReasonCode>;
  GetOrderItemTransactionTypes: Array<OrderItemTransactionType>;
  GetOrderItemsPage: OrderItemsPage;
  GetPaymentMethods: Array<PaymentMethod>;
  GetPaymentMethodsMagicId: Array<PaymentMethod>;
  GetPotentialBookingPrice?: Maybe<BookingPrice>;
  GetPotentialReservationPrice?: Maybe<Price>;
  /** Get relevant preferences by property. If pmsPropertyIds is null -> return all of them */
  GetPreferences: Array<PreferenceCategory>;
  /** Get relevant preferences by property. If pmsPropertyIds is null -> return all of them */
  GetProfileNoteCategories: Array<ProfileNoteCategory>;
  GetProperties?: Maybe<Array<Maybe<Property>>>;
  GetPropertiesByCity?: Maybe<PropertiesByCityAggregate>;
  GetProperty?: Maybe<Property>;
  GetReservation?: Maybe<Reservation>;
  GetReservationsPage: ReservationsPage;
  GetTotalUnpaidAmount?: Maybe<GrossPrice>;
  GetUnitGroups?: Maybe<Array<Maybe<UnitGroup>>>;
  GetUnits?: Maybe<Array<Maybe<Unit>>>;
  /** Profile of the caller */
  GetUserProfile?: Maybe<UserProfile>;
  /** Gets UserProfile by userProfileId or null if not found */
  GetUserProfileById?: Maybe<UserProfile>;
  /** List of user profiles */
  GetUserProfiles: UserProfilesPage;
  /** Returns the details of a specific reservation identified by the pmsReservationId. */
  OverviewDetails?: Maybe<BackofficeReservationOverviewDetail>;
  /** Returns a list of reservations based on the given filter. The result is paginated. */
  OverviewTable?: Maybe<BackofficeReservationOverviewTablePage>;
  SearchReservations: Array<ReservationSearchResult>;
  ValidatePromoCodeMagic: ValidatePromoCodeMagicResponse;
  ValidatePromoCodePms: Scalars["Boolean"];
};

export type QueryBackofficeGetAdditionalServicesAvailabilityArgs = {
  pmsReservationId: Scalars["String"];
};

export type QueryConditionCountsArgs = {
  filter: BackofficeConditionCountsFilter;
};

export type QueryGetAdditionalServicesAvailabilityArgs = {
  magicId: Scalars["String"];
  shopTypesIncludeFilter?: InputMaybe<Array<InputMaybe<ShopType>>>;
};

export type QueryGetAllBookingsArgs = {
  filter?: InputMaybe<BookingFilter>;
};

export type QueryGetAllReservationsArgs = {
  filter?: InputMaybe<ReservationFilter>;
};

export type QueryGetAvailableUnitGroupsArgs = {
  filter: UnitGroupAvailabilityFilter;
};

export type QueryGetBookingArgs = {
  filter?: InputMaybe<BookingFilter>;
};

export type QueryGetBookingOverviewsArgs = {
  active: Scalars["Boolean"];
};

export type QueryGetGuestsArgs = {
  filter?: InputMaybe<PersonInput>;
};

export type QueryGetMagicBookingOverviewsArgs = {
  magicId: Scalars["String"];
};

export type QueryGetMagicObjectArgs = {
  magicId: Scalars["String"];
};

export type QueryGetOrderItemAdjustmentReasonCodesArgs = {
  pmsPropertyId: Scalars["String"];
};

export type QueryGetOrderItemTransactionTypesArgs = {
  pmsPropertyId: Scalars["String"];
};

export type QueryGetOrderItemsPageArgs = {
  filter: OrderItemsPageFilter;
};

export type QueryGetPaymentMethodsArgs = {
  pmsPropertyId: Scalars["String"];
  shopperReference: Scalars["String"];
};

export type QueryGetPaymentMethodsMagicIdArgs = {
  magicId?: InputMaybe<Scalars["String"]>;
  shopperReference: Scalars["String"];
};

export type QueryGetPotentialBookingPriceArgs = {
  booking?: InputMaybe<PotentialBooking>;
};

export type QueryGetPotentialReservationPriceArgs = {
  reservation?: InputMaybe<PotentialReservation>;
};

export type QueryGetPreferencesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryGetProfileNoteCategoriesArgs = {
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type QueryGetPropertiesArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetPropertiesByCityArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetPropertyArgs = {
  filter?: InputMaybe<PropertyFilter>;
};

export type QueryGetReservationArgs = {
  filter?: InputMaybe<ReservationFilter>;
};

export type QueryGetReservationsPageArgs = {
  filter: ReservationsPageFilter;
};

export type QueryGetTotalUnpaidAmountArgs = {
  magicId: Scalars["String"];
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type QueryGetUnitGroupsArgs = {
  filter?: InputMaybe<UnitGroupFilter>;
};

export type QueryGetUnitsArgs = {
  filter?: InputMaybe<UnitFilter>;
};

export type QueryGetUserProfileByIdArgs = {
  userProfileId: Scalars["Int"];
};

export type QueryGetUserProfilesArgs = {
  filter: GenericFilter;
};

export type QueryOverviewDetailsArgs = {
  pmsReservationId: Scalars["String"];
};

export type QueryOverviewTableArgs = {
  filter: BackofficeReservationOverviewFilter;
};

export type QuerySearchReservationsArgs = {
  filter: ReservationSearchFilter;
};

export type QueryValidatePromoCodeMagicArgs = {
  promoCode: Scalars["String"];
};

export type QueryValidatePromoCodePmsArgs = {
  promoCode: Scalars["String"];
};

export type Rate = {
  __typename?: "Rate";
  averagePricePerNight: GrossPrice;
  cancellationFees: Array<CancellationFee>;
  currentCancellationFee?: Maybe<CancellationFee>;
  guaranteeType?: Maybe<GuaranteeType>;
  ratePlan?: Maybe<RatePlan>;
  totalPrice: GrossPrice;
};

export type RatePlan = {
  __typename?: "RatePlan";
  name?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
};

export type RateSplice = {
  __typename?: "RateSplice";
  date?: Maybe<Scalars["LocalDate"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  grossPrice?: Maybe<GrossPrice>;
  hide?: Maybe<Scalars["Boolean"]>;
  pmsId?: Maybe<Scalars["String"]>;
  prepaid?: Maybe<Scalars["Boolean"]>;
  prepaymentType?: Maybe<PrepaymentType>;
  quantity?: Maybe<Scalars["Int"]>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export enum RateSpliceTypeEnumContract {
  Accommodation = "ACCOMMODATION",
  Service = "SERVICE"
}

export type RateSpliceTypeEnumTuple = {
  __typename?: "RateSpliceTypeEnumTuple";
  pmsIdToRateSplicesTuples?: Maybe<Array<PmsIdToRateSplicesTuple>>;
  totalCharges?: Maybe<GrossPrice>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export type RateSpliceTypeTuple = {
  __typename?: "RateSpliceTypeTuple";
  pmsIdRateSplicesTuples?: Maybe<Array<PmsIdRateSplicesTuple>>;
  type?: Maybe<RateSpliceTypeEnumContract>;
};

export enum RecurringFilterValue {
  New = "NEW",
  Recurring = "RECURRING"
}

export type RefundPaymentRequest = {
  folioMetadata?: InputMaybe<Scalars["Json"]>;
  magicId: Scalars["String"];
  pmsPaymentId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
};

export enum RefundStatus {
  Failed = "FAILED",
  Initiated = "INITIATED",
  Refunded = "REFUNDED"
}

export type Reservation = ReservationIdentification & {
  __typename?: "Reservation";
  accessibleDoors?: Maybe<Array<Maybe<AccessibleDoor>>>;
  actor: Actor;
  adultsAmount?: Maybe<Scalars["Int"]>;
  arrival: Scalars["Instant"];
  bookedServicesOverview: Array<BookedService>;
  booker?: Maybe<Person>;
  booking?: Maybe<Booking>;
  bookingOverviewItemId: Scalars["String"];
  cancellationFees: Array<CancellationFee>;
  cancellationTime?: Maybe<Scalars["Instant"]>;
  channel?: Maybe<Scalars["String"]>;
  checkedInBy?: Maybe<ReservationCheckInOutAuthor>;
  checkedOutBy?: Maybe<ReservationCheckInOutAuthor>;
  checkinTime?: Maybe<Scalars["Instant"]>;
  checkoutTime?: Maybe<Scalars["Instant"]>;
  childrenAmount?: Maybe<Scalars["Int"]>;
  createdAt?: Maybe<Scalars["Instant"]>;
  currentCancellationFee?: Maybe<CancellationFee>;
  departure: Scalars["Instant"];
  displayId: Scalars["String"];
  estimatedArrivalTime?: Maybe<Scalars["Instant"]>;
  estimatedDepartureTime?: Maybe<Scalars["Instant"]>;
  extraPmsComplexProperties?: Maybe<Scalars["Json"]>;
  extraPmsSimpleProperties?: Maybe<Scalars["Json"]>;
  files: Array<MagicFileDescriptor>;
  flowState: ReservationFlowState;
  folios: Array<Folio>;
  foliosToBePaid: Array<Folio>;
  groupedRateBreakdown?: Maybe<GroupedRateBreakdown>;
  guaranteeType?: Maybe<GuaranteeType>;
  id: Scalars["Int"];
  idCheckStatus: IdCheckStatus;
  isExternalReservation: Scalars["Boolean"];
  magicId: Scalars["String"];
  magicLink: Scalars["String"];
  magicToken: Scalars["String"];
  maxCompanions: Scalars["Int"];
  origin?: Maybe<Origin>;
  pmsBookingId: Scalars["String"];
  pmsId: Scalars["String"];
  primaryGuest?: Maybe<Person>;
  property: Property;
  reservationComment?: Maybe<Scalars["String"]>;
  reservationConflicts?: Maybe<Array<Maybe<ReservationConflict>>>;
  secondaryGuests?: Maybe<Array<Maybe<Person>>>;
  status: ReservationStatus;
  termsAndConditionsMinors?: Maybe<Scalars["Boolean"]>;
  totalAllowedPayment?: Maybe<GrossPrice>;
  totalCharges?: Maybe<Price>;
  totalPayments?: Maybe<GrossPrice>;
  travelBuddies: Array<TravelBuddy>;
  unit?: Maybe<Unit>;
  unitGroup?: Maybe<UnitGroup>;
  userAccountUuid?: Maybe<Scalars["String"]>;
};

export enum ReservationAction {
  /**
   *     EDIT_PREFERENCES
   *     EDIT_PREFERENCES_TEXT
   */
  AssignUnit = "ASSIGN_UNIT",
  /**     CHANGE_STATUS_TO_NO_SHOW */
  ChangeIdCheckStatus = "CHANGE_ID_CHECK_STATUS",
  ChangeStatusToCanceled = "CHANGE_STATUS_TO_CANCELED",
  ChangeStatusToCheckedOut = "CHANGE_STATUS_TO_CHECKED_OUT",
  ChangeStatusToInHouse = "CHANGE_STATUS_TO_IN_HOUSE",
  ChangeUnitConditionToClean = "CHANGE_UNIT_CONDITION_TO_CLEAN",
  ChangeUnitConditionToCleanToBeInspected = "CHANGE_UNIT_CONDITION_TO_CLEAN_TO_BE_INSPECTED",
  /**     CHANGE_RESERVATION_COMMENT */
  ChangeUnitConditionToDirty = "CHANGE_UNIT_CONDITION_TO_DIRTY",
  CopyGuestflowLink = "COPY_GUESTFLOW_LINK",
  /**     RESET_GUEST_FLOW */
  EncodeKey = "ENCODE_KEY",
  JumpToBooking = "JUMP_TO_BOOKING",
  JumpToReservation = "JUMP_TO_RESERVATION",
  ManualCheckin = "MANUAL_CHECKIN",
  ManualCheckout = "MANUAL_CHECKOUT",
  ResendGuestflowLink = "RESEND_GUESTFLOW_LINK",
  ResendNotification = "RESEND_NOTIFICATION",
  SendCustomNotification = "SEND_CUSTOM_NOTIFICATION",
  /**     CHANGE_PIN_CONFIGURATION */
  ValidateReservation = "VALIDATE_RESERVATION"
}

export enum ReservationCheckInOutAuthor {
  Backoffice = "BACKOFFICE",
  CheckinWizard = "CHECKIN_WIZARD",
  Guest = "GUEST",
  Kiosk = "KIOSK",
  LmAutomation = "LM_AUTOMATION",
  Pms = "PMS"
}

export enum ReservationCondition {
  Critical = "CRITICAL",
  Minor = "MINOR",
  Ok = "OK",
  Unknown = "UNKNOWN"
}

export type ReservationConflict = {
  __typename?: "ReservationConflict";
  conflict?: Maybe<ReservationConflictType>;
  reservationId?: Maybe<Scalars["Int"]>;
};

export enum ReservationConflictType {
  InvalidEmail = "INVALID_EMAIL",
  InvalidPhoneNumber = "INVALID_PHONE_NUMBER",
  NotAttachedToUserAccount = "NOT_ATTACHED_TO_USER_ACCOUNT",
  NoUserprofileExists = "NO_USERPROFILE_EXISTS",
  OverlappingStaySamePrimaryGuestDifferentBooking = "OVERLAPPING_STAY_SAME_PRIMARY_GUEST_DIFFERENT_BOOKING",
  OverlappingStaySamePrimaryGuestSameBooking = "OVERLAPPING_STAY_SAME_PRIMARY_GUEST_SAME_BOOKING",
  SameArrivalSamePrimaryGuestDifferentBooking = "SAME_ARRIVAL_SAME_PRIMARY_GUEST_DIFFERENT_BOOKING",
  SameArrivalSamePrimaryGuestSameBooking = "SAME_ARRIVAL_SAME_PRIMARY_GUEST_SAME_BOOKING"
}

export type ReservationFilter = {
  id?: InputMaybe<Scalars["Int"]>;
  magicId?: InputMaybe<Scalars["String"]>;
  matchingBehaviour?: InputMaybe<MatchingBehaviour>;
  pmsId?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<ReservationStatus>;
};

export type ReservationFlowState = IFlowState & {
  __typename?: "ReservationFlowState";
  billConfirmed?: Maybe<Scalars["Boolean"]>;
  bookingOnBehalfOf?: Maybe<BookingOnBehalfOf>;
  cleanUnitDialogSeen?: Maybe<Scalars["Boolean"]>;
  completed: Scalars["Boolean"];
  context: Scalars["Json"];
  dirtyUnitDialogSeen?: Maybe<Scalars["Boolean"]>;
  fastCheckinAvailable: Scalars["Boolean"];
  notificationPending: Scalars["Boolean"];
  notificationSent: Scalars["Boolean"];
  performedActions: Array<Scalars["String"]>;
  sentNotifications: Array<Scalars["String"]>;
  unitCleanOnCheckin?: Maybe<Scalars["Boolean"]>;
  wasOrIsCompleted: Scalars["Boolean"];
};

export type ReservationIdentification = {
  id: Scalars["Int"];
  pmsId: Scalars["String"];
};

export type ReservationInput = {
  adultsAmount?: InputMaybe<Scalars["Int"]>;
  arrival?: InputMaybe<Scalars["Instant"]>;
  childrenAmount?: InputMaybe<Scalars["Int"]>;
  departure?: InputMaybe<Scalars["Instant"]>;
  pmsUnitId?: InputMaybe<Scalars["String"]>;
};

export type ReservationSearchFilter = {
  arrival: Scalars["LocalDate"];
  departure: Scalars["LocalDate"];
  pmsPropertyId?: InputMaybe<Scalars["String"]>;
  primaryGuest: PrimaryGuestFilter;
  propertyId?: InputMaybe<Scalars["Int"]>;
};

export type ReservationSearchResult = {
  __typename?: "ReservationSearchResult";
  adultsAmount?: Maybe<Scalars["Int"]>;
  childrenAmount?: Maybe<Scalars["Int"]>;
  magicId: Scalars["String"];
  unit?: Maybe<ReservationSearchResultUnit>;
  unitGroup?: Maybe<ReservationSearchResultUnitGroup>;
};

export type ReservationSearchResultUnit = {
  __typename?: "ReservationSearchResultUnit";
  name?: Maybe<Scalars["String"]>;
};

export type ReservationSearchResultUnitGroup = {
  __typename?: "ReservationSearchResultUnitGroup";
  id?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
};

export type ReservationServices = ReservationIdentification & {
  __typename?: "ReservationServices";
  id: Scalars["Int"];
  pmsId: Scalars["String"];
  serviceOrders?: Maybe<Array<Maybe<ServiceOrder>>>;
};

export enum ReservationStatus {
  Cancelled = "CANCELLED",
  CheckedOut = "CHECKED_OUT",
  Confirmed = "CONFIRMED",
  InHouse = "IN_HOUSE",
  NoShow = "NO_SHOW"
}

export type ReservationTermsAndConditionsInput = {
  termsAndConditionsMinors?: InputMaybe<Scalars["Boolean"]>;
};

export type ReservationsPage = {
  __typename?: "ReservationsPage";
  content: Array<Reservation>;
  totalElements: Scalars["Int"];
};

export type ReservationsPageFilter = {
  filters?: InputMaybe<Scalars["Json"]>;
  pageable: Pageable;
  /** if pmsPropertyIds is null or missing -> backend will include all properties */
  pmsPropertyIds?: InputMaybe<Array<Scalars["String"]>>;
  userProfileId?: InputMaybe<Scalars["Int"]>;
};

export type SecondScreenBackofficeEvent = BackofficeOverviewDetailEvent & {
  __typename?: "SecondScreenBackofficeEvent";
  payload: SecondScreenEvent;
  pmsReservationId: Scalars["String"];
  tenantName: Scalars["String"];
  type: BackofficeOverviewDetailEventType;
};

export type SecondScreenEvent = {
  deviceId: Scalars["String"];
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export enum SecondScreenEventType {
  BillingConfirmed = "BILLING_CONFIRMED",
  Canceled = "CANCELED",
  Signed = "SIGNED",
  VerifyAndSign = "VERIFY_AND_SIGN",
  VerifyAndSignAck = "VERIFY_AND_SIGN_ACK",
  VerifyBill = "VERIFY_BILL",
  VerifyBillAck = "VERIFY_BILL_ACK"
}

export type SecondaryPersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]>;
  email?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  lastName?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
};

export type Service = {
  __typename?: "Service";
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  active?: Maybe<Scalars["Boolean"]>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  chargeMode?: Maybe<ChargeMode>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  chargeUnit?: Maybe<ChargeUnit>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  included?: Maybe<Scalars["Boolean"]>;
  magicServiceCodeEnum?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  /**  TODO document properly. shouldn't be needed by FE, maybe used internally? */
  pmsCategoryId?: Maybe<Scalars["String"]>;
  pmsId?: Maybe<Scalars["String"]>;
  price?: Maybe<Price>;
  tags: Array<Scalars["String"]>;
};

export enum ServiceCodeEnum {
  BabyBed = "BABY_BED",
  BabyBed2 = "BABY_BED2",
  Breakfast = "BREAKFAST",
  Breakfast2 = "BREAKFAST2",
  Breakfast3 = "BREAKFAST3",
  Breakfast4 = "BREAKFAST4",
  Breakfast5 = "BREAKFAST5",
  Breakfast6 = "BREAKFAST6",
  ChildrenBreakfast1 = "CHILDREN_BREAKFAST1",
  ChildrenBreakfast2 = "CHILDREN_BREAKFAST2",
  ChildrenBreakfast3 = "CHILDREN_BREAKFAST3",
  DailyPet = "DAILY_PET",
  DonateBreakfast = "DONATE_BREAKFAST",
  EarlyCheckin = "EARLY_CHECKIN",
  ExternalBreakfast1 = "EXTERNAL_BREAKFAST1",
  ExternalBreakfast2 = "EXTERNAL_BREAKFAST2",
  ExternalBreakfast3 = "EXTERNAL_BREAKFAST3",
  ExternalBreakfast4 = "EXTERNAL_BREAKFAST4",
  Fitness1 = "FITNESS1",
  Fitness30 = "FITNESS30",
  FreeBreakfast = "FREE_BREAKFAST",
  FreeCleaning = "FREE_CLEANING",
  HairDryer = "HAIR_DRYER",
  LateCheckout = "LATE_CHECKOUT",
  NeckPillow = "NECK_PILLOW",
  PaidCleaning = "PAID_CLEANING",
  Parking = "PARKING",
  ParkingPerDay = "PARKING_PER_DAY",
  ParkingPerMonth = "PARKING_PER_MONTH",
  ParkingPerWeek = "PARKING_PER_WEEK",
  Storage = "STORAGE",
  Ventilator = "VENTILATOR",
  WelcomeDrink = "WELCOME_DRINK"
}

/** deprecated */
export type ServiceOrder = {
  __typename?: "ServiceOrder";
  date?: Maybe<Scalars["Instant"]>;
  pmsId?: Maybe<Scalars["String"]>;
  price?: Maybe<Price>;
  service: Service;
  tags: Array<Scalars["String"]>;
};

/** deprecated */
export type ServiceOrderRequest = {
  dates?: InputMaybe<Array<InputMaybe<Scalars["LocalDate"]>>>;
  grossPrice?: InputMaybe<GrossPriceInput>;
  pmsServiceId: Scalars["String"];
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** deprecated */
export type ServiceOverview = {
  __typename?: "ServiceOverview";
  dates: Array<ServiceOrder>;
  service: Service;
  totalPrice?: Maybe<GrossPrice>;
};

export enum ShopType {
  AdditionalServices = "ADDITIONAL_SERVICES",
  Amenities = "AMENITIES",
  LoyaltyShop = "LOYALTY_SHOP",
  Minibar = "MINIBAR",
  MinibarCheckoutFlow = "MINIBAR_CHECKOUT_FLOW",
  SelfPouringStation = "SELF_POURING_STATION"
}

export type Signed = SecondScreenEvent & {
  __typename?: "Signed";
  deviceId: Scalars["String"];
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type StoredPaymentConfig = {
  __typename?: "StoredPaymentConfig";
  brand?: Maybe<Scalars["String"]>;
  expiryMonth?: Maybe<Scalars["String"]>;
  expiryYear?: Maybe<Scalars["String"]>;
  holderName?: Maybe<Scalars["String"]>;
  iban?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  lastFour?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  ownerName?: Maybe<Scalars["String"]>;
  shopperEmail?: Maybe<Scalars["String"]>;
  supportedShopperInteractions?: Maybe<Array<Maybe<Scalars["String"]>>>;
  type?: Maybe<Scalars["String"]>;
};

export type StoredPaymentMethod = {
  __typename?: "StoredPaymentMethod";
  config?: Maybe<StoredPaymentConfig>;
  hashCode: Scalars["String"];
  pointOfSales: Scalars["Boolean"];
  type: PaymentMethodType;
};

export type StringWrapper = {
  __typename?: "StringWrapper";
  value: Scalars["String"];
};

export type Subscription = {
  __typename?: "Subscription";
  /**
   * Streams any event related to a specific reservation identified by the pmsReservationId,
   * in the ReservationOverviewDetails view. Events needs to implement BackofficeOverviewDetailEvent interface.
   * @deprecated Use the 'StreamBackofficeSecondScreenEvents' subscription instead
   */
  StreamBackofficeReservationOverviewDetails?: Maybe<BackofficeOverviewDetailEvent>;
  /**
   * Streams any event related to a specific reservation identified by the pmsReservationId,
   * in the ReservationOverviewDetails view. Events needs to implement BackofficeOverviewDetailEvent interface.
   */
  StreamBackofficeSecondScreenEvents?: Maybe<BackofficeOverviewDetailEvent>;
  StreamBooking?: Maybe<Booking>;
  StreamBookingOverview: BookingOverview;
  StreamMagicObject?: Maybe<MagicObject>;
  StreamReservation?: Maybe<Reservation>;
  /** Streams second screen related events for a specific second screen device, identified by the deviceId. */
  StreamSecondScreenDeviceEvents?: Maybe<SecondScreenEvent>;
  /**
   * Initiates a terminal payment. There will be two messages in the lifetime of this subscription.
   * The initial message gives information about the transaction id and the service id. The second and last message
   * indicates the outcome of the terminal payment.
   *
   * The transaction id uniquely identifies this interaction.
   * The service id identifies the terminal request.
   * Both are required to cancel an ongoing terminal payment. A terminal payment can only be cancelled until a payment
   * method will be presented and the payment interaction concludes.
   */
  TerminalPayment?: Maybe<TerminalResponse>;
};

export type SubscriptionStreamBackofficeReservationOverviewDetailsArgs = {
  pmsReservationId: Scalars["String"];
};

export type SubscriptionStreamBackofficeSecondScreenEventsArgs = {
  pmsReservationId: Scalars["String"];
};

export type SubscriptionStreamBookingArgs = {
  id: Scalars["Int"];
};

export type SubscriptionStreamBookingOverviewArgs = {
  magicId: Scalars["String"];
  magicToken?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionStreamMagicObjectArgs = {
  magicId: Scalars["String"];
  magicToken?: InputMaybe<Scalars["String"]>;
};

export type SubscriptionStreamReservationArgs = {
  id: Scalars["Int"];
};

export type SubscriptionStreamSecondScreenDeviceEventsArgs = {
  deviceId: Scalars["String"];
};

export type SubscriptionTerminalPaymentArgs = {
  paymentRequest?: InputMaybe<TerminalPaymentRequest>;
};

export enum TaskTemplate {
  CheckoutNote = "CHECKOUT_NOTE",
  /** ch.aaap.magic.common.TaskTemplate.java. But only the ones that the guest should be able to create */
  LuggagePickup = "LUGGAGE_PICKUP"
}

export type Tax = {
  __typename?: "Tax";
  amount?: Maybe<Scalars["BigDecimal"]>;
  amountInCents?: Maybe<Scalars["Int"]>;
  code?: Maybe<Scalars["String"]>;
};

export type TerminalPaymentMethod = {
  __typename?: "TerminalPaymentMethod";
  availableTerminals?: Maybe<Array<Maybe<AvailableTerminal>>>;
  hashCode: Scalars["String"];
  type: PaymentMethodType;
};

export type TerminalPaymentRequest = {
  amount?: InputMaybe<Amount>;
  magicId: Scalars["String"];
  pmsPropertyId: Scalars["String"];
  shopperReference: Scalars["String"];
  targetFolioMetadata?: InputMaybe<Scalars["Json"]>;
  terminalId: Scalars["String"];
};

export type TerminalResponse = {
  __typename?: "TerminalResponse";
  errorCode?: Maybe<Scalars["String"]>;
  errorReason?: Maybe<Scalars["String"]>;
  serviceId?: Maybe<Scalars["String"]>;
  success?: Maybe<Scalars["Boolean"]>;
  transactionId?: Maybe<Scalars["String"]>;
};

export type TransitoryPayment = {
  __typename?: "TransitoryPayment";
  paymentMethod?: Maybe<Scalars["String"]>;
  shopperReference?: Maybe<Scalars["String"]>;
};

export type TravelBuddy = {
  __typename?: "TravelBuddy";
  accessibleDoors?: Maybe<Array<Maybe<AccessibleDoor>>>;
  actor: Actor;
  arrival: Scalars["Instant"];
  bookingId: Scalars["Int"];
  bookingMagicId: Scalars["String"];
  bookingOverviewItemId: Scalars["String"];
  channel?: Maybe<Scalars["String"]>;
  checkinTime?: Maybe<Scalars["Instant"]>;
  checkoutTime?: Maybe<Scalars["Instant"]>;
  createdAt: Scalars["Instant"];
  currency?: Maybe<Scalars["String"]>;
  departure: Scalars["Instant"];
  flowState: FlowState;
  magicId: Scalars["String"];
  magicLink: Scalars["String"];
  magicToken: Scalars["String"];
  notificationLanguage?: Maybe<Scalars["String"]>;
  person: Person;
  pmsBookingId: Scalars["String"];
  /**  LM-1490 - what's the idea behind these two fields that contain the same thing */
  pmsLanguage?: Maybe<Scalars["String"]>;
  pmsPropertyId: Scalars["String"];
  pmsReservationId: Scalars["String"];
  /**  TODO not this PR - remove this DB id */
  propertyId: Scalars["Int"];
  /**  TODO not this PR - remove this DB id */
  reservationId: Scalars["Int"];
  reservationMagicId: Scalars["String"];
  status: ReservationStatus;
  unit?: Maybe<Unit>;
  /**
   *  TODO not this PR - why isn't this in FlowState?
   *  Does FE even need it?
   */
  unitCleanOnCheckin: Scalars["Boolean"];
  unitGroup?: Maybe<UnitGroup>;
  userAccountUuid?: Maybe<Scalars["String"]>;
  userProfileId?: Maybe<Scalars["Int"]>;
};

export type Unit = {
  __typename?: "Unit";
  accessibility?: Maybe<DoorLockAccessibility>;
  active?: Maybe<Scalars["Boolean"]>;
  batteryLevel?: Maybe<BatteryLevel>;
  floor?: Maybe<Scalars["String"]>;
  gatewayId?: Maybe<Scalars["String"]>;
  lockId?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  occupied?: Maybe<Scalars["Boolean"]>;
  online?: Maybe<Scalars["Boolean"]>;
  onlineLastChangeTimestamp?: Maybe<Scalars["Instant"]>;
  openAlert?: Maybe<Scalars["Boolean"]>;
  origin?: Maybe<Origin>;
  pmsId?: Maybe<Scalars["String"]>;
  pmsServiceId?: Maybe<Scalars["String"]>;
  pmsUnitGroupIds?: Maybe<Array<Scalars["String"]>>;
  privacyMode?: Maybe<Scalars["Boolean"]>;
  privacyModeLastChangeTimestamp?: Maybe<Scalars["Instant"]>;
  property?: Maybe<Property>;
  state?: Maybe<UnitState>;
};

export type UnitFilter = {
  floor?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Scalars["Int"]>;
  isActive?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
  origin?: InputMaybe<Origin>;
  pmsId?: InputMaybe<Scalars["String"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<UnitState>;
};

export type UnitGroup = {
  __typename?: "UnitGroup";
  active?: Maybe<Scalars["Boolean"]>;
  capacity?: Maybe<Scalars["Int"]>;
  extraCapacity?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  origin?: Maybe<Origin>;
  pmsId?: Maybe<Scalars["String"]>;
  property?: Maybe<Property>;
  serviceId?: Maybe<Scalars["String"]>;
};

export type UnitGroupAvailability = {
  __typename?: "UnitGroupAvailability";
  arrival: Scalars["Instant"];
  availableUnitsThreshold: Scalars["Int"];
  departure: Scalars["Instant"];
  numberOfAvailableUnits: Scalars["Int"];
  numberOfNights: Scalars["Int"];
  property?: Maybe<Property>;
  rates: Array<Rate>;
  unitGroup?: Maybe<UnitGroup>;
};

export type UnitGroupAvailabilityFilter = {
  adultsAmount: Scalars["Int"];
  arrival: Scalars["LocalDate"];
  childrenAmount: Scalars["Int"];
  departure: Scalars["LocalDate"];
  pmsPropertyId?: InputMaybe<Scalars["String"]>;
};

export type UnitGroupFilter = {
  active?: InputMaybe<Scalars["Boolean"]>;
  capacity?: InputMaybe<Scalars["Int"]>;
  extraCapacity?: InputMaybe<Scalars["Int"]>;
  id?: InputMaybe<Scalars["Int"]>;
  origin?: InputMaybe<Origin>;
  pmsId?: InputMaybe<Scalars["String"]>;
  pmsPropertyId?: InputMaybe<Scalars["String"]>;
  serviceId?: InputMaybe<Scalars["String"]>;
};

export type UnitInput = {
  floor?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<UnitState>;
};

export enum UnitState {
  Clean = "CLEAN",
  CleanToBeInspected = "CLEAN_TO_BE_INSPECTED",
  Dirty = "DIRTY",
  OutOfOrder = "OUT_OF_ORDER",
  OutOfService = "OUT_OF_SERVICE",
  Pickup = "PICKUP"
}

export type UpdatePersonInput = {
  address?: InputMaybe<PersonAddressInput>;
  birthdate?: InputMaybe<Scalars["LocalDate"]>;
  email?: InputMaybe<Scalars["String"]>;
  emergencyEvacuationHelpNeeded?: InputMaybe<Scalars["Boolean"]>;
  enrolledInLoyaltyProgram?: InputMaybe<Scalars["Boolean"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  identificationDocumentNumber?: InputMaybe<Scalars["String"]>;
  identificationDocumentType?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  nationality?: InputMaybe<Scalars["String"]>;
  phone?: InputMaybe<Scalars["String"]>;
  pmsId?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  termsAndConditionsGeneral?: InputMaybe<Scalars["Boolean"]>;
  termsAndConditionsMarketingConsent?: InputMaybe<Scalars["Boolean"]>;
  termsAndConditionsMarketingConsentDoubleOptin?: InputMaybe<Scalars["Boolean"]>;
  termsAndConditionsOnline?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateUserProfileInput = {
  pmsPreferenceIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type UserProfile = {
  __typename?: "UserProfile";
  address?: Maybe<Address>;
  birthdate?: Maybe<Scalars["LocalDate"]>;
  documents: UserProfileDocuments;
  email?: Maybe<Scalars["String"]>;
  emergencyEvacuationHelpNeeded: Scalars["Boolean"];
  enrolledInLoyaltyProgram: Scalars["Boolean"];
  firstName?: Maybe<Scalars["String"]>;
  gender?: Maybe<Gender>;
  guestIdentityId?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  identificationDocumentNumber?: Maybe<Scalars["String"]>;
  identificationDocumentType?: Maybe<Scalars["String"]>;
  identificationDocumentUploaded: Scalars["Boolean"];
  isEmailVerified?: Maybe<Scalars["Boolean"]>;
  isRecurring: Scalars["Boolean"];
  lastName?: Maybe<Scalars["String"]>;
  membershipAccounts: Array<MembershipAccount>;
  nationality?: Maybe<Scalars["String"]>;
  notes: Array<ProfileNote>;
  numberOfPastBookingsAsBooker: Scalars["Int"];
  numberOfPastReservationsAsPrimaryGuest: Scalars["Int"];
  numberOfPastReservationsAsTravelBuddy: Scalars["Int"];
  numberOfReservations: Scalars["Int"];
  phone?: Maybe<Scalars["String"]>;
  preferences: Array<Preference>;
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLanguage?: Maybe<Scalars["String"]>;
  signatureDocumentUploaded: Scalars["Boolean"];
  termsAndConditionsGeneral: Scalars["Boolean"];
  termsAndConditionsMarketingConsent: Scalars["Boolean"];
  termsAndConditionsMarketingConsentDoubleOptin: Scalars["Boolean"];
  termsAndConditionsOnline: Scalars["Boolean"];
  title?: Maybe<Scalars["String"]>;
  userAccountUuid?: Maybe<Scalars["String"]>;
  vipCode?: Maybe<Scalars["String"]>;
};

export type UserProfileDocuments = {
  __typename?: "UserProfileDocuments";
  passport?: Maybe<MagicFileDescriptor>;
  signature?: Maybe<MagicFileDescriptor>;
};

export type UserProfilesPage = {
  __typename?: "UserProfilesPage";
  content: Array<UserProfile>;
  totalElements: Scalars["Int"];
};

export type ValidatePromoCodeMagicResponse = {
  __typename?: "ValidatePromoCodeMagicResponse";
  discountHidden?: Maybe<Scalars["Boolean"]>;
  valid: Scalars["Boolean"];
};

export type VerifyAndSign = SecondScreenEvent & {
  __typename?: "VerifyAndSign";
  deviceId: Scalars["String"];
  payload: VerifyAndSignPayload;
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type VerifyAndSignAck = SecondScreenEvent & {
  __typename?: "VerifyAndSignAck";
  deviceId: Scalars["String"];
  payload: VerifyAndSignAckPayload;
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type VerifyAndSignAckPayload = {
  __typename?: "VerifyAndSignAckPayload";
  accepted: Scalars["Boolean"];
  reason?: Maybe<Scalars["String"]>;
};

export type VerifyAndSignPayload = {
  __typename?: "VerifyAndSignPayload";
  reservation: Reservation;
};

export type VerifyBill = SecondScreenEvent & {
  __typename?: "VerifyBill";
  deviceId: Scalars["String"];
  payload: VerifyBillPayload;
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type VerifyBillAck = SecondScreenEvent & {
  __typename?: "VerifyBillAck";
  deviceId: Scalars["String"];
  payload: VerifyBillAckPayload;
  tenantName: Scalars["String"];
  type: SecondScreenEventType;
};

export type VerifyBillAckPayload = {
  __typename?: "VerifyBillAckPayload";
  accepted: Scalars["Boolean"];
  reason?: Maybe<Scalars["String"]>;
};

export type VerifyBillPayload = {
  __typename?: "VerifyBillPayload";
  reservation: Reservation;
};

export enum Visibility {
  Backoffice = "BACKOFFICE",
  GuestFlow = "GUEST_FLOW",
  MyStay = "MY_STAY"
}

export type Void = {
  __typename?: "Void";
  /** Will never be filled */
  None?: Maybe<Scalars["ID"]>;
};

export type WidgetPaymentDetailsRequest = {
  amount: GrossPriceInput;
  frontendPayload: FrontendPayload;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference?: InputMaybe<Scalars["String"]>;
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type WidgetPaymentRequest = {
  amount: GrossPriceInput;
  frontendPayload: FrontendPayload;
  paymentData?: InputMaybe<PaymentDataInput>;
  reference: Scalars["String"];
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>>>;
};

export type DeleteUserAccountMutationVariables = Exact<{
  userAccountUuid?: InputMaybe<Scalars["String"]>;
}>;

export type DeleteUserAccountMutation = { __typename?: "Mutation"; DeleteUserAccount: boolean };

export type AddServicesToReservationMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  services: Array<ServiceOrderRequest> | ServiceOrderRequest;
}>;

export type AddServicesToReservationMutation = {
  __typename?: "Mutation";
  AddServicesToReservation?: Array<{
    __typename?: "ServiceOrder";
    pmsId?: string | null;
  } | null> | null;
};

export type CancelMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
}>;

export type CancelMutation = {
  __typename?: "Mutation";
  Cancel?: { __typename?: "Reservation"; pmsId: string } | null;
};

export type CheckinMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
}>;

export type CheckinMutation = {
  __typename?: "Mutation";
  Checkin?: { __typename?: "Reservation"; pmsId: string } | null;
};

export type CheckoutMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
}>;

export type CheckoutMutation = {
  __typename?: "Mutation";
  Checkout?: { __typename?: "Reservation"; pmsId: string } | null;
};

export type CreateBookingMutationVariables = Exact<{
  request: PotentialBooking;
}>;

export type CreateBookingMutation = {
  __typename?: "Mutation";
  CreateBooking: { __typename?: "Booking"; magicId: string; magicToken: string };
};

export type CreatePaymentIntentMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  request: PaymentRequestInput;
}>;

export type CreatePaymentIntentMutation = {
  __typename?: "Mutation";
  CreatePaymentRequest: { __typename?: "PaymentRequestResponse"; paymentLink: string };
};

export type CreatePaymentRequestMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  paymentRequest: PaymentRequestInput;
}>;

export type CreatePaymentRequestMutation = {
  __typename?: "Mutation";
  CreatePaymentRequest: {
    __typename?: "PaymentRequestResponse";
    paymentLink: string;
    pmsId: string;
    state: string;
  };
};

export type CreateTemplatedTaskMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  taskTemplate: TaskTemplate;
  guestNote?: InputMaybe<Scalars["String"]>;
  due?: InputMaybe<Scalars["Instant"]>;
}>;

export type CreateTemplatedTaskMutation = {
  __typename?: "Mutation";
  CreateTemplatedTask?: boolean | null;
};

export type EncodeKeyMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  encoderId: Scalars["String"];
}>;

export type EncodeKeyMutation = {
  __typename?: "Mutation";
  EncodeKey?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export type InitiatePaymentDetailsMutationVariables = Exact<{
  magicId: Scalars["String"];
  paymentDetailsRequest: WidgetPaymentDetailsRequest;
}>;

export type InitiatePaymentDetailsMutation = {
  __typename?: "Mutation";
  PaymentDetails?: { __typename?: "Void"; None?: string | null } | null;
};

export type InitiatePaymentMutationVariables = Exact<{
  magicId: Scalars["String"];
  paymentRequest: WidgetPaymentRequest;
}>;

export type InitiatePaymentMutation = {
  __typename?: "Mutation";
  InitiatePayment?:
    | {
        __typename: "PaymentAction";
        checkoutPaymentsAction?: any | null;
        transitoryPaymentDTO?: {
          __typename?: "TransitoryPayment";
          paymentMethod?: string | null;
          shopperReference?: string | null;
        } | null;
      }
    | { __typename: "Void"; None?: string | null }
    | null;
};

export type LogDoorAccessMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  doorId: Scalars["String"];
  doorAccesStatus: DoorAccessStatus;
  doorLog?: InputMaybe<Scalars["String"]>;
}>;

export type LogDoorAccessMutation = {
  __typename?: "Mutation";
  LogDoorAccess?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export type OpenDoorMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  doorId: Scalars["String"];
}>;

export type OpenDoorMutation = {
  __typename?: "Mutation";
  OpenDoor?:
    | { __typename: "Booking" }
    | { __typename: "Reservation" }
    | { __typename: "TravelBuddy" }
    | null;
};

export type PayAndCreateBookingMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"];
  payment: WidgetPaymentRequest;
  booking: PotentialBooking;
}>;

export type PayAndCreateBookingMutation = {
  __typename?: "Mutation";
  PayAndCreateBooking:
    | {
        __typename: "Booking";
        magicId: string;
        reservations?: Array<{ __typename?: "Reservation"; magicId: string } | null> | null;
      }
    | {
        __typename: "PaymentAction";
        checkoutPaymentsAction?: any | null;
        correlationId?: string | null;
        transitoryPaymentDTO?: {
          __typename?: "TransitoryPayment";
          paymentMethod?: string | null;
          shopperReference?: string | null;
        } | null;
      };
};

export type PayDetailsAndCreateBookingMutationVariables = Exact<{
  pmsPropertyId: Scalars["String"];
  payment: WidgetPaymentDetailsRequest;
  booking: PotentialBooking;
}>;

export type PayDetailsAndCreateBookingMutation = {
  __typename?: "Mutation";
  PayDetailsAndCreateBooking: {
    __typename?: "Booking";
    magicId: string;
    reservations?: Array<{ __typename?: "Reservation"; magicId: string } | null> | null;
  };
};

export type PostPaymentMutationVariables = Exact<{
  payment: PerformedPaymentInput;
}>;

export type PostPaymentMutation = {
  __typename?: "Mutation";
  PostPayment?: Array<{
    __typename?: "PerformedPaymentResponse";
    pmsPaymentId?: string | null;
  } | null> | null;
};

export type RequestAccessMutationVariables = Exact<{
  magicId: Scalars["String"];
}>;

export type RequestAccessMutation = { __typename?: "Mutation"; RequestAccess?: boolean | null };

export type SecondScreenActionMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  deviceId: Scalars["String"];
  eventType: SecondScreenEventType;
  payload?: InputMaybe<Scalars["Json"]>;
}>;

export type SecondScreenActionMutation = {
  __typename?: "Mutation";
  SecondScreenAction?: boolean | null;
};

export type SetPasswordMutationVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  password: Scalars["String"];
}>;

export type SetPasswordMutation = {
  __typename?: "Mutation";
  SetPassword: { __typename?: "UserProfile"; id: number; userAccountUuid?: string | null };
};

export type SyncPaymentRequestMutationVariables = Exact<{
  magicId: Scalars["String"];
}>;

export type SyncPaymentRequestMutation = {
  __typename?: "Mutation";
  SyncPaymentRequest: {
    __typename?: "PaymentRequestResponse";
    pmsId: string;
    state: string;
    paymentLink: string;
  };
};

export type UnlinkIdpMutationVariables = Exact<{
  idp: Scalars["String"];
  userAccountUuid?: InputMaybe<Scalars["String"]>;
}>;

export type UnlinkIdpMutation = { __typename?: "Mutation"; UnlinkIdp: boolean };

export type UpdateEstimatedDepartureTimeMutationVariables = Exact<{
  estimatedDepartureTime: Scalars["Instant"];
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
  context?: InputMaybe<Scalars["Json"]>;
  guestNote?: InputMaybe<Scalars["String"]>;
  dueDateLuggage?: InputMaybe<Scalars["Instant"]>;
  dueDateGuestNote?: InputMaybe<Scalars["Instant"]>;
  includeLuggagePickupTask: Scalars["Boolean"];
  includeCheckoutNoteTask: Scalars["Boolean"];
}>;

export type UpdateEstimatedDepartureTimeMutation = {
  __typename?: "Mutation";
  UpdateEstimatedDepartureTime?: boolean | null;
  createLuggagePickupTask?: boolean | null;
  createCheckoutNoteTask?: boolean | null;
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export type UpdateFlowStateMutationVariables = Exact<{
  magicId: Scalars["String"];
  flowState: FlowStateUpdateInput;
  magicToken: Scalars["String"];
}>;

export type UpdateFlowStateMutation = {
  __typename?: "Mutation";
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export type UpdatePreferredCommunicationChannelMutationVariables = Exact<{
  magicId: Scalars["String"];
  channel?: InputMaybe<CommunicationChannel>;
  flowState: FlowStateUpdateInput;
  person: UpdatePersonInput;
  magicToken: Scalars["String"];
}>;

export type UpdatePreferredCommunicationChannelMutation = {
  __typename?: "Mutation";
  UpdatePreferredCommunicationChannel: { __typename?: "Person"; email?: string | null };
  UpdateFlowState: { __typename: "ReservationFlowState" };
  UpdateMainActor: { __typename: "Person" };
};

export type UpdatePrimaryAndSecondaryGuestDetailsMutationVariables = Exact<{
  magicId: Scalars["String"];
  persons: Array<SecondaryPersonInput> | SecondaryPersonInput;
  primaryGuest: UpdatePersonInput;
  flowState: FlowStateUpdateInput;
  magicToken: Scalars["String"];
}>;

export type UpdatePrimaryAndSecondaryGuestDetailsMutation = {
  __typename?: "Mutation";
  UpdateMainActor: { __typename?: "Person"; email?: string | null };
  UpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; email?: string | null }>;
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export type UpdatePrimaryGuestMutationVariables = Exact<{
  magicId: Scalars["String"];
  person: UpdatePersonInput;
  flowState: FlowStateUpdateInput;
  magicToken: Scalars["String"];
}>;

export type UpdatePrimaryGuestMutation = {
  __typename?: "Mutation";
  UpdateMainActor: { __typename: "Person" };
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export type UpdateSecondaryGuestDetailsMutationVariables = Exact<{
  magicId: Scalars["String"];
  persons: Array<SecondaryPersonInput> | SecondaryPersonInput;
  magicToken: Scalars["String"];
}>;

export type UpdateSecondaryGuestDetailsMutation = {
  __typename?: "Mutation";
  UpdateSecondaryGuestDetails: Array<{ __typename?: "Person"; email?: string | null }>;
};

export type GetAdditionalServicesAvailabilityQueryVariables = Exact<{
  magicId: Scalars["String"];
}>;

export type GetAdditionalServicesAvailabilityQuery = {
  __typename?: "Query";
  GetAdditionalServicesAvailability?: Array<{
    __typename?: "AdditionalServiceAvailabilityDTO";
    pmsServiceId: string;
    available: boolean;
    bookedDates?: Array<any | null> | null;
    bookableDates?: Array<any | null> | null;
    visibilities?: Array<Visibility> | null;
    granularity: Granularity;
    payAtCheckout?: boolean | null;
    basePrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    };
  } | null> | null;
};

export type GetAvailableUnitGroupsQueryVariables = Exact<{
  filter: UnitGroupAvailabilityFilter;
}>;

export type GetAvailableUnitGroupsQuery = {
  __typename?: "Query";
  GetAvailableUnitGroups: Array<{
    __typename?: "UnitGroupAvailability";
    arrival: any;
    departure: any;
    numberOfAvailableUnits: number;
    availableUnitsThreshold: number;
    numberOfNights: number;
    property?: { __typename?: "Property"; pmsId: string } | null;
    rates: Array<{
      __typename?: "Rate";
      guaranteeType?: GuaranteeType | null;
      currentCancellationFee?: {
        __typename?: "CancellationFee";
        description?: string | null;
        dueDateTime?: any | null;
        name?: string | null;
        pmsId?: string | null;
        price?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPriceInCents?: number | null;
        } | null;
      } | null;
      averagePricePerNight: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      };
      ratePlan?: { __typename?: "RatePlan"; pmsId?: string | null; name?: string | null } | null;
      totalPrice: {
        __typename?: "GrossPrice";
        currency?: string | null;
        grossPriceInCents?: number | null;
      };
    }>;
    unitGroup?: {
      __typename?: "UnitGroup";
      pmsId?: string | null;
      capacity?: number | null;
    } | null;
  }>;
};

export type GetBookingOverviewsQueryVariables = Exact<{
  active: Scalars["Boolean"];
}>;

export type GetBookingOverviewsQuery = {
  __typename?: "Query";
  GetBookingOverviews: Array<{
    __typename: "BookingOverview";
    actor: Actor;
    pmsBookingId: string;
    userAccountUuid?: string | null;
    magicId: string;
    magicToken: string;
    flowState: {
      __typename?: "FlowState";
      wasOrIsCompleted: boolean;
      completed: boolean;
      context: any;
      notificationPending: boolean;
      notificationSent: boolean;
      sentNotifications: Array<string>;
      performedActions: Array<string>;
      fastCheckinAvailable: boolean;
    };
    items: Array<{
      __typename?: "BookingOverviewItem";
      id: string;
      active: boolean;
      arrival: any;
      pmsBookingId: string;
      bookingManagementCompleted: boolean;
      departure: any;
      pmsPropertyId: string;
      travelBuddies: Array<{
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }>;
      reservations: Array<{
        __typename: "Reservation";
        actor: Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: RateSpliceTypeEnumContract | null;
                prepaymentType?: PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }>;
    }>;
  }>;
};

export type GetPotentialBookingPriceQueryVariables = Exact<{
  booking: PotentialBooking;
}>;

export type GetPotentialBookingPriceQuery = {
  __typename?: "Query";
  GetPotentialBookingPrice?: {
    __typename?: "BookingPrice";
    prepayment?: GuaranteeType | null;
    prepaid?: boolean | null;
    total?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    } | null;
    accommodation?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    } | null;
  } | null;
};

export type GetLatestMagicObjectQueryVariables = Exact<{ [key: string]: never }>;

export type GetLatestMagicObjectQuery = {
  __typename?: "Query";
  GetLatestMagicObject?:
    | { __typename?: "Booking"; magicId: string }
    | { __typename?: "Reservation"; magicId: string }
    | { __typename?: "TravelBuddy"; magicId: string }
    | null;
};

export type GetMagicBookingOverviewsQueryVariables = Exact<{
  magicId: Scalars["String"];
}>;

export type GetMagicBookingOverviewsQuery = {
  __typename?: "Query";
  GetMagicBookingOverviews: Array<{
    __typename: "BookingOverview";
    actor: Actor;
    pmsBookingId: string;
    userAccountUuid?: string | null;
    magicId: string;
    magicToken: string;
    flowState: {
      __typename?: "FlowState";
      wasOrIsCompleted: boolean;
      completed: boolean;
      context: any;
      notificationPending: boolean;
      notificationSent: boolean;
      sentNotifications: Array<string>;
      performedActions: Array<string>;
      fastCheckinAvailable: boolean;
    };
    items: Array<{
      __typename?: "BookingOverviewItem";
      id: string;
      active: boolean;
      arrival: any;
      pmsBookingId: string;
      bookingManagementCompleted: boolean;
      departure: any;
      pmsPropertyId: string;
      travelBuddies: Array<{
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }>;
      reservations: Array<{
        __typename: "Reservation";
        actor: Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: RateSpliceTypeEnumContract | null;
                prepaymentType?: PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }>;
    }>;
  }>;
};

export type GetMagicObjectQueryVariables = Exact<{
  magicId: Scalars["String"];
}>;

export type GetMagicObjectQuery = {
  __typename?: "Query";
  GetMagicObject?:
    | {
        __typename: "Booking";
        actor: Actor;
        magicId: string;
        magicToken: string;
        userAccountUuid?: string | null;
        pmsId: string;
        flowState: {
          __typename?: "FlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
        };
        reservations?: Array<{
          __typename: "Reservation";
          actor: Actor;
          pmsId: string;
          bookingOverviewItemId: string;
          userAccountUuid?: string | null;
          maxCompanions: number;
          magicId: string;
          magicToken: string;
          magicLink: string;
          isExternalReservation: boolean;
          channel?: string | null;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
          createdAt?: any | null;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          estimatedArrivalTime?: any | null;
          estimatedDepartureTime?: any | null;
          cancellationTime?: any | null;
          idCheckStatus: IdCheckStatus;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          secondaryGuests?: Array<{
            __typename?: "Person";
            pmsId: string;
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
          } | null> | null;
          travelBuddies: Array<{
            __typename: "TravelBuddy";
            pmsPropertyId: string;
            pmsReservationId: string;
            magicId: string;
            magicToken: string;
            actor: Actor;
            pmsBookingId: string;
            bookingMagicId: string;
            bookingOverviewItemId: string;
            status: ReservationStatus;
            arrival: any;
            departure: any;
            checkinTime?: any | null;
            checkoutTime?: any | null;
            reservationId: number;
            reservationMagicId: string;
            unitCleanOnCheckin: boolean;
            created: any;
            accessibleDoors?: Array<{
              __typename?: "AccessibleDoor";
              id?: string | null;
              isGeneral?: boolean | null;
              pin?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
              title?: string | null;
            } | null> | null;
            unit?: {
              __typename?: "Unit";
              pmsId?: string | null;
              name?: string | null;
              state?: UnitState | null;
              floor?: string | null;
            } | null;
            unitGroup?: {
              __typename?: "UnitGroup";
              pmsId?: string | null;
              capacity?: number | null;
            } | null;
            flowState: {
              __typename?: "FlowState";
              completed: boolean;
              context: any;
              fastCheckinAvailable: boolean;
              notificationPending: boolean;
              notificationSent: boolean;
              performedActions: Array<string>;
              sentNotifications: Array<string>;
              wasOrIsCompleted: boolean;
            };
            person: {
              __typename?: "Person";
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
              birthdate?: any | null;
              termsAndConditionsMarketingConsent: boolean;
              termsAndConditionsMarketingConsentDoubleOptin: boolean;
              termsAndConditionsGeneral: boolean;
              termsAndConditionsOnline: boolean;
              preferredCommunicationChannel?: CommunicationChannel | null;
              identificationDocumentNumber?: string | null;
              pmsId: string;
              preferredLanguage?: string | null;
              emergencyEvacuationHelpNeeded: boolean;
              enrolledInLoyaltyProgram: boolean;
              address?: {
                __typename?: "Address";
                addressLine1?: string | null;
                addressLine2?: string | null;
                postalCode?: string | null;
                city?: string | null;
                countryCode?: string | null;
              } | null;
            };
          }>;
          primaryGuest?: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            enrolledInLoyaltyProgram: boolean;
            emergencyEvacuationHelpNeeded: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            nationality?: string | null;
            preferredLanguage?: string | null;
            pmsId: string;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
              region?: string | null;
            } | null;
          } | null;
          property: { __typename?: "Property"; pmsId: string };
          totalCharges?: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPriceInCents?: number | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            currency?: string | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            grossPriceInCents?: number | null;
            grossPrice?: any | null;
            currency?: string | null;
          } | null;
          totalAllowedPayment?: {
            __typename?: "GrossPrice";
            grossPriceInCents?: number | null;
            grossPrice?: any | null;
            currency?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          folios: Array<{
            __typename?: "Folio";
            pmsId?: string | null;
            type: FolioType;
            debitorType: DebitorType;
            prepaymentType: PrepaymentType;
            pmsCustomerId?: string | null;
            propertyId?: number | null;
            pmsCompanyId?: string | null;
            number?: string | null;
            closed?: any | null;
            totalCharges?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                amountInCents?: number | null;
                code?: string | null;
              } | null> | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
            groupedCharges: Array<{
              __typename?: "GroupedCharge";
              name: string;
              subName?: string | null;
              pmsServiceId?: string | null;
              totalQuantity: number;
              prepayable: boolean;
              translatedNames?: any | null;
              totalPrice: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
              };
            }>;
            orderItems: Array<{
              __typename?: "OrderItem";
              pmsId: string;
              pmsFolioId?: string | null;
              pmsReservationId?: string | null;
              start?: any | null;
              end?: any | null;
              accountingState: AccountingState;
              translatedNames?: any | null;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
            payments: Array<{
              __typename?: "Payment";
              pmsId: string;
              folioId?: number | null;
              state: PaymentState;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
          }>;
          bookedServicesOverview: Array<{
            __typename?: "BookedService";
            dates: Array<{
              __typename?: "BookedServiceDate";
              serviceDate?: any | null;
              tags?: Array<BookedServiceTag> | null;
              amount?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                } | null> | null;
              } | null;
            }>;
            service?: {
              __typename?: "BookedServiceDetails";
              magicServiceCodeEnum?: string | null;
              name: string;
              pmsServiceId: string;
              tags?: Array<BookedServiceTag> | null;
              totalAmount?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                } | null> | null;
              } | null;
            } | null;
          }>;
          flowState: {
            __typename?: "ReservationFlowState";
            wasOrIsCompleted: boolean;
            completed: boolean;
            context: any;
            notificationPending: boolean;
            notificationSent: boolean;
            sentNotifications: Array<string>;
            performedActions: Array<string>;
            fastCheckinAvailable: boolean;
            dirtyUnitDialogSeen?: boolean | null;
            cleanUnitDialogSeen?: boolean | null;
            billConfirmed?: boolean | null;
            bookingOnBehalfOf?: BookingOnBehalfOf | null;
            unitCleanOnCheckin?: boolean | null;
          };
          files: Array<{
            __typename?: "MagicFileDescriptor";
            id: string;
            fileName: string;
            contentType: string;
            magicFileType: string;
            ownerId: string;
          }>;
          cancellationFees: Array<{
            __typename?: "CancellationFee";
            pmsId?: string | null;
            name?: string | null;
            description?: string | null;
            dueDateTime?: any | null;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPriceInCents?: number | null;
            } | null;
          }>;
          currentCancellationFee?: {
            __typename?: "CancellationFee";
            description?: string | null;
            dueDateTime?: any | null;
            name?: string | null;
            pmsId?: string | null;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPriceInCents?: number | null;
            } | null;
          } | null;
          foliosToBePaid: Array<{
            __typename?: "Folio";
            pmsId?: string | null;
            type: FolioType;
            debitorType: DebitorType;
            prepaymentType: PrepaymentType;
            pmsCustomerId?: string | null;
            propertyId?: number | null;
            pmsCompanyId?: string | null;
            number?: string | null;
            closed?: any | null;
            totalCharges?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                amountInCents?: number | null;
                code?: string | null;
              } | null> | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
            groupedCharges: Array<{
              __typename?: "GroupedCharge";
              name: string;
              subName?: string | null;
              pmsServiceId?: string | null;
              totalQuantity: number;
              prepayable: boolean;
              translatedNames?: any | null;
              totalPrice: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
              };
            }>;
            orderItems: Array<{
              __typename?: "OrderItem";
              pmsId: string;
              pmsFolioId?: string | null;
              pmsReservationId?: string | null;
              start?: any | null;
              end?: any | null;
              accountingState: AccountingState;
              translatedNames?: any | null;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
            payments: Array<{
              __typename?: "Payment";
              pmsId: string;
              folioId?: number | null;
              state: PaymentState;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
          }>;
          groupedRateBreakdown?: {
            __typename?: "GroupedRateBreakdown";
            rateSpliceTypeTuples?: Array<{
              __typename?: "RateSpliceTypeEnumTuple";
              type?: RateSpliceTypeEnumContract | null;
              totalCharges?: {
                __typename?: "GrossPrice";
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                currency?: string | null;
              } | null;
              pmsIdToRateSplicesTuples?: Array<{
                __typename?: "PmsIdToRateSplicesTuple";
                pmsId?: string | null;
                rateSplices?: Array<{
                  __typename?: "RateSplice";
                  displayName?: string | null;
                  quantity?: number | null;
                  type?: RateSpliceTypeEnumContract | null;
                  prepaymentType?: PrepaymentType | null;
                  hide?: boolean | null;
                  prepaid?: boolean | null;
                  grossPrice?: {
                    __typename?: "GrossPrice";
                    grossPrice?: any | null;
                    grossPriceInCents?: number | null;
                    currency?: string | null;
                  } | null;
                }> | null;
              }> | null;
            }> | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename: "Reservation";
        actor: Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: RateSpliceTypeEnumContract | null;
                prepaymentType?: PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }
    | {
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }
    | null;
};

export type GetPaymentMethodsQueryVariables = Exact<{
  magicId?: InputMaybe<Scalars["String"]>;
  shopperReference: Scalars["String"];
}>;

export type GetPaymentMethodsQuery = {
  __typename?: "Query";
  GetPaymentMethodsMagicId: Array<
    | {
        __typename: "CardPaymentMethod";
        type: PaymentMethodType;
        paymentMethods?: Array<{
          __typename?: "PossiblePaymentMethod";
          brand?: string | null;
          brands?: Array<string | null> | null;
          configuration?: any | null;
          fundingSource?: string | null;
          group?: any | null;
          inputDetails?: string | null;
          issuers?: string | null;
          name?: string | null;
          type?: string | null;
        } | null> | null;
      }
    | { __typename: "CashPaymentMethod"; type: PaymentMethodType }
    | { __typename: "MewsPaymentMethod"; type: PaymentMethodType }
    | { __typename: "PayAtCheckinPaymentMethod"; type: PaymentMethodType }
    | { __typename: "PayAtCheckoutPaymentMethod"; type: PaymentMethodType }
    | { __typename: "PaymentLinkPaymentMethod"; type: PaymentMethodType }
    | { __typename: "PreAuthorizationPaymentMethod"; type: PaymentMethodType }
    | {
        __typename: "StoredPaymentMethod";
        type: PaymentMethodType;
        config?: {
          __typename?: "StoredPaymentConfig";
          brand?: string | null;
          expiryMonth?: string | null;
          expiryYear?: string | null;
          holderName?: string | null;
          iban?: string | null;
          id?: string | null;
          lastFour?: string | null;
          name?: string | null;
          ownerName?: string | null;
          shopperEmail?: string | null;
          supportedShopperInteractions?: Array<string | null> | null;
          type?: string | null;
        } | null;
      }
    | {
        __typename: "TerminalPaymentMethod";
        type: PaymentMethodType;
        availableTerminals?: Array<{
          __typename?: "AvailableTerminal";
          id?: string | null;
          name?: string | null;
        } | null> | null;
      }
  >;
};

export type GetAllPropertiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllPropertiesQuery = {
  __typename?: "Query";
  GetPropertiesByCity?: {
    __typename?: "PropertiesByCityAggregate";
    grouped?: Array<{
      __typename?: "PropertyGroup";
      key?: string | null;
      value?: Array<{
        __typename?: "Property";
        pmsId: string;
        timezone?: string | null;
        name?: string | null;
        defaultCurrency?: string | null;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          postalCode?: string | null;
          city?: string | null;
        } | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetShopAvailabilityQueryVariables = Exact<{
  magicId: Scalars["String"];
  shopTypesIncludeFilter?: InputMaybe<Array<InputMaybe<ShopType>> | InputMaybe<ShopType>>;
}>;

export type GetShopAvailabilityQuery = {
  __typename?: "Query";
  GetAdditionalServicesAvailability?: Array<{
    __typename?: "AdditionalServiceAvailabilityDTO";
    pmsServiceId: string;
    available: boolean;
    bookedDates?: Array<any | null> | null;
    bookableDates?: Array<any | null> | null;
    granularity: Granularity;
    payAtCheckout?: boolean | null;
    basePrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    };
  } | null> | null;
};

export type GetTotalUnpaidAmountQueryVariables = Exact<{
  magicId: Scalars["String"];
  services?: InputMaybe<Array<InputMaybe<ServiceOrderRequest>> | InputMaybe<ServiceOrderRequest>>;
}>;

export type GetTotalUnpaidAmountQuery = {
  __typename?: "Query";
  GetTotalUnpaidAmount?: {
    __typename?: "GrossPrice";
    grossPriceInCents?: number | null;
    grossPrice?: any | null;
    currency?: string | null;
  } | null;
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserProfileQuery = {
  __typename?: "Query";
  GetUserProfile?: {
    __typename?: "UserProfile";
    preferredLanguage?: string | null;
    userAccountUuid?: string | null;
    id: number;
  } | null;
};

export type SearchReservationsQueryVariables = Exact<{
  searchData: ReservationSearchFilter;
}>;

export type SearchReservationsQuery = {
  __typename?: "Query";
  SearchReservations: Array<{
    __typename?: "ReservationSearchResult";
    magicId: string;
    adultsAmount?: number | null;
    childrenAmount?: number | null;
    unit?: { __typename?: "ReservationSearchResultUnit"; name?: string | null } | null;
    unitGroup?: { __typename?: "ReservationSearchResultUnitGroup"; id?: number | null } | null;
  }>;
};

export type BookingOverviewFragmentFragment = {
  __typename: "BookingOverview";
  actor: Actor;
  pmsBookingId: string;
  userAccountUuid?: string | null;
  magicId: string;
  magicToken: string;
  flowState: {
    __typename?: "FlowState";
    wasOrIsCompleted: boolean;
    completed: boolean;
    context: any;
    notificationPending: boolean;
    notificationSent: boolean;
    sentNotifications: Array<string>;
    performedActions: Array<string>;
    fastCheckinAvailable: boolean;
  };
  items: Array<{
    __typename?: "BookingOverviewItem";
    id: string;
    active: boolean;
    arrival: any;
    pmsBookingId: string;
    bookingManagementCompleted: boolean;
    departure: any;
    pmsPropertyId: string;
    travelBuddies: Array<{
      __typename: "TravelBuddy";
      pmsPropertyId: string;
      pmsReservationId: string;
      magicId: string;
      magicToken: string;
      actor: Actor;
      pmsBookingId: string;
      bookingMagicId: string;
      bookingOverviewItemId: string;
      status: ReservationStatus;
      arrival: any;
      departure: any;
      checkinTime?: any | null;
      checkoutTime?: any | null;
      reservationId: number;
      reservationMagicId: string;
      unitCleanOnCheckin: boolean;
      created: any;
      accessibleDoors?: Array<{
        __typename?: "AccessibleDoor";
        id?: string | null;
        isGeneral?: boolean | null;
        pin?: string | null;
        privacyMode?: boolean | null;
        privacyModeLastChangeTimestamp?: any | null;
        title?: string | null;
      } | null> | null;
      unit?: {
        __typename?: "Unit";
        pmsId?: string | null;
        name?: string | null;
        state?: UnitState | null;
        floor?: string | null;
      } | null;
      unitGroup?: {
        __typename?: "UnitGroup";
        pmsId?: string | null;
        capacity?: number | null;
      } | null;
      flowState: {
        __typename?: "FlowState";
        completed: boolean;
        context: any;
        fastCheckinAvailable: boolean;
        notificationPending: boolean;
        notificationSent: boolean;
        performedActions: Array<string>;
        sentNotifications: Array<string>;
        wasOrIsCompleted: boolean;
      };
      person: {
        __typename?: "Person";
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
        gender?: Gender | null;
        birthdate?: any | null;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        preferredCommunicationChannel?: CommunicationChannel | null;
        identificationDocumentNumber?: string | null;
        pmsId: string;
        preferredLanguage?: string | null;
        emergencyEvacuationHelpNeeded: boolean;
        enrolledInLoyaltyProgram: boolean;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          postalCode?: string | null;
          city?: string | null;
          countryCode?: string | null;
        } | null;
      };
    }>;
    reservations: Array<{
      __typename: "Reservation";
      actor: Actor;
      pmsId: string;
      bookingOverviewItemId: string;
      userAccountUuid?: string | null;
      maxCompanions: number;
      magicId: string;
      magicToken: string;
      magicLink: string;
      isExternalReservation: boolean;
      channel?: string | null;
      adultsAmount?: number | null;
      childrenAmount?: number | null;
      createdAt?: any | null;
      status: ReservationStatus;
      arrival: any;
      departure: any;
      checkinTime?: any | null;
      checkoutTime?: any | null;
      estimatedArrivalTime?: any | null;
      estimatedDepartureTime?: any | null;
      cancellationTime?: any | null;
      idCheckStatus: IdCheckStatus;
      accessibleDoors?: Array<{
        __typename?: "AccessibleDoor";
        id?: string | null;
        isGeneral?: boolean | null;
        pin?: string | null;
        privacyMode?: boolean | null;
        privacyModeLastChangeTimestamp?: any | null;
        title?: string | null;
      } | null> | null;
      secondaryGuests?: Array<{
        __typename?: "Person";
        pmsId: string;
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
        gender?: Gender | null;
      } | null> | null;
      travelBuddies: Array<{
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }>;
      primaryGuest?: {
        __typename?: "Person";
        firstName?: string | null;
        lastName?: string | null;
        email?: string | null;
        phone?: string | null;
        gender?: Gender | null;
        birthdate?: any | null;
        termsAndConditionsMarketingConsent: boolean;
        termsAndConditionsMarketingConsentDoubleOptin: boolean;
        termsAndConditionsGeneral: boolean;
        termsAndConditionsOnline: boolean;
        enrolledInLoyaltyProgram: boolean;
        emergencyEvacuationHelpNeeded: boolean;
        preferredCommunicationChannel?: CommunicationChannel | null;
        identificationDocumentNumber?: string | null;
        nationality?: string | null;
        preferredLanguage?: string | null;
        pmsId: string;
        address?: {
          __typename?: "Address";
          addressLine1?: string | null;
          addressLine2?: string | null;
          postalCode?: string | null;
          city?: string | null;
          countryCode?: string | null;
          region?: string | null;
        } | null;
      } | null;
      property: { __typename?: "Property"; pmsId: string };
      totalCharges?: {
        __typename?: "Price";
        grossPriceInCents?: number | null;
        netPriceInCents?: number | null;
        grossPrice?: any | null;
        netPrice?: any | null;
        currency?: string | null;
      } | null;
      totalPayments?: {
        __typename?: "GrossPrice";
        grossPriceInCents?: number | null;
        grossPrice?: any | null;
        currency?: string | null;
      } | null;
      totalAllowedPayment?: {
        __typename?: "GrossPrice";
        grossPriceInCents?: number | null;
        grossPrice?: any | null;
        currency?: string | null;
      } | null;
      unit?: {
        __typename?: "Unit";
        pmsId?: string | null;
        name?: string | null;
        state?: UnitState | null;
        floor?: string | null;
        privacyMode?: boolean | null;
        privacyModeLastChangeTimestamp?: any | null;
      } | null;
      unitGroup?: {
        __typename?: "UnitGroup";
        pmsId?: string | null;
        capacity?: number | null;
      } | null;
      folios: Array<{
        __typename?: "Folio";
        pmsId?: string | null;
        type: FolioType;
        debitorType: DebitorType;
        prepaymentType: PrepaymentType;
        pmsCustomerId?: string | null;
        propertyId?: number | null;
        pmsCompanyId?: string | null;
        number?: string | null;
        closed?: any | null;
        totalCharges?: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
          taxes?: Array<{
            __typename?: "Tax";
            amount?: any | null;
            amountInCents?: number | null;
            code?: string | null;
          } | null> | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
        } | null;
        groupedCharges: Array<{
          __typename?: "GroupedCharge";
          name: string;
          subName?: string | null;
          pmsServiceId?: string | null;
          totalQuantity: number;
          prepayable: boolean;
          translatedNames?: any | null;
          totalPrice: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
          };
        }>;
        orderItems: Array<{
          __typename?: "OrderItem";
          pmsId: string;
          pmsFolioId?: string | null;
          pmsReservationId?: string | null;
          start?: any | null;
          end?: any | null;
          accountingState: AccountingState;
          translatedNames?: any | null;
          price: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            currency?: string | null;
          };
        }>;
        payments: Array<{
          __typename?: "Payment";
          pmsId: string;
          folioId?: number | null;
          state: PaymentState;
          price: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            currency?: string | null;
          };
        }>;
      }>;
      bookedServicesOverview: Array<{
        __typename?: "BookedService";
        dates: Array<{
          __typename?: "BookedServiceDate";
          serviceDate?: any | null;
          tags?: Array<BookedServiceTag> | null;
          amount?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        }>;
        service?: {
          __typename?: "BookedServiceDetails";
          magicServiceCodeEnum?: string | null;
          name: string;
          pmsServiceId: string;
          tags?: Array<BookedServiceTag> | null;
          totalAmount?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              code?: string | null;
            } | null> | null;
          } | null;
        } | null;
      }>;
      flowState: {
        __typename?: "ReservationFlowState";
        wasOrIsCompleted: boolean;
        completed: boolean;
        context: any;
        notificationPending: boolean;
        notificationSent: boolean;
        sentNotifications: Array<string>;
        performedActions: Array<string>;
        fastCheckinAvailable: boolean;
        dirtyUnitDialogSeen?: boolean | null;
        cleanUnitDialogSeen?: boolean | null;
        billConfirmed?: boolean | null;
        bookingOnBehalfOf?: BookingOnBehalfOf | null;
        unitCleanOnCheckin?: boolean | null;
      };
      files: Array<{
        __typename?: "MagicFileDescriptor";
        id: string;
        fileName: string;
        contentType: string;
        magicFileType: string;
        ownerId: string;
      }>;
      cancellationFees: Array<{
        __typename?: "CancellationFee";
        pmsId?: string | null;
        name?: string | null;
        description?: string | null;
        dueDateTime?: any | null;
        price?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPriceInCents?: number | null;
        } | null;
      }>;
      currentCancellationFee?: {
        __typename?: "CancellationFee";
        description?: string | null;
        dueDateTime?: any | null;
        name?: string | null;
        pmsId?: string | null;
        price?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPriceInCents?: number | null;
        } | null;
      } | null;
      foliosToBePaid: Array<{
        __typename?: "Folio";
        pmsId?: string | null;
        type: FolioType;
        debitorType: DebitorType;
        prepaymentType: PrepaymentType;
        pmsCustomerId?: string | null;
        propertyId?: number | null;
        pmsCompanyId?: string | null;
        number?: string | null;
        closed?: any | null;
        totalCharges?: {
          __typename?: "Price";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          netPrice?: any | null;
          netPriceInCents?: number | null;
          taxes?: Array<{
            __typename?: "Tax";
            amount?: any | null;
            amountInCents?: number | null;
            code?: string | null;
          } | null> | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          currency?: string | null;
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
        } | null;
        groupedCharges: Array<{
          __typename?: "GroupedCharge";
          name: string;
          subName?: string | null;
          pmsServiceId?: string | null;
          totalQuantity: number;
          prepayable: boolean;
          translatedNames?: any | null;
          totalPrice: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
          };
        }>;
        orderItems: Array<{
          __typename?: "OrderItem";
          pmsId: string;
          pmsFolioId?: string | null;
          pmsReservationId?: string | null;
          start?: any | null;
          end?: any | null;
          accountingState: AccountingState;
          translatedNames?: any | null;
          price: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            currency?: string | null;
          };
        }>;
        payments: Array<{
          __typename?: "Payment";
          pmsId: string;
          folioId?: number | null;
          state: PaymentState;
          price: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            currency?: string | null;
          };
        }>;
      }>;
      groupedRateBreakdown?: {
        __typename?: "GroupedRateBreakdown";
        rateSpliceTypeTuples?: Array<{
          __typename?: "RateSpliceTypeEnumTuple";
          type?: RateSpliceTypeEnumContract | null;
          totalCharges?: {
            __typename?: "GrossPrice";
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            currency?: string | null;
          } | null;
          pmsIdToRateSplicesTuples?: Array<{
            __typename?: "PmsIdToRateSplicesTuple";
            pmsId?: string | null;
            rateSplices?: Array<{
              __typename?: "RateSplice";
              displayName?: string | null;
              quantity?: number | null;
              type?: RateSpliceTypeEnumContract | null;
              prepaymentType?: PrepaymentType | null;
              hide?: boolean | null;
              prepaid?: boolean | null;
              grossPrice?: {
                __typename?: "GrossPrice";
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                currency?: string | null;
              } | null;
            }> | null;
          }> | null;
        }> | null;
      } | null;
    }>;
  }>;
};

export type FolioFragmentFragment = {
  __typename?: "Folio";
  pmsId?: string | null;
  type: FolioType;
  debitorType: DebitorType;
  prepaymentType: PrepaymentType;
  pmsCustomerId?: string | null;
  propertyId?: number | null;
  pmsCompanyId?: string | null;
  number?: string | null;
  closed?: any | null;
  totalCharges?: {
    __typename?: "Price";
    currency?: string | null;
    grossPrice?: any | null;
    grossPriceInCents?: number | null;
    netPrice?: any | null;
    netPriceInCents?: number | null;
    taxes?: Array<{
      __typename?: "Tax";
      amount?: any | null;
      amountInCents?: number | null;
      code?: string | null;
    } | null> | null;
  } | null;
  totalPayments?: {
    __typename?: "GrossPrice";
    currency?: string | null;
    grossPrice?: any | null;
    grossPriceInCents?: number | null;
  } | null;
  groupedCharges: Array<{
    __typename?: "GroupedCharge";
    name: string;
    subName?: string | null;
    pmsServiceId?: string | null;
    totalQuantity: number;
    prepayable: boolean;
    translatedNames?: any | null;
    totalPrice: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
    };
  }>;
  orderItems: Array<{
    __typename?: "OrderItem";
    pmsId: string;
    pmsFolioId?: string | null;
    pmsReservationId?: string | null;
    start?: any | null;
    end?: any | null;
    accountingState: AccountingState;
    translatedNames?: any | null;
    price: {
      __typename?: "Price";
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      currency?: string | null;
    };
  }>;
  payments: Array<{
    __typename?: "Payment";
    pmsId: string;
    folioId?: number | null;
    state: PaymentState;
    price: {
      __typename?: "Price";
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      currency?: string | null;
    };
  }>;
};

export type PersonFragmentFragment = {
  __typename?: "Person";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  gender?: Gender | null;
  birthdate?: any | null;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  enrolledInLoyaltyProgram: boolean;
  emergencyEvacuationHelpNeeded: boolean;
  preferredCommunicationChannel?: CommunicationChannel | null;
  identificationDocumentNumber?: string | null;
  nationality?: string | null;
  preferredLanguage?: string | null;
  pmsId: string;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryCode?: string | null;
    region?: string | null;
  } | null;
};

export type PriceFragmentFragment = {
  __typename?: "Price";
  grossPriceInCents?: number | null;
  netPrice?: any | null;
  currency?: string | null;
};

export type GroupedRateBreakdownFragmentFragment = {
  __typename?: "GroupedRateBreakdown";
  rateSpliceTypeTuples?: Array<{
    __typename?: "RateSpliceTypeEnumTuple";
    type?: RateSpliceTypeEnumContract | null;
    totalCharges?: {
      __typename?: "GrossPrice";
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      currency?: string | null;
    } | null;
    pmsIdToRateSplicesTuples?: Array<{
      __typename?: "PmsIdToRateSplicesTuple";
      pmsId?: string | null;
      rateSplices?: Array<{
        __typename?: "RateSplice";
        displayName?: string | null;
        quantity?: number | null;
        type?: RateSpliceTypeEnumContract | null;
        prepaymentType?: PrepaymentType | null;
        hide?: boolean | null;
        prepaid?: boolean | null;
        grossPrice?: {
          __typename?: "GrossPrice";
          grossPrice?: any | null;
          grossPriceInCents?: number | null;
          currency?: string | null;
        } | null;
      }> | null;
    }> | null;
  }> | null;
};

export type ReservationEntityFragment = {
  __typename: "Reservation";
  actor: Actor;
  pmsId: string;
  bookingOverviewItemId: string;
  userAccountUuid?: string | null;
  maxCompanions: number;
  magicId: string;
  magicToken: string;
  magicLink: string;
  isExternalReservation: boolean;
  channel?: string | null;
  adultsAmount?: number | null;
  childrenAmount?: number | null;
  createdAt?: any | null;
  status: ReservationStatus;
  arrival: any;
  departure: any;
  checkinTime?: any | null;
  checkoutTime?: any | null;
  estimatedArrivalTime?: any | null;
  estimatedDepartureTime?: any | null;
  cancellationTime?: any | null;
  idCheckStatus: IdCheckStatus;
  accessibleDoors?: Array<{
    __typename?: "AccessibleDoor";
    id?: string | null;
    isGeneral?: boolean | null;
    pin?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    title?: string | null;
  } | null> | null;
  secondaryGuests?: Array<{
    __typename?: "Person";
    pmsId: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: Gender | null;
  } | null> | null;
  travelBuddies: Array<{
    __typename: "TravelBuddy";
    pmsPropertyId: string;
    pmsReservationId: string;
    magicId: string;
    magicToken: string;
    actor: Actor;
    pmsBookingId: string;
    bookingMagicId: string;
    bookingOverviewItemId: string;
    status: ReservationStatus;
    arrival: any;
    departure: any;
    checkinTime?: any | null;
    checkoutTime?: any | null;
    reservationId: number;
    reservationMagicId: string;
    unitCleanOnCheckin: boolean;
    created: any;
    accessibleDoors?: Array<{
      __typename?: "AccessibleDoor";
      id?: string | null;
      isGeneral?: boolean | null;
      pin?: string | null;
      privacyMode?: boolean | null;
      privacyModeLastChangeTimestamp?: any | null;
      title?: string | null;
    } | null> | null;
    unit?: {
      __typename?: "Unit";
      pmsId?: string | null;
      name?: string | null;
      state?: UnitState | null;
      floor?: string | null;
    } | null;
    unitGroup?: {
      __typename?: "UnitGroup";
      pmsId?: string | null;
      capacity?: number | null;
    } | null;
    flowState: {
      __typename?: "FlowState";
      completed: boolean;
      context: any;
      fastCheckinAvailable: boolean;
      notificationPending: boolean;
      notificationSent: boolean;
      performedActions: Array<string>;
      sentNotifications: Array<string>;
      wasOrIsCompleted: boolean;
    };
    person: {
      __typename?: "Person";
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
      phone?: string | null;
      gender?: Gender | null;
      birthdate?: any | null;
      termsAndConditionsMarketingConsent: boolean;
      termsAndConditionsMarketingConsentDoubleOptin: boolean;
      termsAndConditionsGeneral: boolean;
      termsAndConditionsOnline: boolean;
      preferredCommunicationChannel?: CommunicationChannel | null;
      identificationDocumentNumber?: string | null;
      pmsId: string;
      preferredLanguage?: string | null;
      emergencyEvacuationHelpNeeded: boolean;
      enrolledInLoyaltyProgram: boolean;
      address?: {
        __typename?: "Address";
        addressLine1?: string | null;
        addressLine2?: string | null;
        postalCode?: string | null;
        city?: string | null;
        countryCode?: string | null;
      } | null;
    };
  }>;
  primaryGuest?: {
    __typename?: "Person";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: Gender | null;
    birthdate?: any | null;
    termsAndConditionsMarketingConsent: boolean;
    termsAndConditionsMarketingConsentDoubleOptin: boolean;
    termsAndConditionsGeneral: boolean;
    termsAndConditionsOnline: boolean;
    enrolledInLoyaltyProgram: boolean;
    emergencyEvacuationHelpNeeded: boolean;
    preferredCommunicationChannel?: CommunicationChannel | null;
    identificationDocumentNumber?: string | null;
    nationality?: string | null;
    preferredLanguage?: string | null;
    pmsId: string;
    address?: {
      __typename?: "Address";
      addressLine1?: string | null;
      addressLine2?: string | null;
      postalCode?: string | null;
      city?: string | null;
      countryCode?: string | null;
      region?: string | null;
    } | null;
  } | null;
  property: { __typename?: "Property"; pmsId: string };
  totalCharges?: {
    __typename?: "Price";
    grossPriceInCents?: number | null;
    netPriceInCents?: number | null;
    grossPrice?: any | null;
    netPrice?: any | null;
    currency?: string | null;
  } | null;
  totalPayments?: {
    __typename?: "GrossPrice";
    grossPriceInCents?: number | null;
    grossPrice?: any | null;
    currency?: string | null;
  } | null;
  totalAllowedPayment?: {
    __typename?: "GrossPrice";
    grossPriceInCents?: number | null;
    grossPrice?: any | null;
    currency?: string | null;
  } | null;
  unit?: {
    __typename?: "Unit";
    pmsId?: string | null;
    name?: string | null;
    state?: UnitState | null;
    floor?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
  } | null;
  unitGroup?: { __typename?: "UnitGroup"; pmsId?: string | null; capacity?: number | null } | null;
  folios: Array<{
    __typename?: "Folio";
    pmsId?: string | null;
    type: FolioType;
    debitorType: DebitorType;
    prepaymentType: PrepaymentType;
    pmsCustomerId?: string | null;
    propertyId?: number | null;
    pmsCompanyId?: string | null;
    number?: string | null;
    closed?: any | null;
    totalCharges?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    } | null;
    totalPayments?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
    } | null;
    groupedCharges: Array<{
      __typename?: "GroupedCharge";
      name: string;
      subName?: string | null;
      pmsServiceId?: string | null;
      totalQuantity: number;
      prepayable: boolean;
      translatedNames?: any | null;
      totalPrice: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
      };
    }>;
    orderItems: Array<{
      __typename?: "OrderItem";
      pmsId: string;
      pmsFolioId?: string | null;
      pmsReservationId?: string | null;
      start?: any | null;
      end?: any | null;
      accountingState: AccountingState;
      translatedNames?: any | null;
      price: {
        __typename?: "Price";
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        currency?: string | null;
      };
    }>;
    payments: Array<{
      __typename?: "Payment";
      pmsId: string;
      folioId?: number | null;
      state: PaymentState;
      price: {
        __typename?: "Price";
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        currency?: string | null;
      };
    }>;
  }>;
  bookedServicesOverview: Array<{
    __typename?: "BookedService";
    dates: Array<{
      __typename?: "BookedServiceDate";
      serviceDate?: any | null;
      tags?: Array<BookedServiceTag> | null;
      amount?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          code?: string | null;
        } | null> | null;
      } | null;
    }>;
    service?: {
      __typename?: "BookedServiceDetails";
      magicServiceCodeEnum?: string | null;
      name: string;
      pmsServiceId: string;
      tags?: Array<BookedServiceTag> | null;
      totalAmount?: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
        taxes?: Array<{
          __typename?: "Tax";
          amount?: any | null;
          code?: string | null;
        } | null> | null;
      } | null;
    } | null;
  }>;
  flowState: {
    __typename?: "ReservationFlowState";
    wasOrIsCompleted: boolean;
    completed: boolean;
    context: any;
    notificationPending: boolean;
    notificationSent: boolean;
    sentNotifications: Array<string>;
    performedActions: Array<string>;
    fastCheckinAvailable: boolean;
    dirtyUnitDialogSeen?: boolean | null;
    cleanUnitDialogSeen?: boolean | null;
    billConfirmed?: boolean | null;
    bookingOnBehalfOf?: BookingOnBehalfOf | null;
    unitCleanOnCheckin?: boolean | null;
  };
  files: Array<{
    __typename?: "MagicFileDescriptor";
    id: string;
    fileName: string;
    contentType: string;
    magicFileType: string;
    ownerId: string;
  }>;
  cancellationFees: Array<{
    __typename?: "CancellationFee";
    pmsId?: string | null;
    name?: string | null;
    description?: string | null;
    dueDateTime?: any | null;
    price?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPriceInCents?: number | null;
    } | null;
  }>;
  currentCancellationFee?: {
    __typename?: "CancellationFee";
    description?: string | null;
    dueDateTime?: any | null;
    name?: string | null;
    pmsId?: string | null;
    price?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPriceInCents?: number | null;
    } | null;
  } | null;
  foliosToBePaid: Array<{
    __typename?: "Folio";
    pmsId?: string | null;
    type: FolioType;
    debitorType: DebitorType;
    prepaymentType: PrepaymentType;
    pmsCustomerId?: string | null;
    propertyId?: number | null;
    pmsCompanyId?: string | null;
    number?: string | null;
    closed?: any | null;
    totalCharges?: {
      __typename?: "Price";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
      netPrice?: any | null;
      netPriceInCents?: number | null;
      taxes?: Array<{
        __typename?: "Tax";
        amount?: any | null;
        amountInCents?: number | null;
        code?: string | null;
      } | null> | null;
    } | null;
    totalPayments?: {
      __typename?: "GrossPrice";
      currency?: string | null;
      grossPrice?: any | null;
      grossPriceInCents?: number | null;
    } | null;
    groupedCharges: Array<{
      __typename?: "GroupedCharge";
      name: string;
      subName?: string | null;
      pmsServiceId?: string | null;
      totalQuantity: number;
      prepayable: boolean;
      translatedNames?: any | null;
      totalPrice: {
        __typename?: "Price";
        currency?: string | null;
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        netPriceInCents?: number | null;
      };
    }>;
    orderItems: Array<{
      __typename?: "OrderItem";
      pmsId: string;
      pmsFolioId?: string | null;
      pmsReservationId?: string | null;
      start?: any | null;
      end?: any | null;
      accountingState: AccountingState;
      translatedNames?: any | null;
      price: {
        __typename?: "Price";
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        currency?: string | null;
      };
    }>;
    payments: Array<{
      __typename?: "Payment";
      pmsId: string;
      folioId?: number | null;
      state: PaymentState;
      price: {
        __typename?: "Price";
        grossPriceInCents?: number | null;
        netPrice?: any | null;
        currency?: string | null;
      };
    }>;
  }>;
  groupedRateBreakdown?: {
    __typename?: "GroupedRateBreakdown";
    rateSpliceTypeTuples?: Array<{
      __typename?: "RateSpliceTypeEnumTuple";
      type?: RateSpliceTypeEnumContract | null;
      totalCharges?: {
        __typename?: "GrossPrice";
        grossPrice?: any | null;
        grossPriceInCents?: number | null;
        currency?: string | null;
      } | null;
      pmsIdToRateSplicesTuples?: Array<{
        __typename?: "PmsIdToRateSplicesTuple";
        pmsId?: string | null;
        rateSplices?: Array<{
          __typename?: "RateSplice";
          displayName?: string | null;
          quantity?: number | null;
          type?: RateSpliceTypeEnumContract | null;
          prepaymentType?: PrepaymentType | null;
          hide?: boolean | null;
          prepaid?: boolean | null;
          grossPrice?: {
            __typename?: "GrossPrice";
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            currency?: string | null;
          } | null;
        }> | null;
      }> | null;
    }> | null;
  } | null;
};

export type TravelBuddyFragmentFragment = {
  __typename: "TravelBuddy";
  pmsPropertyId: string;
  pmsReservationId: string;
  magicId: string;
  magicToken: string;
  actor: Actor;
  pmsBookingId: string;
  bookingMagicId: string;
  bookingOverviewItemId: string;
  status: ReservationStatus;
  arrival: any;
  departure: any;
  checkinTime?: any | null;
  checkoutTime?: any | null;
  reservationId: number;
  reservationMagicId: string;
  unitCleanOnCheckin: boolean;
  created: any;
  accessibleDoors?: Array<{
    __typename?: "AccessibleDoor";
    id?: string | null;
    isGeneral?: boolean | null;
    pin?: string | null;
    privacyMode?: boolean | null;
    privacyModeLastChangeTimestamp?: any | null;
    title?: string | null;
  } | null> | null;
  unit?: {
    __typename?: "Unit";
    pmsId?: string | null;
    name?: string | null;
    state?: UnitState | null;
    floor?: string | null;
  } | null;
  unitGroup?: { __typename?: "UnitGroup"; pmsId?: string | null; capacity?: number | null } | null;
  flowState: {
    __typename?: "FlowState";
    completed: boolean;
    context: any;
    fastCheckinAvailable: boolean;
    notificationPending: boolean;
    notificationSent: boolean;
    performedActions: Array<string>;
    sentNotifications: Array<string>;
    wasOrIsCompleted: boolean;
  };
  person: {
    __typename?: "Person";
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    gender?: Gender | null;
    birthdate?: any | null;
    termsAndConditionsMarketingConsent: boolean;
    termsAndConditionsMarketingConsentDoubleOptin: boolean;
    termsAndConditionsGeneral: boolean;
    termsAndConditionsOnline: boolean;
    preferredCommunicationChannel?: CommunicationChannel | null;
    identificationDocumentNumber?: string | null;
    pmsId: string;
    preferredLanguage?: string | null;
    emergencyEvacuationHelpNeeded: boolean;
    enrolledInLoyaltyProgram: boolean;
    address?: {
      __typename?: "Address";
      addressLine1?: string | null;
      addressLine2?: string | null;
      postalCode?: string | null;
      city?: string | null;
      countryCode?: string | null;
    } | null;
  };
};

export type TravelBuddyPersonFragmentFragment = {
  __typename?: "Person";
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  gender?: Gender | null;
  birthdate?: any | null;
  termsAndConditionsMarketingConsent: boolean;
  termsAndConditionsMarketingConsentDoubleOptin: boolean;
  termsAndConditionsGeneral: boolean;
  termsAndConditionsOnline: boolean;
  preferredCommunicationChannel?: CommunicationChannel | null;
  identificationDocumentNumber?: string | null;
  pmsId: string;
  preferredLanguage?: string | null;
  emergencyEvacuationHelpNeeded: boolean;
  enrolledInLoyaltyProgram: boolean;
  address?: {
    __typename?: "Address";
    addressLine1?: string | null;
    addressLine2?: string | null;
    postalCode?: string | null;
    city?: string | null;
    countryCode?: string | null;
  } | null;
};

export type StreamBookingOverviewSubscriptionVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
}>;

export type StreamBookingOverviewSubscription = {
  __typename?: "Subscription";
  StreamBookingOverview: {
    __typename: "BookingOverview";
    actor: Actor;
    pmsBookingId: string;
    userAccountUuid?: string | null;
    magicId: string;
    magicToken: string;
    flowState: {
      __typename?: "FlowState";
      wasOrIsCompleted: boolean;
      completed: boolean;
      context: any;
      notificationPending: boolean;
      notificationSent: boolean;
      sentNotifications: Array<string>;
      performedActions: Array<string>;
      fastCheckinAvailable: boolean;
    };
    items: Array<{
      __typename?: "BookingOverviewItem";
      id: string;
      active: boolean;
      arrival: any;
      pmsBookingId: string;
      bookingManagementCompleted: boolean;
      departure: any;
      pmsPropertyId: string;
      travelBuddies: Array<{
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }>;
      reservations: Array<{
        __typename: "Reservation";
        actor: Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: RateSpliceTypeEnumContract | null;
                prepaymentType?: PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }>;
    }>;
  };
};

export type StreamMagicObjectSubscriptionVariables = Exact<{
  magicId: Scalars["String"];
  magicToken: Scalars["String"];
}>;

export type StreamMagicObjectSubscription = {
  __typename?: "Subscription";
  StreamMagicObject?:
    | {
        __typename: "Booking";
        actor: Actor;
        magicId: string;
        magicToken: string;
        reservations?: Array<{
          __typename: "Reservation";
          actor: Actor;
          pmsId: string;
          bookingOverviewItemId: string;
          userAccountUuid?: string | null;
          maxCompanions: number;
          magicId: string;
          magicToken: string;
          magicLink: string;
          isExternalReservation: boolean;
          channel?: string | null;
          adultsAmount?: number | null;
          childrenAmount?: number | null;
          createdAt?: any | null;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          estimatedArrivalTime?: any | null;
          estimatedDepartureTime?: any | null;
          cancellationTime?: any | null;
          idCheckStatus: IdCheckStatus;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          secondaryGuests?: Array<{
            __typename?: "Person";
            pmsId: string;
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
          } | null> | null;
          travelBuddies: Array<{
            __typename: "TravelBuddy";
            pmsPropertyId: string;
            pmsReservationId: string;
            magicId: string;
            magicToken: string;
            actor: Actor;
            pmsBookingId: string;
            bookingMagicId: string;
            bookingOverviewItemId: string;
            status: ReservationStatus;
            arrival: any;
            departure: any;
            checkinTime?: any | null;
            checkoutTime?: any | null;
            reservationId: number;
            reservationMagicId: string;
            unitCleanOnCheckin: boolean;
            created: any;
            accessibleDoors?: Array<{
              __typename?: "AccessibleDoor";
              id?: string | null;
              isGeneral?: boolean | null;
              pin?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
              title?: string | null;
            } | null> | null;
            unit?: {
              __typename?: "Unit";
              pmsId?: string | null;
              name?: string | null;
              state?: UnitState | null;
              floor?: string | null;
            } | null;
            unitGroup?: {
              __typename?: "UnitGroup";
              pmsId?: string | null;
              capacity?: number | null;
            } | null;
            flowState: {
              __typename?: "FlowState";
              completed: boolean;
              context: any;
              fastCheckinAvailable: boolean;
              notificationPending: boolean;
              notificationSent: boolean;
              performedActions: Array<string>;
              sentNotifications: Array<string>;
              wasOrIsCompleted: boolean;
            };
            person: {
              __typename?: "Person";
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
              birthdate?: any | null;
              termsAndConditionsMarketingConsent: boolean;
              termsAndConditionsMarketingConsentDoubleOptin: boolean;
              termsAndConditionsGeneral: boolean;
              termsAndConditionsOnline: boolean;
              preferredCommunicationChannel?: CommunicationChannel | null;
              identificationDocumentNumber?: string | null;
              pmsId: string;
              preferredLanguage?: string | null;
              emergencyEvacuationHelpNeeded: boolean;
              enrolledInLoyaltyProgram: boolean;
              address?: {
                __typename?: "Address";
                addressLine1?: string | null;
                addressLine2?: string | null;
                postalCode?: string | null;
                city?: string | null;
                countryCode?: string | null;
              } | null;
            };
          }>;
          primaryGuest?: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            enrolledInLoyaltyProgram: boolean;
            emergencyEvacuationHelpNeeded: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            nationality?: string | null;
            preferredLanguage?: string | null;
            pmsId: string;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
              region?: string | null;
            } | null;
          } | null;
          property: { __typename?: "Property"; pmsId: string };
          totalCharges?: {
            __typename?: "Price";
            grossPriceInCents?: number | null;
            netPriceInCents?: number | null;
            grossPrice?: any | null;
            netPrice?: any | null;
            currency?: string | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            grossPriceInCents?: number | null;
            grossPrice?: any | null;
            currency?: string | null;
          } | null;
          totalAllowedPayment?: {
            __typename?: "GrossPrice";
            grossPriceInCents?: number | null;
            grossPrice?: any | null;
            currency?: string | null;
          } | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          folios: Array<{
            __typename?: "Folio";
            pmsId?: string | null;
            type: FolioType;
            debitorType: DebitorType;
            prepaymentType: PrepaymentType;
            pmsCustomerId?: string | null;
            propertyId?: number | null;
            pmsCompanyId?: string | null;
            number?: string | null;
            closed?: any | null;
            totalCharges?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                amountInCents?: number | null;
                code?: string | null;
              } | null> | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
            groupedCharges: Array<{
              __typename?: "GroupedCharge";
              name: string;
              subName?: string | null;
              pmsServiceId?: string | null;
              totalQuantity: number;
              prepayable: boolean;
              translatedNames?: any | null;
              totalPrice: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
              };
            }>;
            orderItems: Array<{
              __typename?: "OrderItem";
              pmsId: string;
              pmsFolioId?: string | null;
              pmsReservationId?: string | null;
              start?: any | null;
              end?: any | null;
              accountingState: AccountingState;
              translatedNames?: any | null;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
            payments: Array<{
              __typename?: "Payment";
              pmsId: string;
              folioId?: number | null;
              state: PaymentState;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
          }>;
          bookedServicesOverview: Array<{
            __typename?: "BookedService";
            dates: Array<{
              __typename?: "BookedServiceDate";
              serviceDate?: any | null;
              tags?: Array<BookedServiceTag> | null;
              amount?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                } | null> | null;
              } | null;
            }>;
            service?: {
              __typename?: "BookedServiceDetails";
              magicServiceCodeEnum?: string | null;
              name: string;
              pmsServiceId: string;
              tags?: Array<BookedServiceTag> | null;
              totalAmount?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  code?: string | null;
                } | null> | null;
              } | null;
            } | null;
          }>;
          flowState: {
            __typename?: "ReservationFlowState";
            wasOrIsCompleted: boolean;
            completed: boolean;
            context: any;
            notificationPending: boolean;
            notificationSent: boolean;
            sentNotifications: Array<string>;
            performedActions: Array<string>;
            fastCheckinAvailable: boolean;
            dirtyUnitDialogSeen?: boolean | null;
            cleanUnitDialogSeen?: boolean | null;
            billConfirmed?: boolean | null;
            bookingOnBehalfOf?: BookingOnBehalfOf | null;
            unitCleanOnCheckin?: boolean | null;
          };
          files: Array<{
            __typename?: "MagicFileDescriptor";
            id: string;
            fileName: string;
            contentType: string;
            magicFileType: string;
            ownerId: string;
          }>;
          cancellationFees: Array<{
            __typename?: "CancellationFee";
            pmsId?: string | null;
            name?: string | null;
            description?: string | null;
            dueDateTime?: any | null;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPriceInCents?: number | null;
            } | null;
          }>;
          currentCancellationFee?: {
            __typename?: "CancellationFee";
            description?: string | null;
            dueDateTime?: any | null;
            name?: string | null;
            pmsId?: string | null;
            price?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPriceInCents?: number | null;
            } | null;
          } | null;
          foliosToBePaid: Array<{
            __typename?: "Folio";
            pmsId?: string | null;
            type: FolioType;
            debitorType: DebitorType;
            prepaymentType: PrepaymentType;
            pmsCustomerId?: string | null;
            propertyId?: number | null;
            pmsCompanyId?: string | null;
            number?: string | null;
            closed?: any | null;
            totalCharges?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                amountInCents?: number | null;
                code?: string | null;
              } | null> | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
            } | null;
            groupedCharges: Array<{
              __typename?: "GroupedCharge";
              name: string;
              subName?: string | null;
              pmsServiceId?: string | null;
              totalQuantity: number;
              prepayable: boolean;
              translatedNames?: any | null;
              totalPrice: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
              };
            }>;
            orderItems: Array<{
              __typename?: "OrderItem";
              pmsId: string;
              pmsFolioId?: string | null;
              pmsReservationId?: string | null;
              start?: any | null;
              end?: any | null;
              accountingState: AccountingState;
              translatedNames?: any | null;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
            payments: Array<{
              __typename?: "Payment";
              pmsId: string;
              folioId?: number | null;
              state: PaymentState;
              price: {
                __typename?: "Price";
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                currency?: string | null;
              };
            }>;
          }>;
          groupedRateBreakdown?: {
            __typename?: "GroupedRateBreakdown";
            rateSpliceTypeTuples?: Array<{
              __typename?: "RateSpliceTypeEnumTuple";
              type?: RateSpliceTypeEnumContract | null;
              totalCharges?: {
                __typename?: "GrossPrice";
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                currency?: string | null;
              } | null;
              pmsIdToRateSplicesTuples?: Array<{
                __typename?: "PmsIdToRateSplicesTuple";
                pmsId?: string | null;
                rateSplices?: Array<{
                  __typename?: "RateSplice";
                  displayName?: string | null;
                  quantity?: number | null;
                  type?: RateSpliceTypeEnumContract | null;
                  prepaymentType?: PrepaymentType | null;
                  hide?: boolean | null;
                  prepaid?: boolean | null;
                  grossPrice?: {
                    __typename?: "GrossPrice";
                    grossPrice?: any | null;
                    grossPriceInCents?: number | null;
                    currency?: string | null;
                  } | null;
                }> | null;
              }> | null;
            }> | null;
          } | null;
        } | null> | null;
      }
    | {
        __typename: "Reservation";
        actor: Actor;
        pmsId: string;
        bookingOverviewItemId: string;
        userAccountUuid?: string | null;
        maxCompanions: number;
        magicId: string;
        magicToken: string;
        magicLink: string;
        isExternalReservation: boolean;
        channel?: string | null;
        adultsAmount?: number | null;
        childrenAmount?: number | null;
        createdAt?: any | null;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        estimatedArrivalTime?: any | null;
        estimatedDepartureTime?: any | null;
        cancellationTime?: any | null;
        idCheckStatus: IdCheckStatus;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        secondaryGuests?: Array<{
          __typename?: "Person";
          pmsId: string;
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
        } | null> | null;
        travelBuddies: Array<{
          __typename: "TravelBuddy";
          pmsPropertyId: string;
          pmsReservationId: string;
          magicId: string;
          magicToken: string;
          actor: Actor;
          pmsBookingId: string;
          bookingMagicId: string;
          bookingOverviewItemId: string;
          status: ReservationStatus;
          arrival: any;
          departure: any;
          checkinTime?: any | null;
          checkoutTime?: any | null;
          reservationId: number;
          reservationMagicId: string;
          unitCleanOnCheckin: boolean;
          created: any;
          accessibleDoors?: Array<{
            __typename?: "AccessibleDoor";
            id?: string | null;
            isGeneral?: boolean | null;
            pin?: string | null;
            privacyMode?: boolean | null;
            privacyModeLastChangeTimestamp?: any | null;
            title?: string | null;
          } | null> | null;
          unit?: {
            __typename?: "Unit";
            pmsId?: string | null;
            name?: string | null;
            state?: UnitState | null;
            floor?: string | null;
          } | null;
          unitGroup?: {
            __typename?: "UnitGroup";
            pmsId?: string | null;
            capacity?: number | null;
          } | null;
          flowState: {
            __typename?: "FlowState";
            completed: boolean;
            context: any;
            fastCheckinAvailable: boolean;
            notificationPending: boolean;
            notificationSent: boolean;
            performedActions: Array<string>;
            sentNotifications: Array<string>;
            wasOrIsCompleted: boolean;
          };
          person: {
            __typename?: "Person";
            firstName?: string | null;
            lastName?: string | null;
            email?: string | null;
            phone?: string | null;
            gender?: Gender | null;
            birthdate?: any | null;
            termsAndConditionsMarketingConsent: boolean;
            termsAndConditionsMarketingConsentDoubleOptin: boolean;
            termsAndConditionsGeneral: boolean;
            termsAndConditionsOnline: boolean;
            preferredCommunicationChannel?: CommunicationChannel | null;
            identificationDocumentNumber?: string | null;
            pmsId: string;
            preferredLanguage?: string | null;
            emergencyEvacuationHelpNeeded: boolean;
            enrolledInLoyaltyProgram: boolean;
            address?: {
              __typename?: "Address";
              addressLine1?: string | null;
              addressLine2?: string | null;
              postalCode?: string | null;
              city?: string | null;
              countryCode?: string | null;
            } | null;
          };
        }>;
        primaryGuest?: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          enrolledInLoyaltyProgram: boolean;
          emergencyEvacuationHelpNeeded: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          nationality?: string | null;
          preferredLanguage?: string | null;
          pmsId: string;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
            region?: string | null;
          } | null;
        } | null;
        property: { __typename?: "Property"; pmsId: string };
        totalCharges?: {
          __typename?: "Price";
          grossPriceInCents?: number | null;
          netPriceInCents?: number | null;
          grossPrice?: any | null;
          netPrice?: any | null;
          currency?: string | null;
        } | null;
        totalPayments?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        totalAllowedPayment?: {
          __typename?: "GrossPrice";
          grossPriceInCents?: number | null;
          grossPrice?: any | null;
          currency?: string | null;
        } | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        folios: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        bookedServicesOverview: Array<{
          __typename?: "BookedService";
          dates: Array<{
            __typename?: "BookedServiceDate";
            serviceDate?: any | null;
            tags?: Array<BookedServiceTag> | null;
            amount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          }>;
          service?: {
            __typename?: "BookedServiceDetails";
            magicServiceCodeEnum?: string | null;
            name: string;
            pmsServiceId: string;
            tags?: Array<BookedServiceTag> | null;
            totalAmount?: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
              taxes?: Array<{
                __typename?: "Tax";
                amount?: any | null;
                code?: string | null;
              } | null> | null;
            } | null;
          } | null;
        }>;
        flowState: {
          __typename?: "ReservationFlowState";
          wasOrIsCompleted: boolean;
          completed: boolean;
          context: any;
          notificationPending: boolean;
          notificationSent: boolean;
          sentNotifications: Array<string>;
          performedActions: Array<string>;
          fastCheckinAvailable: boolean;
          dirtyUnitDialogSeen?: boolean | null;
          cleanUnitDialogSeen?: boolean | null;
          billConfirmed?: boolean | null;
          bookingOnBehalfOf?: BookingOnBehalfOf | null;
          unitCleanOnCheckin?: boolean | null;
        };
        files: Array<{
          __typename?: "MagicFileDescriptor";
          id: string;
          fileName: string;
          contentType: string;
          magicFileType: string;
          ownerId: string;
        }>;
        cancellationFees: Array<{
          __typename?: "CancellationFee";
          pmsId?: string | null;
          name?: string | null;
          description?: string | null;
          dueDateTime?: any | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        }>;
        currentCancellationFee?: {
          __typename?: "CancellationFee";
          description?: string | null;
          dueDateTime?: any | null;
          name?: string | null;
          pmsId?: string | null;
          price?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPriceInCents?: number | null;
          } | null;
        } | null;
        foliosToBePaid: Array<{
          __typename?: "Folio";
          pmsId?: string | null;
          type: FolioType;
          debitorType: DebitorType;
          prepaymentType: PrepaymentType;
          pmsCustomerId?: string | null;
          propertyId?: number | null;
          pmsCompanyId?: string | null;
          number?: string | null;
          closed?: any | null;
          totalCharges?: {
            __typename?: "Price";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
            netPrice?: any | null;
            netPriceInCents?: number | null;
            taxes?: Array<{
              __typename?: "Tax";
              amount?: any | null;
              amountInCents?: number | null;
              code?: string | null;
            } | null> | null;
          } | null;
          totalPayments?: {
            __typename?: "GrossPrice";
            currency?: string | null;
            grossPrice?: any | null;
            grossPriceInCents?: number | null;
          } | null;
          groupedCharges: Array<{
            __typename?: "GroupedCharge";
            name: string;
            subName?: string | null;
            pmsServiceId?: string | null;
            totalQuantity: number;
            prepayable: boolean;
            translatedNames?: any | null;
            totalPrice: {
              __typename?: "Price";
              currency?: string | null;
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              netPriceInCents?: number | null;
            };
          }>;
          orderItems: Array<{
            __typename?: "OrderItem";
            pmsId: string;
            pmsFolioId?: string | null;
            pmsReservationId?: string | null;
            start?: any | null;
            end?: any | null;
            accountingState: AccountingState;
            translatedNames?: any | null;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
          payments: Array<{
            __typename?: "Payment";
            pmsId: string;
            folioId?: number | null;
            state: PaymentState;
            price: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPrice?: any | null;
              currency?: string | null;
            };
          }>;
        }>;
        groupedRateBreakdown?: {
          __typename?: "GroupedRateBreakdown";
          rateSpliceTypeTuples?: Array<{
            __typename?: "RateSpliceTypeEnumTuple";
            type?: RateSpliceTypeEnumContract | null;
            totalCharges?: {
              __typename?: "GrossPrice";
              grossPrice?: any | null;
              grossPriceInCents?: number | null;
              currency?: string | null;
            } | null;
            pmsIdToRateSplicesTuples?: Array<{
              __typename?: "PmsIdToRateSplicesTuple";
              pmsId?: string | null;
              rateSplices?: Array<{
                __typename?: "RateSplice";
                displayName?: string | null;
                quantity?: number | null;
                type?: RateSpliceTypeEnumContract | null;
                prepaymentType?: PrepaymentType | null;
                hide?: boolean | null;
                prepaid?: boolean | null;
                grossPrice?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
              }> | null;
            }> | null;
          }> | null;
        } | null;
      }
    | {
        __typename: "TravelBuddy";
        pmsPropertyId: string;
        pmsReservationId: string;
        magicId: string;
        magicToken: string;
        actor: Actor;
        pmsBookingId: string;
        bookingMagicId: string;
        bookingOverviewItemId: string;
        status: ReservationStatus;
        arrival: any;
        departure: any;
        checkinTime?: any | null;
        checkoutTime?: any | null;
        reservationId: number;
        reservationMagicId: string;
        unitCleanOnCheckin: boolean;
        created: any;
        accessibleDoors?: Array<{
          __typename?: "AccessibleDoor";
          id?: string | null;
          isGeneral?: boolean | null;
          pin?: string | null;
          privacyMode?: boolean | null;
          privacyModeLastChangeTimestamp?: any | null;
          title?: string | null;
        } | null> | null;
        unit?: {
          __typename?: "Unit";
          pmsId?: string | null;
          name?: string | null;
          state?: UnitState | null;
          floor?: string | null;
        } | null;
        unitGroup?: {
          __typename?: "UnitGroup";
          pmsId?: string | null;
          capacity?: number | null;
        } | null;
        flowState: {
          __typename?: "FlowState";
          completed: boolean;
          context: any;
          fastCheckinAvailable: boolean;
          notificationPending: boolean;
          notificationSent: boolean;
          performedActions: Array<string>;
          sentNotifications: Array<string>;
          wasOrIsCompleted: boolean;
        };
        person: {
          __typename?: "Person";
          firstName?: string | null;
          lastName?: string | null;
          email?: string | null;
          phone?: string | null;
          gender?: Gender | null;
          birthdate?: any | null;
          termsAndConditionsMarketingConsent: boolean;
          termsAndConditionsMarketingConsentDoubleOptin: boolean;
          termsAndConditionsGeneral: boolean;
          termsAndConditionsOnline: boolean;
          preferredCommunicationChannel?: CommunicationChannel | null;
          identificationDocumentNumber?: string | null;
          pmsId: string;
          preferredLanguage?: string | null;
          emergencyEvacuationHelpNeeded: boolean;
          enrolledInLoyaltyProgram: boolean;
          address?: {
            __typename?: "Address";
            addressLine1?: string | null;
            addressLine2?: string | null;
            postalCode?: string | null;
            city?: string | null;
            countryCode?: string | null;
          } | null;
        };
      }
    | null;
};

export type StreamSecondScreenDeviceEventsSubscriptionVariables = Exact<{
  deviceId: Scalars["String"];
}>;

export type StreamSecondScreenDeviceEventsSubscription = {
  __typename?: "Subscription";
  StreamSecondScreenDeviceEvents?:
    | {
        __typename: "BillingConfirmed";
        deviceId: string;
        tenantName: string;
        type: SecondScreenEventType;
      }
    | { __typename: "Canceled"; deviceId: string; tenantName: string; type: SecondScreenEventType }
    | { __typename: "Signed"; deviceId: string; tenantName: string; type: SecondScreenEventType }
    | {
        __typename: "VerifyAndSign";
        deviceId: string;
        tenantName: string;
        type: SecondScreenEventType;
        payload: {
          __typename: "VerifyAndSignPayload";
          reservation: {
            __typename: "Reservation";
            actor: Actor;
            pmsId: string;
            bookingOverviewItemId: string;
            userAccountUuid?: string | null;
            maxCompanions: number;
            magicId: string;
            magicToken: string;
            magicLink: string;
            isExternalReservation: boolean;
            channel?: string | null;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            createdAt?: any | null;
            status: ReservationStatus;
            arrival: any;
            departure: any;
            checkinTime?: any | null;
            checkoutTime?: any | null;
            estimatedArrivalTime?: any | null;
            estimatedDepartureTime?: any | null;
            cancellationTime?: any | null;
            idCheckStatus: IdCheckStatus;
            accessibleDoors?: Array<{
              __typename?: "AccessibleDoor";
              id?: string | null;
              isGeneral?: boolean | null;
              pin?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
              title?: string | null;
            } | null> | null;
            secondaryGuests?: Array<{
              __typename?: "Person";
              pmsId: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
            } | null> | null;
            travelBuddies: Array<{
              __typename: "TravelBuddy";
              pmsPropertyId: string;
              pmsReservationId: string;
              magicId: string;
              magicToken: string;
              actor: Actor;
              pmsBookingId: string;
              bookingMagicId: string;
              bookingOverviewItemId: string;
              status: ReservationStatus;
              arrival: any;
              departure: any;
              checkinTime?: any | null;
              checkoutTime?: any | null;
              reservationId: number;
              reservationMagicId: string;
              unitCleanOnCheckin: boolean;
              created: any;
              accessibleDoors?: Array<{
                __typename?: "AccessibleDoor";
                id?: string | null;
                isGeneral?: boolean | null;
                pin?: string | null;
                privacyMode?: boolean | null;
                privacyModeLastChangeTimestamp?: any | null;
                title?: string | null;
              } | null> | null;
              unit?: {
                __typename?: "Unit";
                pmsId?: string | null;
                name?: string | null;
                state?: UnitState | null;
                floor?: string | null;
              } | null;
              unitGroup?: {
                __typename?: "UnitGroup";
                pmsId?: string | null;
                capacity?: number | null;
              } | null;
              flowState: {
                __typename?: "FlowState";
                completed: boolean;
                context: any;
                fastCheckinAvailable: boolean;
                notificationPending: boolean;
                notificationSent: boolean;
                performedActions: Array<string>;
                sentNotifications: Array<string>;
                wasOrIsCompleted: boolean;
              };
              person: {
                __typename?: "Person";
                firstName?: string | null;
                lastName?: string | null;
                email?: string | null;
                phone?: string | null;
                gender?: Gender | null;
                birthdate?: any | null;
                termsAndConditionsMarketingConsent: boolean;
                termsAndConditionsMarketingConsentDoubleOptin: boolean;
                termsAndConditionsGeneral: boolean;
                termsAndConditionsOnline: boolean;
                preferredCommunicationChannel?: CommunicationChannel | null;
                identificationDocumentNumber?: string | null;
                pmsId: string;
                preferredLanguage?: string | null;
                emergencyEvacuationHelpNeeded: boolean;
                enrolledInLoyaltyProgram: boolean;
                address?: {
                  __typename?: "Address";
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  countryCode?: string | null;
                } | null;
              };
            }>;
            primaryGuest?: {
              __typename?: "Person";
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
              birthdate?: any | null;
              termsAndConditionsMarketingConsent: boolean;
              termsAndConditionsMarketingConsentDoubleOptin: boolean;
              termsAndConditionsGeneral: boolean;
              termsAndConditionsOnline: boolean;
              enrolledInLoyaltyProgram: boolean;
              emergencyEvacuationHelpNeeded: boolean;
              preferredCommunicationChannel?: CommunicationChannel | null;
              identificationDocumentNumber?: string | null;
              nationality?: string | null;
              preferredLanguage?: string | null;
              pmsId: string;
              address?: {
                __typename?: "Address";
                addressLine1?: string | null;
                addressLine2?: string | null;
                postalCode?: string | null;
                city?: string | null;
                countryCode?: string | null;
                region?: string | null;
              } | null;
            } | null;
            property: { __typename?: "Property"; pmsId: string };
            totalCharges?: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPriceInCents?: number | null;
              grossPrice?: any | null;
              netPrice?: any | null;
              currency?: string | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              grossPriceInCents?: number | null;
              grossPrice?: any | null;
              currency?: string | null;
            } | null;
            totalAllowedPayment?: {
              __typename?: "GrossPrice";
              grossPriceInCents?: number | null;
              grossPrice?: any | null;
              currency?: string | null;
            } | null;
            unit?: {
              __typename?: "Unit";
              pmsId?: string | null;
              name?: string | null;
              state?: UnitState | null;
              floor?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
            } | null;
            unitGroup?: {
              __typename?: "UnitGroup";
              pmsId?: string | null;
              capacity?: number | null;
            } | null;
            folios: Array<{
              __typename?: "Folio";
              pmsId?: string | null;
              type: FolioType;
              debitorType: DebitorType;
              prepaymentType: PrepaymentType;
              pmsCustomerId?: string | null;
              propertyId?: number | null;
              pmsCompanyId?: string | null;
              number?: string | null;
              closed?: any | null;
              totalCharges?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  amountInCents?: number | null;
                  code?: string | null;
                } | null> | null;
              } | null;
              totalPayments?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
              } | null;
              groupedCharges: Array<{
                __typename?: "GroupedCharge";
                name: string;
                subName?: string | null;
                pmsServiceId?: string | null;
                totalQuantity: number;
                prepayable: boolean;
                translatedNames?: any | null;
                totalPrice: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                };
              }>;
              orderItems: Array<{
                __typename?: "OrderItem";
                pmsId: string;
                pmsFolioId?: string | null;
                pmsReservationId?: string | null;
                start?: any | null;
                end?: any | null;
                accountingState: AccountingState;
                translatedNames?: any | null;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
              payments: Array<{
                __typename?: "Payment";
                pmsId: string;
                folioId?: number | null;
                state: PaymentState;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
            }>;
            bookedServicesOverview: Array<{
              __typename?: "BookedService";
              dates: Array<{
                __typename?: "BookedServiceDate";
                serviceDate?: any | null;
                tags?: Array<BookedServiceTag> | null;
                amount?: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                  taxes?: Array<{
                    __typename?: "Tax";
                    amount?: any | null;
                    code?: string | null;
                  } | null> | null;
                } | null;
              }>;
              service?: {
                __typename?: "BookedServiceDetails";
                magicServiceCodeEnum?: string | null;
                name: string;
                pmsServiceId: string;
                tags?: Array<BookedServiceTag> | null;
                totalAmount?: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                  taxes?: Array<{
                    __typename?: "Tax";
                    amount?: any | null;
                    code?: string | null;
                  } | null> | null;
                } | null;
              } | null;
            }>;
            flowState: {
              __typename?: "ReservationFlowState";
              wasOrIsCompleted: boolean;
              completed: boolean;
              context: any;
              notificationPending: boolean;
              notificationSent: boolean;
              sentNotifications: Array<string>;
              performedActions: Array<string>;
              fastCheckinAvailable: boolean;
              dirtyUnitDialogSeen?: boolean | null;
              cleanUnitDialogSeen?: boolean | null;
              billConfirmed?: boolean | null;
              bookingOnBehalfOf?: BookingOnBehalfOf | null;
              unitCleanOnCheckin?: boolean | null;
            };
            files: Array<{
              __typename?: "MagicFileDescriptor";
              id: string;
              fileName: string;
              contentType: string;
              magicFileType: string;
              ownerId: string;
            }>;
            cancellationFees: Array<{
              __typename?: "CancellationFee";
              pmsId?: string | null;
              name?: string | null;
              description?: string | null;
              dueDateTime?: any | null;
              price?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPriceInCents?: number | null;
              } | null;
            }>;
            currentCancellationFee?: {
              __typename?: "CancellationFee";
              description?: string | null;
              dueDateTime?: any | null;
              name?: string | null;
              pmsId?: string | null;
              price?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPriceInCents?: number | null;
              } | null;
            } | null;
            foliosToBePaid: Array<{
              __typename?: "Folio";
              pmsId?: string | null;
              type: FolioType;
              debitorType: DebitorType;
              prepaymentType: PrepaymentType;
              pmsCustomerId?: string | null;
              propertyId?: number | null;
              pmsCompanyId?: string | null;
              number?: string | null;
              closed?: any | null;
              totalCharges?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  amountInCents?: number | null;
                  code?: string | null;
                } | null> | null;
              } | null;
              totalPayments?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
              } | null;
              groupedCharges: Array<{
                __typename?: "GroupedCharge";
                name: string;
                subName?: string | null;
                pmsServiceId?: string | null;
                totalQuantity: number;
                prepayable: boolean;
                translatedNames?: any | null;
                totalPrice: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                };
              }>;
              orderItems: Array<{
                __typename?: "OrderItem";
                pmsId: string;
                pmsFolioId?: string | null;
                pmsReservationId?: string | null;
                start?: any | null;
                end?: any | null;
                accountingState: AccountingState;
                translatedNames?: any | null;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
              payments: Array<{
                __typename?: "Payment";
                pmsId: string;
                folioId?: number | null;
                state: PaymentState;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
            }>;
            groupedRateBreakdown?: {
              __typename?: "GroupedRateBreakdown";
              rateSpliceTypeTuples?: Array<{
                __typename?: "RateSpliceTypeEnumTuple";
                type?: RateSpliceTypeEnumContract | null;
                totalCharges?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
                pmsIdToRateSplicesTuples?: Array<{
                  __typename?: "PmsIdToRateSplicesTuple";
                  pmsId?: string | null;
                  rateSplices?: Array<{
                    __typename?: "RateSplice";
                    displayName?: string | null;
                    quantity?: number | null;
                    type?: RateSpliceTypeEnumContract | null;
                    prepaymentType?: PrepaymentType | null;
                    hide?: boolean | null;
                    prepaid?: boolean | null;
                    grossPrice?: {
                      __typename?: "GrossPrice";
                      grossPrice?: any | null;
                      grossPriceInCents?: number | null;
                      currency?: string | null;
                    } | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          };
        };
      }
    | {
        __typename: "VerifyAndSignAck";
        deviceId: string;
        tenantName: string;
        type: SecondScreenEventType;
      }
    | {
        __typename: "VerifyBill";
        deviceId: string;
        tenantName: string;
        type: SecondScreenEventType;
        payload: {
          __typename: "VerifyBillPayload";
          reservation: {
            __typename: "Reservation";
            actor: Actor;
            pmsId: string;
            bookingOverviewItemId: string;
            userAccountUuid?: string | null;
            maxCompanions: number;
            magicId: string;
            magicToken: string;
            magicLink: string;
            isExternalReservation: boolean;
            channel?: string | null;
            adultsAmount?: number | null;
            childrenAmount?: number | null;
            createdAt?: any | null;
            status: ReservationStatus;
            arrival: any;
            departure: any;
            checkinTime?: any | null;
            checkoutTime?: any | null;
            estimatedArrivalTime?: any | null;
            estimatedDepartureTime?: any | null;
            cancellationTime?: any | null;
            idCheckStatus: IdCheckStatus;
            accessibleDoors?: Array<{
              __typename?: "AccessibleDoor";
              id?: string | null;
              isGeneral?: boolean | null;
              pin?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
              title?: string | null;
            } | null> | null;
            secondaryGuests?: Array<{
              __typename?: "Person";
              pmsId: string;
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
            } | null> | null;
            travelBuddies: Array<{
              __typename: "TravelBuddy";
              pmsPropertyId: string;
              pmsReservationId: string;
              magicId: string;
              magicToken: string;
              actor: Actor;
              pmsBookingId: string;
              bookingMagicId: string;
              bookingOverviewItemId: string;
              status: ReservationStatus;
              arrival: any;
              departure: any;
              checkinTime?: any | null;
              checkoutTime?: any | null;
              reservationId: number;
              reservationMagicId: string;
              unitCleanOnCheckin: boolean;
              created: any;
              accessibleDoors?: Array<{
                __typename?: "AccessibleDoor";
                id?: string | null;
                isGeneral?: boolean | null;
                pin?: string | null;
                privacyMode?: boolean | null;
                privacyModeLastChangeTimestamp?: any | null;
                title?: string | null;
              } | null> | null;
              unit?: {
                __typename?: "Unit";
                pmsId?: string | null;
                name?: string | null;
                state?: UnitState | null;
                floor?: string | null;
              } | null;
              unitGroup?: {
                __typename?: "UnitGroup";
                pmsId?: string | null;
                capacity?: number | null;
              } | null;
              flowState: {
                __typename?: "FlowState";
                completed: boolean;
                context: any;
                fastCheckinAvailable: boolean;
                notificationPending: boolean;
                notificationSent: boolean;
                performedActions: Array<string>;
                sentNotifications: Array<string>;
                wasOrIsCompleted: boolean;
              };
              person: {
                __typename?: "Person";
                firstName?: string | null;
                lastName?: string | null;
                email?: string | null;
                phone?: string | null;
                gender?: Gender | null;
                birthdate?: any | null;
                termsAndConditionsMarketingConsent: boolean;
                termsAndConditionsMarketingConsentDoubleOptin: boolean;
                termsAndConditionsGeneral: boolean;
                termsAndConditionsOnline: boolean;
                preferredCommunicationChannel?: CommunicationChannel | null;
                identificationDocumentNumber?: string | null;
                pmsId: string;
                preferredLanguage?: string | null;
                emergencyEvacuationHelpNeeded: boolean;
                enrolledInLoyaltyProgram: boolean;
                address?: {
                  __typename?: "Address";
                  addressLine1?: string | null;
                  addressLine2?: string | null;
                  postalCode?: string | null;
                  city?: string | null;
                  countryCode?: string | null;
                } | null;
              };
            }>;
            primaryGuest?: {
              __typename?: "Person";
              firstName?: string | null;
              lastName?: string | null;
              email?: string | null;
              phone?: string | null;
              gender?: Gender | null;
              birthdate?: any | null;
              termsAndConditionsMarketingConsent: boolean;
              termsAndConditionsMarketingConsentDoubleOptin: boolean;
              termsAndConditionsGeneral: boolean;
              termsAndConditionsOnline: boolean;
              enrolledInLoyaltyProgram: boolean;
              emergencyEvacuationHelpNeeded: boolean;
              preferredCommunicationChannel?: CommunicationChannel | null;
              identificationDocumentNumber?: string | null;
              nationality?: string | null;
              preferredLanguage?: string | null;
              pmsId: string;
              address?: {
                __typename?: "Address";
                addressLine1?: string | null;
                addressLine2?: string | null;
                postalCode?: string | null;
                city?: string | null;
                countryCode?: string | null;
                region?: string | null;
              } | null;
            } | null;
            property: { __typename?: "Property"; pmsId: string };
            totalCharges?: {
              __typename?: "Price";
              grossPriceInCents?: number | null;
              netPriceInCents?: number | null;
              grossPrice?: any | null;
              netPrice?: any | null;
              currency?: string | null;
            } | null;
            totalPayments?: {
              __typename?: "GrossPrice";
              grossPriceInCents?: number | null;
              grossPrice?: any | null;
              currency?: string | null;
            } | null;
            totalAllowedPayment?: {
              __typename?: "GrossPrice";
              grossPriceInCents?: number | null;
              grossPrice?: any | null;
              currency?: string | null;
            } | null;
            unit?: {
              __typename?: "Unit";
              pmsId?: string | null;
              name?: string | null;
              state?: UnitState | null;
              floor?: string | null;
              privacyMode?: boolean | null;
              privacyModeLastChangeTimestamp?: any | null;
            } | null;
            unitGroup?: {
              __typename?: "UnitGroup";
              pmsId?: string | null;
              capacity?: number | null;
            } | null;
            folios: Array<{
              __typename?: "Folio";
              pmsId?: string | null;
              type: FolioType;
              debitorType: DebitorType;
              prepaymentType: PrepaymentType;
              pmsCustomerId?: string | null;
              propertyId?: number | null;
              pmsCompanyId?: string | null;
              number?: string | null;
              closed?: any | null;
              totalCharges?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  amountInCents?: number | null;
                  code?: string | null;
                } | null> | null;
              } | null;
              totalPayments?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
              } | null;
              groupedCharges: Array<{
                __typename?: "GroupedCharge";
                name: string;
                subName?: string | null;
                pmsServiceId?: string | null;
                totalQuantity: number;
                prepayable: boolean;
                translatedNames?: any | null;
                totalPrice: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                };
              }>;
              orderItems: Array<{
                __typename?: "OrderItem";
                pmsId: string;
                pmsFolioId?: string | null;
                pmsReservationId?: string | null;
                start?: any | null;
                end?: any | null;
                accountingState: AccountingState;
                translatedNames?: any | null;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
              payments: Array<{
                __typename?: "Payment";
                pmsId: string;
                folioId?: number | null;
                state: PaymentState;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
            }>;
            bookedServicesOverview: Array<{
              __typename?: "BookedService";
              dates: Array<{
                __typename?: "BookedServiceDate";
                serviceDate?: any | null;
                tags?: Array<BookedServiceTag> | null;
                amount?: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                  taxes?: Array<{
                    __typename?: "Tax";
                    amount?: any | null;
                    code?: string | null;
                  } | null> | null;
                } | null;
              }>;
              service?: {
                __typename?: "BookedServiceDetails";
                magicServiceCodeEnum?: string | null;
                name: string;
                pmsServiceId: string;
                tags?: Array<BookedServiceTag> | null;
                totalAmount?: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                  taxes?: Array<{
                    __typename?: "Tax";
                    amount?: any | null;
                    code?: string | null;
                  } | null> | null;
                } | null;
              } | null;
            }>;
            flowState: {
              __typename?: "ReservationFlowState";
              wasOrIsCompleted: boolean;
              completed: boolean;
              context: any;
              notificationPending: boolean;
              notificationSent: boolean;
              sentNotifications: Array<string>;
              performedActions: Array<string>;
              fastCheckinAvailable: boolean;
              dirtyUnitDialogSeen?: boolean | null;
              cleanUnitDialogSeen?: boolean | null;
              billConfirmed?: boolean | null;
              bookingOnBehalfOf?: BookingOnBehalfOf | null;
              unitCleanOnCheckin?: boolean | null;
            };
            files: Array<{
              __typename?: "MagicFileDescriptor";
              id: string;
              fileName: string;
              contentType: string;
              magicFileType: string;
              ownerId: string;
            }>;
            cancellationFees: Array<{
              __typename?: "CancellationFee";
              pmsId?: string | null;
              name?: string | null;
              description?: string | null;
              dueDateTime?: any | null;
              price?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPriceInCents?: number | null;
              } | null;
            }>;
            currentCancellationFee?: {
              __typename?: "CancellationFee";
              description?: string | null;
              dueDateTime?: any | null;
              name?: string | null;
              pmsId?: string | null;
              price?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPriceInCents?: number | null;
              } | null;
            } | null;
            foliosToBePaid: Array<{
              __typename?: "Folio";
              pmsId?: string | null;
              type: FolioType;
              debitorType: DebitorType;
              prepaymentType: PrepaymentType;
              pmsCustomerId?: string | null;
              propertyId?: number | null;
              pmsCompanyId?: string | null;
              number?: string | null;
              closed?: any | null;
              totalCharges?: {
                __typename?: "Price";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
                netPrice?: any | null;
                netPriceInCents?: number | null;
                taxes?: Array<{
                  __typename?: "Tax";
                  amount?: any | null;
                  amountInCents?: number | null;
                  code?: string | null;
                } | null> | null;
              } | null;
              totalPayments?: {
                __typename?: "GrossPrice";
                currency?: string | null;
                grossPrice?: any | null;
                grossPriceInCents?: number | null;
              } | null;
              groupedCharges: Array<{
                __typename?: "GroupedCharge";
                name: string;
                subName?: string | null;
                pmsServiceId?: string | null;
                totalQuantity: number;
                prepayable: boolean;
                translatedNames?: any | null;
                totalPrice: {
                  __typename?: "Price";
                  currency?: string | null;
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  netPriceInCents?: number | null;
                };
              }>;
              orderItems: Array<{
                __typename?: "OrderItem";
                pmsId: string;
                pmsFolioId?: string | null;
                pmsReservationId?: string | null;
                start?: any | null;
                end?: any | null;
                accountingState: AccountingState;
                translatedNames?: any | null;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
              payments: Array<{
                __typename?: "Payment";
                pmsId: string;
                folioId?: number | null;
                state: PaymentState;
                price: {
                  __typename?: "Price";
                  grossPriceInCents?: number | null;
                  netPrice?: any | null;
                  currency?: string | null;
                };
              }>;
            }>;
            groupedRateBreakdown?: {
              __typename?: "GroupedRateBreakdown";
              rateSpliceTypeTuples?: Array<{
                __typename?: "RateSpliceTypeEnumTuple";
                type?: RateSpliceTypeEnumContract | null;
                totalCharges?: {
                  __typename?: "GrossPrice";
                  grossPrice?: any | null;
                  grossPriceInCents?: number | null;
                  currency?: string | null;
                } | null;
                pmsIdToRateSplicesTuples?: Array<{
                  __typename?: "PmsIdToRateSplicesTuple";
                  pmsId?: string | null;
                  rateSplices?: Array<{
                    __typename?: "RateSplice";
                    displayName?: string | null;
                    quantity?: number | null;
                    type?: RateSpliceTypeEnumContract | null;
                    prepaymentType?: PrepaymentType | null;
                    hide?: boolean | null;
                    prepaid?: boolean | null;
                    grossPrice?: {
                      __typename?: "GrossPrice";
                      grossPrice?: any | null;
                      grossPriceInCents?: number | null;
                      currency?: string | null;
                    } | null;
                  }> | null;
                }> | null;
              }> | null;
            } | null;
          };
        };
      }
    | {
        __typename: "VerifyBillAck";
        deviceId: string;
        tenantName: string;
        type: SecondScreenEventType;
      }
    | null;
};
