import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
  Selector
} from "@reduxjs/toolkit";
import { EntityStateStatus } from "../../state/EntityStateStatus";
import { RootState, ThunkExtraArguments } from "../../state/store";
import { MagicApi } from "../../api/magic.api";
import { getTenantHeaders } from "../../api/custom-headers";

type CheckoutSliceState = {
  isDialogOpen: boolean;
  status: EntityStateStatus;
  tasks: {
    note?: string;
    collectBags?: boolean;
  };
};

const initialState: CheckoutSliceState = {
  isDialogOpen: false,
  status: EntityStateStatus.IDLE,
  tasks: {
    note: undefined,
    collectBags: undefined
  }
};

export const startCheckout = createAsyncThunk<
  any,
  { magicId: string; magicToken: string },
  {
    state: RootState;
    rejectValue: { reason: string };
    extra: ThunkExtraArguments;
  }
>("checkout/startCheckout", async (arg, thunkAPI) => {
  return await MagicApi.checkout(arg, {
    signal: thunkAPI.signal,
    ...(await getTenantHeaders(thunkAPI.extra))
  });
});

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    resetCheckoutState: (state) => {
      state.status = EntityStateStatus.IDLE;
    },
    setCheckoutTasks: (state, action: PayloadAction<{ note?: string; collectBags?: boolean }>) => {
      state.tasks = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startCheckout.pending, (state) => {
        state.status = EntityStateStatus.LOADING;
      })
      .addCase(startCheckout.fulfilled, (state) => {
        state.status = EntityStateStatus.SUCCEEDED;
      })
      .addCase(startCheckout.rejected, (state) => {
        state.status = EntityStateStatus.FAILED;
      });
  }
});

export const { reducer: checkoutReducer } = checkoutSlice;

export const { resetCheckoutState, setCheckoutTasks } = checkoutSlice.actions;

const selectSelf: Selector<RootState, CheckoutSliceState> = (state: RootState) =>
  state[checkoutSlice.name];

export const selectIsCheckingOut = createSelector(
  selectSelf,
  (s) => s.status === EntityStateStatus.LOADING
);

export const selectIsCheckedOut = createSelector(
  selectSelf,
  (s) => s.status === EntityStateStatus.SUCCEEDED
);

export const selectCheckoutTasks = createSelector(selectSelf, (s) => s.tasks);
