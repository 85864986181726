import React, { FC, useCallback } from "react";
import { Box, FormControlLabel } from "@mui/material";
import { PageHeadingInfo } from "../../../components/molecules/page-heading-info";
import { useTranslateWrapper } from "../../../util/i18n-wrapper";
import { CMSSingleDocumentTypes } from "../../../state/cms/cms-single-document-types";
import { DesktopCard, DisplayCmsSvg, SubmitButton } from "../../../components";
import { useNavigate } from "react-router";
import { generateCheckoutFlowMiniBarPageUrl } from "../../../util/routing";
import { useMagicIdParams } from "../../magic/use-magic-id-params";
import { FlowTemplate } from "../../../components/layouts/flow-template";
import { CheckoutFlowPages, getCheckoutFlowProgress } from "../checkout-flow-pages";
import { Form, Formik } from "formik";
import { MobileTimePicker } from "@mui/lab";
import { Caption, Checkbox, Input, Paragraph } from "@likemagic-tech/sv-magic-library";
import { useReservationContext } from "../../reservation-provider/reservation-provider";
import { LocalizationDatePicker } from "../../../components/molecules/range-date-picker/localization-date-picker";
import { useCMSData } from "../../../state/cms/use-cms-data";
import { fetchCommonCMS, selectCommonCMSById } from "../../../state/common-cms/common-cms.slice";
import { GuestFlowBackgroundBox } from "../../guest-flow/components/guest-flow-background-box";
import { useUpdateEstimatedDepartureTimeMutation } from "../../../graphql/mutations/update-estimated-departure-time.generated";
import { useFormValidations } from "../../../util/hooks/use-form-validations";
import { WrappedInput } from "../../../components/atoms/input/wrapped-input";
import { useAppDispatch } from "../../../state/store";
import { selectCheckoutTasks, setCheckoutTasks } from "../checkout-flow.slice";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import { useUpdateFlowStateMutation } from "../../../graphql/mutations/update-flow-state.generated";

interface CheckoutDepartureForm {
  time?: string;
  specialInstruction?: string;
  pickUpMyBags: boolean;
}

const currentPage = CheckoutFlowPages.DEPARTURE_TIME;
export const CheckoutDepartureTimePage: FC = () => {
  const { t } = useTranslateWrapper({
    namespace: [CMSSingleDocumentTypes.common]
  });
  const cmsData = useCMSData(selectCommonCMSById, fetchCommonCMS);
  const { checkoutTimeValidation } = useFormValidations();
  const dispatch = useAppDispatch();

  const checkoutTasks = useSelector(selectCheckoutTasks);

  const { magicId } = useMagicIdParams();
  const navigate = useNavigate();
  const { reservation } = useReservationContext();
  const theme = useTheme();
  const [updateFlowState] = useUpdateFlowStateMutation();

  const initialValues: CheckoutDepartureForm = {
    time: reservation.estimatedDepartureTime ?? reservation.departure,
    specialInstruction: checkoutTasks.note,
    pickUpMyBags: checkoutTasks.collectBags ?? false
  };

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const [updateEstimatedDepartureTime] = useUpdateEstimatedDepartureTimeMutation();

  const handleSubmit = useCallback(
    async (values: CheckoutDepartureForm, dirty: boolean) => {
      if (dirty) {
        await updateEstimatedDepartureTime({
          magicId,
          magicToken: reservation.magicToken,
          estimatedDepartureTime: values.time,
          context: {
            ...reservation.flowState.context,
            lastConfirmedCheckoutPage: currentPage
          },
          guestNote: values.specialInstruction,
          includeCheckoutNoteTask: !!values.specialInstruction && !checkoutTasks.note,
          includeLuggagePickupTask: values.pickUpMyBags && !checkoutTasks.collectBags,
          dueDateGuestNote: reservation.departure,
          dueDateLuggage: values.time
        }).unwrap();
        dispatch(
          setCheckoutTasks({ note: values.specialInstruction, collectBags: values.pickUpMyBags })
        );
      } else {
        await updateFlowState({
          magicId,
          magicToken: reservation.magicToken,
          flowState: {
            context: {
              ...reservation.flowState.context,
              lastConfirmedCheckoutPage: currentPage
            }
          }
        });
      }

      navigate(generateCheckoutFlowMiniBarPageUrl(magicId));
    },
    [
      navigate,
      magicId,
      updateEstimatedDepartureTime,
      reservation.magicToken,
      reservation.flowState.context,
      reservation.departure,
      checkoutTasks.note,
      checkoutTasks.collectBags,
      dispatch,
      updateFlowState
    ]
  );

  return (
    <FlowTemplate icons={[]} progress={getCheckoutFlowProgress(currentPage)} handleBack={goBack}>
      <GuestFlowBackgroundBox>
        <Box p={2}>
          <DesktopCard>
            <PageHeadingInfo
              icon={<DisplayCmsSvg url={cmsData?.data?.icon__personal_data_icon?.url} />}
              title={t("title__checkout_flow_departure_time_page")}
            />
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              validationSchema={checkoutTimeValidation}
            >
              {({ values, errors, dirty, setFieldValue, handleChange, touched }) => (
                <Form>
                  <LocalizationDatePicker>
                    <MobileTimePicker
                      label={t("labels__departure_time")}
                      onChange={(date) => {
                        setFieldValue("time", date);
                      }}
                      value={values.time}
                      renderInput={(params) => (
                        // @ts-ignore
                        <Input id="time" name="time" {...params} error={errors["time"]} />
                      )}
                    />
                  </LocalizationDatePicker>

                  <Paragraph mt={2.5}>{t("labels__departure_time_note")}</Paragraph>

                  <Box mt={2.5}>
                    <WrappedInput
                      id="specialInstruction"
                      name="specialInstruction"
                      value={values.specialInstruction}
                      onChange={handleChange}
                      label={t("labels__special_instruction")}
                      error={touched.specialInstruction && errors.specialInstruction}
                      disabled={!!checkoutTasks.note}
                      helperText={!!checkoutTasks.note && t("labels__task_is_already_created")}
                    />
                  </Box>

                  <Box mt={2.5} display="flex" flexDirection="column">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="pickUpMyBags"
                          name="pickUpMyBags"
                          onChange={handleChange}
                          color="primary"
                          checked={values.pickUpMyBags}
                        />
                      }
                      label={t("labels__pickup_my_bags")}
                      disabled={checkoutTasks.collectBags ?? false}
                    />
                    <Caption sx={{ opacity: 0.38, mt: theme.spacing(-0.75) }}>
                      {checkoutTasks.collectBags && t("labels__task_is_already_created")}
                    </Caption>
                  </Box>

                  <SubmitButton
                    label={t("buttons__next")}
                    onClick={() => handleSubmit(values, dirty)}
                  />
                </Form>
              )}
            </Formik>
          </DesktopCard>
        </Box>
      </GuestFlowBackgroundBox>
    </FlowTemplate>
  );
};
