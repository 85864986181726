import * as Types from "../generated/graphql";

import { graphqlApi } from "../graphql.api";
export type UpdateEstimatedDepartureTimeMutationVariables = Types.Exact<{
  estimatedDepartureTime: Types.Scalars["Instant"];
  magicId: Types.Scalars["String"];
  magicToken: Types.Scalars["String"];
  context?: Types.InputMaybe<Types.Scalars["Json"]>;
  guestNote?: Types.InputMaybe<Types.Scalars["String"]>;
  dueDateLuggage?: Types.InputMaybe<Types.Scalars["Instant"]>;
  dueDateGuestNote?: Types.InputMaybe<Types.Scalars["Instant"]>;
  includeLuggagePickupTask: Types.Scalars["Boolean"];
  includeCheckoutNoteTask: Types.Scalars["Boolean"];
}>;

export type UpdateEstimatedDepartureTimeMutation = {
  __typename?: "Mutation";
  UpdateEstimatedDepartureTime?: boolean | null;
  createLuggagePickupTask?: boolean | null;
  createCheckoutNoteTask?: boolean | null;
  UpdateFlowState: { __typename: "ReservationFlowState" };
};

export const UpdateEstimatedDepartureTimeDocument = `
    mutation UpdateEstimatedDepartureTime($estimatedDepartureTime: Instant!, $magicId: String!, $magicToken: String!, $context: Json, $guestNote: String, $dueDateLuggage: Instant, $dueDateGuestNote: Instant, $includeLuggagePickupTask: Boolean!, $includeCheckoutNoteTask: Boolean!) {
  UpdateEstimatedDepartureTime(
    estimatedDepartureTime: $estimatedDepartureTime
    magicId: $magicId
    magicToken: $magicToken
  )
  UpdateFlowState(
    magicId: $magicId
    magicToken: $magicToken
    flowState: {context: $context}
  ) {
    __typename
  }
  createLuggagePickupTask: CreateTemplatedTask(
    magicId: $magicId
    magicToken: $magicToken
    taskTemplate: LUGGAGE_PICKUP
    due: $dueDateLuggage
  ) @include(if: $includeLuggagePickupTask)
  createCheckoutNoteTask: CreateTemplatedTask(
    magicId: $magicId
    magicToken: $magicToken
    guestNote: $guestNote
    taskTemplate: CHECKOUT_NOTE
    due: $dueDateGuestNote
  ) @include(if: $includeCheckoutNoteTask)
}
    `;

const injectedRtkApi = graphqlApi.injectEndpoints({
  endpoints: (build) => ({
    UpdateEstimatedDepartureTime: build.mutation<
      UpdateEstimatedDepartureTimeMutation,
      UpdateEstimatedDepartureTimeMutationVariables
    >({
      query: (variables) => ({ document: UpdateEstimatedDepartureTimeDocument, variables })
    })
  })
});

export { injectedRtkApi as api };
export const { useUpdateEstimatedDepartureTimeMutation } = injectedRtkApi;
